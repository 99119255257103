import React from "react";
import { Search } from "react-bootstrap-table2-toolkit";
import { useParams } from "react-router";
import { Container, Row } from "reactstrap";
import AddKeyForm from "./AddKeyForm";
import Header from "./Header";

const { SearchBar } = Search;

function AddKey(props) {
  //!Const
  const { keyId } = useParams();
  //!State
  //!Function

  //!Render
  return (
    <>
      <Header
        name={keyId === "add" ? "Thêm mới từ khoá" : "Cập nhật từ khoá"}
        parentName="Quản lý từ khoá"
      />
      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <AddKeyForm />
          </div>
        </Row>
        {/* <Row>
          <div className="col">
            <Card className="border-0"></Card>
          </div>
        </Row> */}
      </Container>
    </>
  );
}

export default AddKey;

import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import { Card, CardHeader, Container, Row } from "reactstrap";
import { dataTable } from "variables/general";
import Header from "./Header";
import Results from "./Results";

const { SearchBar } = Search;

function TenderNotice(props) {
  //!Const
  //!State
  //!Function

  //!Render
  return (
    <>
      <Header name="Thông báo mời thầu" parentName="Quản lý TBMT" />
      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <Results />
          </div>
        </Row>
        {/* <Row>
          <div className="col">
            <Card className="border-0"></Card>
          </div>
        </Row> */}
      </Container>
    </>
  );
}

export default TenderNotice;

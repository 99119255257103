import _ from 'lodash';
import { call, put, takeLatest } from 'redux-saga/effects';
import TypeActions from 'Redux/TypeActions';
import ServiceURL from 'Service/ServiceURL';
import { DELETE, GET, PATCH, POST } from '../../Service/ServiceBase';

export function* getUsersById(data) {
  const url = ServiceURL.User + '/' + data.params;
  const callback = data.callback;
  try {
    const res = yield call(GET, url);
    if (res.message && !_.isEmpty(res.message)) {
      yield put({
        type: TypeActions.GET_USERS_FAILED,
        error: res.message,
      });
      callback && callback.failed(res.message);
    } else {
      yield put({
        type: TypeActions.GET_USERS_SUCCESS,
        data: res.data,
      });
      callback && callback.success(res.data);
    }
  } catch (error) {
    yield put({ type: TypeActions.GET_USERS_FAILED, error });
    callback && callback.failed(error?.response?.data?.message);
  }
}
export function* getUserInfor(data) {
  const url = ServiceURL.User + '/' + data.params;

  try {
    const res = yield call(GET, url);
    if (res.message && !_.isEmpty(res.message)) {
      yield put({
        type: TypeActions.GET_USERS_INFOR_FAILED,
        error: res.message,
      });
    } else {
      yield put({
        type: TypeActions.GET_USERS_INFOR_SUCCESS,
        data: res.data,
      });
    }
  } catch (error) {
    yield put({ type: TypeActions.GET_USERS_INFOR_FAILED, error });
  }
}

export function* uploadImage(data) {
  const url = ServiceURL.UploadImage;
  const callback = data.callback;
  try {
    const res = yield call(POST, url, data.body);
    if (!res.data.success) {
      yield put({
        type: TypeActions.UPLOAD_IMAGE_FAILED,
        error: res.message,
      });
    } else {
      yield put({
        type: TypeActions.UPLOAD_IMAGE_SUCCESS,
        data: res.data.path,
      });
      callback && callback.success(res.data.path);
    }
  } catch (error) {
    yield put({ type: TypeActions.UPLOAD_IMAGE_FAILED, error });
    callback && callback.failed(error.data.success);
  }
}

export function* deleteUsers(data) {
  const url = ServiceURL.User + '/' + data.params;
  const callback = data.callback;
  try {
    const res = yield call(DELETE, url);
    if (res.message && !_.isEmpty(res.message)) {
      yield put({
        type: TypeActions.DELETE_USERS_FAILED,
        error: res.message,
      });
      callback && callback.failed(res.message);
    } else {
      yield put({
        type: TypeActions.DELETE_USERS_SUCCESS,
      });
      callback && callback.success();
    }
  } catch (error) {
    yield put({ type: TypeActions.DELETE_USERS_FAILED, error });
    callback && callback.failed(error?.response?.data?.message);
  }
}

export function* getListUsers(data) {
  const url = ServiceURL.User + '?' + data.params;
  const callback = data.callback;
  try {
    const res = yield call(GET, url);

    if (res.message && !_.isEmpty(res.message)) {
      yield put({
        type: TypeActions.GET_LIST_USERS_FAILED,
        error: res.message,
      });
    } else {
      yield put({
        type: TypeActions.GET_LIST_USERS_SUCCESS,
        data: res.data,
      });
      callback && callback.success(res.data);
    }
  } catch (error) {
    yield put({ type: TypeActions.GET_LIST_USERS_FAILED, error });
  }
}

export function* createUsers(data) {
  const url = ServiceURL.User;
  const callback = data.callback;
  try {
    const res = yield call(POST, url, data.body);
    if (res.message && !_.isEmpty(res.message)) {
      yield put({
        type: TypeActions.CREATE_USERS_FAILED,
        error: res.message,
      });
      callback && callback.failed(res.error?.response?.data?.message);
    } else {
      yield put({
        type: TypeActions.CREATE_USERS_SUCCESS,
      });
      callback && callback.success();
    }
  } catch (error) {
    yield put({ type: TypeActions.CREATE_USERS_FAILED, error });
    callback && callback.failed(error?.response?.data?.message);
  }
}

export function* changePassword(data) {
  const url = ServiceURL.ChangePassword + '/' + data.params;
  const callback = data.callback;
  try {
    const res = yield call(POST, url, data.body);
    if (res.message && !_.isEmpty(res.message)) {
      yield put({
        type: TypeActions.CHANGE_PASSWORD_USERS_FAILED,
        error: res.message,
      });
      callback && callback.failed(res.error?.response?.data?.message);
    } else {
      yield put({
        type: TypeActions.CHANGE_PASSWORD_USERS_SUCCESS,
      });
      callback && callback.success();
    }
  } catch (error) {
    yield put({ type: TypeActions.CHANGE_PASSWORD_USERS_FAILED, error });
    callback && callback.failed(error?.response?.data?.message);
  }
}
export function* forgotPassword(data) {
  const url = ServiceURL.ForgotPassword;
  const callback = data.callback;
  try {
    const res = yield call(POST, url, data.body);
    if (res.message && !_.isEmpty(res.message)) {
      yield put({
        type: TypeActions.FORGOT_PASSWORD_USERS_FAILED,
        error: res.message,
      });
      callback && callback.failed(res.error?.response?.data?.message);
    } else {
      yield put({
        type: TypeActions.FORGOT_PASSWORD_USERS_SUCCESS,
      });
      callback && callback.success();
    }
  } catch (error) {
    yield put({ type: TypeActions.FORGOT_PASSWORD_USERS_FAILED, error });
    callback && callback.failed(error?.response?.data?.message);
  }
}

export function* resetPassword(data) {
  const url = ServiceURL.ResetPassword + '?' + data.params;
  const callback = data.callback;
  try {
    const res = yield call(POST, url, data.body);
    if (res.message && !_.isEmpty(res.message)) {
      yield put({
        type: TypeActions.RESET_PASSWORD_USERS_FAILED,
        error: res.message,
      });
      callback && callback.failed(res.error?.response?.data?.message);
    } else {
      yield put({
        type: TypeActions.RESET_PASSWORD_USERS_SUCCESS,
      });
      callback && callback.success();
    }
  } catch (error) {
    yield put({ type: TypeActions.RESET_PASSWORD_USERS_FAILED, error });
    callback && callback.failed(error?.response?.data?.message);
  }
}

export function* editUsers(data) {
  const url = ServiceURL.User + '/' + data.params;
  const callback = data.callback;
  try {
    const res = yield call(PATCH, url, data.body);
    if (res.message && !_.isEmpty(res.message)) {
      yield put({
        type: TypeActions.EDIT_USERS_FAILED,
        error: res.message,
      });
      callback && callback.failed(res.error?.response?.data?.message);
    } else {
      yield put({
        type: TypeActions.EDIT_USERS_SUCCESS,
      });
      callback && callback.success();
    }
  } catch (error) {
    yield put({ type: TypeActions.EDIT_USERS_FAILED, error });
    callback && callback.failed(error?.response?.data?.message);
  }
}

export function* logIn(data) {
  const url = ServiceURL.Login;
  const callback = data.callback;
  try {
    const res = yield call(POST, url, data.body);

    if (res.message && !_.isEmpty(res.message)) {
      yield put({ type: TypeActions.LOGIN_REQUEST_FAILED, error: res.message });
      callback && callback.failed(res.message);
    } else {
      yield put({
        type: TypeActions.LOGIN_REQUEST_SUCCESS,
        data: res.data.user,
      });
      // localStorage.setItem('expiresAt', res.data.tokens.access.expires);
      // localStorage.setItem('token', res.data.tokens.access.token);
      // localStorage.setItem('refreshtoken', res.data.tokens.refresh.token);
      // // localStorage.setItem("role", res.data.user.role);
      // localStorage.setItem('id', res.data.user.id);
      callback && callback.success(res.data);
    }
  } catch (error) {
    yield put({
      type: TypeActions.LOGIN_REQUEST_FAILED,
      error: error?.response?.data?.message,
    });
    callback && callback.failed(error?.response?.data?.message);
  }
}

export function* logOut(data) {
  const url = ServiceURL.Logout;
  try {
    const res = yield call(POST, url, data.body);

    if (res.message && !_.isEmpty(res.message)) {
      yield put({
        type: TypeActions.LOGOUT_REQUEST_FAILED,
        error: res.message,
      });
      localStorage.setItem('token', '');
      localStorage.setItem('id', '');
      localStorage.setItem('roleId', '');
      localStorage.setItem('refreshtoken', '');
    } else {
      yield put({
        type: TypeActions.LOGOUT_REQUEST_SUCCESS,
      });
      // localStorage.setItem('expiresAt', res.data.tokens.access.expires);
      // localStorage.setItem('token', res.data.tokens.access.token);
      // localStorage.setItem('refreshtoken', res.data.tokens.refresh.token);
      // // localStorage.setItem("role", res.data.user.role);
      // localStorage.setItem('id', res.data.user.id);
      localStorage.setItem('token', '');
      localStorage.setItem('id', '');
      localStorage.setItem('roleId', '');
      localStorage.setItem('refreshtoken', '');
    }
  } catch (error) {
    yield put({
      type: TypeActions.LOGOUT_REQUEST_FAILED,
      error: error?.response?.data?.message,
    });
  }
}

export function* setPassUser(data) {
  const url = ServiceURL.setPassUser;
  const callback = data.callback;
  try {
    const res = yield call(POST, url, data.body);
    if (res.message && !_.isEmpty(res.message)) {
      yield put({
        type: TypeActions.SET_PASS_USER_FAILED,
        error: res.message,
      });
      callback && callback.failed(res.error?.response?.data?.message);
    } else {
      yield put({
        type: TypeActions.SET_PASS_USER_SUCCESS,
      });
      callback && callback.success();
    }
  } catch (error) {
    yield put({ type: TypeActions.SET_PASS_USER_FAILED, error });
    callback && callback.failed(error?.response?.data?.message);
  }
}

export default function* userSaga() {
  yield takeLatest(TypeActions.GET_USERS_REQUEST, getUsersById);
  yield takeLatest(TypeActions.GET_USERS_INFOR_REQUEST, getUserInfor);
  yield takeLatest(TypeActions.DELETE_USERS_REQUEST, deleteUsers);
  yield takeLatest(TypeActions.LOGIN_REQUEST, logIn);
  yield takeLatest(TypeActions.GET_LIST_USERS_REQUEST, getListUsers);
  yield takeLatest(TypeActions.CREATE_USERS_REQUEST, createUsers);
  yield takeLatest(TypeActions.EDIT_USERS_REQUEST, editUsers);
  yield takeLatest(TypeActions.UPLOAD_IMAGE_REQUEST, uploadImage);
  yield takeLatest(TypeActions.LOGOUT_REQUEST, logOut);
  yield takeLatest(TypeActions.CHANGE_PASSWORD_USERS_REQUEST, changePassword);
  yield takeLatest(TypeActions.FORGOT_PASSWORD_USERS_REQUEST, forgotPassword);
  yield takeLatest(TypeActions.RESET_PASSWORD_USERS_REQUEST, resetPassword);
  yield takeLatest(TypeActions.SET_PASS_USER_REQUEST, setPassUser);
}

import { Divider, TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import AddDocumentChild from "containers/DocumentManage/AddDocumentChild";
import { Formik } from "formik";
import queryString from "query-string";
import React, { useEffect, useState } from "react";
import { store } from "react-notifications-component";
import "react-notifications-component/dist/theme.css";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router";
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  Row,
} from "reactstrap";
import documentActions from "Redux/Actions/documentActions";
import documentTypeActions from "Redux/Actions/documentTypeActions";
import * as yup from "yup";

function AddDocumentForm(props) {
  //!Const
  const history = useHistory();
  const dispatch = useDispatch();
  const isCreating = useSelector((state) => state.documentReducer.isCreating);
  const isEditting = useSelector((state) => state.documentReducer.isEditting);
  const documentById = useSelector(
    (state) => state.documentReducer.documentById
  );
  const listdocumentType = useSelector(
    (state) => state.documentTypeReducer.listdocumentType
  );
  const userSchema = yup.object().shape({
    code: yup.string().required("Vui lòng nhập mã tài liệu!"),
    documentTypeId: yup.object().required("Vui lòng chọn loại tài liệu!"),
    name: yup.string().required("Vui lòng nhập tên tài liệu!"),
  });
  const { documentId } = useParams();
  //!State
  const [detailParams, setdetailParams] = useState({
    code: "",
    name: "",
    documentTypeId: "",
    childrentIds: [],
  });
  const [searchType, setsearchType] = useState("");
  //!Function
  const handleDocument = (value) => {
    if (!!documentId && documentId !== null && documentId === "add") {
      const detailAdd = {
        code: value.code,
        name: value.name,
        documentTypeId: value.documentTypeId.id,
        childrentIds: [],
      };
      dispatch(
        documentActions.createDocument(detailAdd, {
          success: () => {
            store.addNotification({
              title: "Thông báo!",
              message: "Thêm tài liệu thành công!",
              type: "success", // 'default', 'success', 'info', 'warning'
              container: "bottom-right", // where to position the notifications
              animationIn: ["animated", "fadeIn"], // animate.css classes that's applied
              animationOut: ["animated", "fadeOut"], // animate.css classes that's applied
              dismiss: {
                pauseOnHover: true,
                onScreen: true,
                duration: 3000,
              },
            });
            history.goBack();
          },
          failed: (mess) => {
            store.addNotification({
              title: "Thông báo!",
              message: "Thêm mới tài liệu thất bại! Lỗi: " + mess + "! Vui lòng thử lại!",
              type: "danger", // 'default', 'success', 'info', 'warning'
              container: "bottom-right", // where to position the notifications
              animationIn: ["animated", "fadeIn"], // animate.css classes that's applied
              animationOut: ["animated", "fadeOut"], // animate.css classes that's applied
              dismiss: {
                onScreen: true,
                pauseOnHover: true,
                duration: 5000,
              },
            });
          },
        })
      );
    } else if (!!documentId && documentId !== null && documentId !== "add") {
      const detailEdit = {
        code: value.code,
        name: value.name,
        documentTypeId: value.documentTypeId.id,
        childrentIds: value.childrentIds.map((i) => {
          return i.id;
        }),
      };

      dispatch(
        documentActions.editDocument(detailEdit, documentId, {
          success: () => {
            store.addNotification({
              title: "Thông báo!",
              message: "Cập nhật tài liệu thành công!",
              type: "success", // 'default', 'success', 'info', 'warning'
              container: "bottom-right", // where to position the notifications
              animationIn: ["animated", "fadeIn"], // animate.css classes that's applied
              animationOut: ["animated", "fadeOut"], // animate.css classes that's applied
              dismiss: {
                pauseOnHover: true,
                onScreen: true,
                duration: 3000,
              },
            });
            history.goBack();
          },
          failed: (mess) => {
            store.addNotification({
              title: "Thông báo!",
              message: "Cập nhật tài liệu thất bại! Lỗi: " + mess + "! Vui lòng thử lại!",
              type: "danger", // 'default', 'success', 'info', 'warning'
              container: "bottom-right", // where to position the notifications
              animationIn: ["animated", "fadeIn"], // animate.css classes that's applied
              animationOut: ["animated", "fadeOut"], // animate.css classes that's applied
              dismiss: {
                onScreen: true,
                pauseOnHover: true,
                duration: 5000,
              },
            });
          },
        })
      );
    }
  };

  //!Use effect
  useEffect(() => {
    if (documentId && documentId !== "add") {
      dispatch(
        documentActions.getDocument(
          documentId +
            "?" +
            queryString.stringify({ populate: "childrentIds,documentTypeId" })
        )
      );
    }
  }, [documentId]);
  useEffect(() => {
    dispatch(
      documentTypeActions.getListDocumentType(queryString.stringify(searchType))
    );
  }, [searchType]);
  useEffect(() => {
    if (documentId !== "add" && documentById !== undefined) {
      setdetailParams({
        code: documentById.code,
        documentTypeId: documentById.documentTypeId,
        name: documentById.name,
        childrentIds: documentById.childrentIds,
      });
    } else {
      setdetailParams({
        code: "",
        name: "",
        documentTypeId: "",
        childrentIds: [],
      });
    }
  }, [documentById]);

  //!Render
  return (
    <>
      <Card>
        <CardHeader>
          <Row className="align-items-center ">
            <Col lg="6" md="7" xs="12">
              <h3 className="mb-0">
                {documentId === "add"
                  ? "Thêm mới tài liệu"
                  : "Chỉnh sửa tài liệu"}
              </h3>
            </Col>
            <Col className="mt-3 mt-md-0 text-md-right" lg="6" md="5" xs="12">
              {/* <Button>
                <i className="ni ni-bold-down"></i>
              </Button> */}
            </Col>
          </Row>
        </CardHeader>
        <Formik
          initialValues={detailParams}
          enableReinitialize
          onSubmit={handleDocument}
          validationSchema={userSchema}
        >
          {({ values, setFieldValue, handleSubmit, errors, touched }) => {
            return (
              <>
                <CardBody>
                  <Row className="align-items-flex-start">
                    <Col
                      className="align-items-flex-start"
                      lg="12"
                      md="12"
                      xs="12"
                    >
                      <Row className="align-items-flex-start p-2">
                        <Col lg="12" md="12" xs="12">
                          <h2>Thông tin</h2>
                          <Divider />
                        </Col>
                      </Row>
                      <Row className="align-items-flex-start p-2">
                        <Col lg="6" md="6" xs="12" className="p-2">
                          <TextField
                            label="Mã tài liệu *"
                            name="code"
                            value={values.code}
                            variant="outlined"
                            fullWidth
                            type="text"
                            onChange={(e) => {
                              setFieldValue("code", e.target.value);
                            }}
                          />
                          {errors.code && touched.code && (
                            <a
                              style={{
                                fontSize: 15,

                                color: "red",
                                paddingLeft: "3px",
                              }}
                            >
                              {errors.code}
                            </a>
                          )}
                        </Col>
                        <Col lg="6" md="6" xs="12" className="p-2">
                          <TextField
                            label="Tên tài liệu *"
                            name="name"
                            value={values.name}
                            variant="outlined"
                            fullWidth
                            type="text"
                            onChange={(e) => {
                              setFieldValue("name", e.target.value);
                            }}
                          />
                          {errors.name && touched.name && (
                            <a
                              style={{
                                fontSize: 15,

                                color: "red",
                                paddingLeft: "3px",
                              }}
                            >
                              {errors.name}
                            </a>
                          )}
                        </Col>
                      </Row>
                      <Row className="align-items-flex-start p-2">
                        <Col lg="6" md="6" xs="12" className="p-2">
                          <Autocomplete
                            options={
                              listdocumentType &&
                              listdocumentType.results !== undefined
                                ? listdocumentType.results
                                : []
                            }
                            value={values.documentTypeId}
                            // value={values.customerId}
                            getOptionLabel={(option) => {
                              if (option !== null && option.name)
                                return option.name;
                            }}
                            onChange={(e, value) => {
                              setFieldValue("documentTypeId", value);
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                style={{
                                  height: "80%",
                                }}
                                // placeholder="Phân quyền"
                                label="Loại tài liệu *"
                                name="role"
                                variant="outlined"
                                fullWidth
                                // type="text"
                                onChange={(e) => {
                                  setsearchType(e.target.value);
                                }}
                              />
                            )}
                          />
                          {errors.documentTypeId && touched.documentTypeId && (
                            <a
                              style={{
                                fontSize: 15,
                                color: "red",
                                paddingLeft: "3px",
                              }}
                            >
                              {errors.documentTypeId}
                            </a>
                          )}
                        </Col>
                      </Row>
                      <AddDocumentChild
                        dataTable={values.childrentIds}
                        setFieldValue={setFieldValue}
                      />
                    </Col>
                  </Row>
                </CardBody>
                <CardFooter>
                  <Row className="align-items-center text-right">
                    <Col lg="12" md="12" xs="12">
                      {isEditting || isCreating ? (
                        <div class="spinner-border text-info" />
                      ) : (
                        <span>
                          <Button
                            className="bg-info text-white"
                            onClick={handleSubmit}
                          >
                            <i className="fas fa-check" />{" "}
                            {documentId === "add" ? "Thêm mới" : "Cập nhật"}
                          </Button>
                          {/* {avatar ? <br /> : null} */}
                          <Button
                            // className=""
                            onClick={() => {
                              history.goBack();
                            }}
                          >
                            <i className="fas fa-times" /> Huỷ bỏ
                          </Button>
                        </span>
                      )}
                    </Col>
                  </Row>
                </CardFooter>
              </>
            );
          }}
        </Formik>
      </Card>
    </>
  );
}

export default AddDocumentForm;

import { TextField } from '@material-ui/core';
import { Formik } from 'formik';
import React, { useState } from 'react';
import { store } from 'react-notifications-component';
import 'react-notifications-component/dist/theme.css';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import { Button, Col, Row } from 'reactstrap';
import { userActions } from 'Redux/Actions';
import * as yup from 'yup';

function ChangePass({ dataUser }) {
  //!Const
  const { id } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const isLoading = useSelector((state) => state.userReducer.isLoading);
  const passSchema = yup.object().shape({
    oldPassword: yup
      .string()
      // .matches(
      //   "^(?=.*[A-Za-z])(?=.*\\d)[A-Za-z\\d]{8,}$",
      //   "Mật khẩu phải có ít nhất 8 kí tự, trong đó chứa ít nhất 1 kí tự và một số!"
      // )
      .required('Vui lòng nhập mật khẩu hiện tại!'),
    newPassword: yup
      .string()
      .required('Vui lòng nhập mật khẩu mới!')
      .matches(
        '^(?=.*[A-Za-z])(?=.*\\d)[A-Za-z\\d\\W]{8,}$',
        'Mật khẩu phải có ít nhất 8 kí tự, trong đó chứa ít nhất 1 kí tự và một số!'
      ),
    newPasswordRetype: yup
      .string()
      .oneOf([yup.ref('newPassword')], 'Mật khẩu không trùng khớp!')
      .required('Vui lòng xác nhận mật khẩu!'),
  });
  //!State
  const [changePass, setschangePass] = useState({
    oldPassword: '',
    newPassword: '',
    newPasswordRetype: '',
  });
  //!Function
  const handleChange = (value) => {
    const password = {
      oldPassword: value.oldPassword,
      newPassword: value.newPassword,
    };
    dispatch(
      userActions.changePassword(password, id, {
        success: () => {
          store.addNotification({
            title: 'Thông báo!',
            message:
              'Cập nhật mật khẩu thành công! Vui lòng đăng nhập lại để tiếp tục sử dụng hệ thống!',
            type: 'success', // 'default', 'success', 'info', 'warning'
            container: 'bottom-right', // where to position the notifications
            animationIn: ['animated', 'fadeIn'], // animate.css classes that's applied
            animationOut: ['animated', 'fadeOut'], // animate.css classes that's applied
            dismiss: {
              pauseOnHover: true,
              onScreen: true,
              duration: 3000,
            },
          });
          // props.setopenPopup(false);
          dispatch(
            userActions.logout({
              refreshToken: localStorage.getItem('refreshtoken'),
            })
          );
          history.push('/auth/login');
        },
        failed: (mess) => {
          store.addNotification({
            title: 'Thông báo!',
            message:
              'Cập nhật mật khẩu thất bại! Lỗi: ' +
              mess +
              '! Vui lòng thử lại!',
            type: 'danger', // 'default', 'success', 'info', 'warning'
            container: 'bottom-right', // where to position the notifications
            animationIn: ['animated', 'fadeIn'], // animate.css classes that's applied
            animationOut: ['animated', 'fadeOut'], // animate.css classes that's applied
            dismiss: {
              onScreen: true,
              pauseOnHover: true,
              duration: 5000,
            },
          });
        },
      })
    );
  };
  //!UseEffect
  return (
    <Formik
      initialValues={changePass}
      enableReinitialize
      onSubmit={handleChange}
      validationSchema={passSchema}
    >
      {({ values, setFieldValue, handleSubmit, errors, touched }) => {
        return (
          <>
            {dataUser.isPasswordChange === false ? (
              <Row>
                <Col lg="12" md="12" xs="12" className="p-2">
                  <a
                    style={{
                      fontSize: 17,
                      color: 'red',
                      padding: '10px',
                    }}
                  >
                    Vui lòng thay đổi mật khẩu trước khi sử dụng hệ thống!
                  </a>
                </Col>
              </Row>
            ) : null}
            <Row>
              <Col lg="12" md="12" xs="12" className="p-2">
                <TextField
                  variant="outlined"
                  id="oldPassword"
                  label="Mật khẩu hiện tại"
                  value={values.oldPassword}
                  //   style={{ color: "white" }}
                  onChange={(text) =>
                    setFieldValue('oldPassword', text.target.value)
                  }
                  fullWidth
                  type="password"
                />
                {errors.oldPassword && touched.oldPassword && (
                  <a
                    style={{
                      fontSize: 15,
                      color: 'red',
                    }}
                  >
                    {errors.oldPassword}
                  </a>
                )}
              </Col>
              <Col lg="12" md="12" xs="12" className="p-2">
                <TextField
                  variant="outlined"
                  id="newPassword"
                  label="Mật khẩu mới"
                  value={values.newPassword}
                  //   style={{ color: "white" }}
                  onChange={(text) =>
                    setFieldValue('newPassword', text.target.value)
                  }
                  fullWidth
                  type="password"
                />
                {errors.newPassword && touched.newPassword && (
                  <a
                    style={{
                      fontSize: 15,
                      color: 'red',
                    }}
                  >
                    {errors.newPassword}
                  </a>
                )}
              </Col>
              <Col lg="12" md="12" xs="12" className="p-2">
                <TextField
                  variant="outlined"
                  id="newPasswordRetype"
                  label="Nhập lại mật khẩu mới"
                  value={values.newPasswordRetype}
                  //   style={{ color: "white" }}
                  onChange={(text) =>
                    setFieldValue('newPasswordRetype', text.target.value)
                  }
                  fullWidth
                  type="password"
                />
                {errors.newPasswordRetype && touched.newPasswordRetype && (
                  <a
                    style={{
                      fontSize: 15,
                      color: 'red',
                    }}
                  >
                    {errors.newPasswordRetype}
                  </a>
                )}
              </Col>
            </Row>
            <Row>
              <Col lg="12" md="12" xs="12" className="p-2 text-right">
                {isLoading ? (
                  <div class="spinner-border text-info" />
                ) : (
                  <>
                    <Button
                      className="bg-info text-white"
                      onClick={handleSubmit}
                    >
                      Cập nhật
                    </Button>
                    &nbsp;
                    <Button
                      //   className={classes.buttonGrey}
                      onClick={() => {
                        setFieldValue('oldPassword', '');
                        setFieldValue('newPassword', '');
                        setFieldValue('newPasswordRetype', '');
                        history.push(`/infor`);
                      }}
                    >
                      Huỷ bỏ
                    </Button>
                  </>
                )}
              </Col>
            </Row>
          </>
        );
      }}
    </Formik>
  );
}

export default ChangePass;

import TypeActions from "../TypeActions";

const initialState = {
  listTenderProfile: [],
  tenderProfileById: {},
  isGetting: false,
  isCreating: false,
  isEditing: false,
  isDeleting: false,
  error: "",
};

export const tenderProfileReducer = (state = initialState, action) => {
  switch (action.type) {
    //!Get list tenderProfile
    case TypeActions.GET_LIST_TENDER_PROFILE_REQUEST:
      return {
        ...state,
        isGetting: true,
      };
    case TypeActions.GET_LIST_TENDER_PROFILE_SUCCESS:
      return {
        ...state,
        listTenderProfile: action.data,
        isGetting: false,
      };
    case TypeActions.GET_LIST_TENDER_PROFILE_FAILED:
      return {
        ...state,
        error: action.error,
        isGetting: false,
      };
    //!Get tenderProfile
    case TypeActions.GET_TENDER_PROFILE_REQUEST:
      return {
        ...state,
        isGetting: true,
      };
    case TypeActions.GET_TENDER_PROFILE_SUCCESS:
      return {
        ...state,
        tenderProfileById: action.data,
        isGetting: false,
      };
    case TypeActions.GET_TENDER_PROFILE_FAILED:
      return {
        ...state,
        error: action.error,
        isGetting: false,
      };
    //!Create tenderProfile
    case TypeActions.CREATE_TENDER_PROFILE_REQUEST:
      return {
        ...state,
        isCreating: true,
      };
    case TypeActions.CREATE_TENDER_PROFILE_SUCCESS:
      return {
        ...state,
        isCreating: false,
      };
    case TypeActions.CREATE_TENDER_PROFILE_FAILED:
      return {
        ...state,
        error: action.error,
        isCreating: false,
      };
    //!Edit tenderProfile
    case TypeActions.EDIT_TENDER_PROFILE_REQUEST:
      return {
        ...state,
        isEditing: true,
      };
    case TypeActions.EDIT_TENDER_PROFILE_SUCCESS:
      return {
        ...state,
        isEditing: false,
      };
    case TypeActions.EDIT_TENDER_PROFILE_FAILED:
      return {
        ...state,
        error: action.error,
        isEditing: false,
      };
    //!Edit task tender Profile
    case TypeActions.EDIT_TASK_TENDER_PROFILE_REQUEST:
      return {
        ...state,
        isEditing: true,
      };
    case TypeActions.EDIT_TASK_TENDER_PROFILE_SUCCESS:
      return {
        ...state,
        isEditing: false,
      };
    case TypeActions.EDIT_TASK_TENDER_PROFILE_FAILED:
      return {
        ...state,
        error: action.error,
        isEditing: false,
      };
    //!Delete tenderProfile
    case TypeActions.DELETE_TENDER_PROFILE_REQUEST:
      return {
        ...state,
        isDeleting: true,
      };
    case TypeActions.DELETE_TENDER_PROFILE_SUCCESS:
      return {
        ...state,
        isDeleting: false,
      };
    case TypeActions.DELETE_TENDER_PROFILE_FAILED:
      return {
        ...state,
        error: action.error,
        isDeleting: false,
      };
    //!Default
    default:
      return {
        ...state,
      };
  }
};

export default tenderProfileReducer;

import TypeActions from "../TypeActions";

export const getListDocumentType = (params) => {
  return {
    type: TypeActions.GET_LIST_DOCUMENT_TYPE_REQUEST,
    params,
    // callback
  };
};
export const getDocumentType = (params) => {
  return {
    type: TypeActions.GET_DOCUMENT_TYPE_REQUEST,
    params,
  };
};

export const createDocumentType = (body, callback) => {
  return {
    type: TypeActions.CREATE_DOCUMENT_TYPE_REQUEST,
    body,
    callback,
  };
};
export const editDocumentType = (body, params, callback) => {
  return {
    type: TypeActions.EDIT_DOCUMENT_TYPE_REQUEST,
    body,
    params,
    callback,
  };
};
export const deleteDocumentType = (params, callback) => {
  return {
    type: TypeActions.DELETE_DOCUMENT_TYPE_REQUEST,
    params,
    callback,
  };
};

export default {
  getListDocumentType,
  createDocumentType,
  editDocumentType,
  deleteDocumentType,
  getDocumentType,
};

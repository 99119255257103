import {
  Box,
  colors,
  Divider,
  makeStyles,
  Tab,
  Tabs,
  Typography,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import "react-notifications-component/dist/theme.css";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router";
import { Button, Card, CardFooter, CardHeader } from "reactstrap";
import { tenderProfileActions } from "Redux/Actions";
import queryString from "query-string";
import TabInfo from "./TabInfo";
import OtherTabs from "./OtherTabs";

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  tabs: {
    marginTop: theme.spacing(3),
  },
  divider: {
    backgroundColor: colors.grey[300],
  },
  content: {
    // marginTop: theme.spacing(3),
    padding: theme.spacing(3),
  },
}));

function EditTenderProfileUserForm(props) {
  //!Const
  const history = useHistory();
  const classes = useStyles();
  const dispatch = useDispatch();
  const userInfor = useSelector((state) => state.userReducer.userInfor);

  const { tenderProfileId } = useParams();

  const tenderNoticeById = useSelector(
    (state) => state.tenderNoticeReducer.tenderNoticeById
  );
  const documentForProfile = useSelector(
    (state) => state.documentReducer.documentForProfile
  );
  const isCreating = useSelector((state) => state.userReducer.isCreating);
  const isEditting = useSelector((state) => state.userReducer.isEditting);
  const tenderProfileById = useSelector(
    (state) => state.tenderProfileReducer.tenderProfileById
  );

  //!State

  const [isChanged, setisChanged] = useState(false);
  const [selectedTab, setselectedTab] = useState("Thông tin chung");
  const tabs = [
    { value: "received-product", label: "Tiếp nhận" },
    { value: "guaranteed", label: "Đã bảo hành" },
    { value: "all-product", label: "Tất cả sản phẩm" },
  ];
  const [tabInfo, settabInfo] = useState({});
  //!Function
  function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box p={0}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  const handleTabsChange = (event, value) => {
    setselectedTab(value);
  };

  //!Use effect

  useEffect(() => {
    if (tenderProfileId && tenderProfileId !== null) {
      dispatch(
        tenderProfileActions.getTenderProfile(
          tenderProfileId +
            "?" +
            queryString.stringify({
              populate:
                "documents.listDoc.selectedIds.docSelectId,documents.listDoc.userId,documents.listDoc.documentId,documents.documentTypeId,tBMTInfoId",
            })
        )
      );
    }
  }, [tenderProfileId]);
  useEffect(() => {
    if (tenderProfileId && tenderProfileId !== null && isChanged) {
      dispatch(
        tenderProfileActions.getTenderProfile(
          tenderProfileId +
            "?" +
            queryString.stringify({
              populate:
                "documents.listDoc.selectedIds.docSelectId,documents.listDoc.userId,documents.listDoc.documentId,documents.documentTypeId,tBMTInfoId",
            })
        )
      );
      setisChanged(false);
    }
  }, [isChanged]);
  useEffect(() => {
    if (tenderProfileById !== undefined) {
      settabInfo(tenderProfileById);
    }
  }, [tenderProfileById]);

  //!Render
  return (
    <>
      <Card>
        <CardHeader>
          <h2 className="text-info">
            Thông tin chi tiết quá trình thực hiện làm hồ sơ dự thầu
          </h2>
        </CardHeader>
        <Tabs
          className={classes.tabs}
          onChange={handleTabsChange}
          scrollButtons="auto"
          value={selectedTab}
          variant="scrollable"
          TabIndicatorProps={{
            style: { backgroundColor: "rgba(17, 205, 239, 1)" },
          }}
        >
          <Tab
            key={tabInfo.id}
            label="Thông tin chung"
            value="Thông tin chung"
          />
          {tabInfo.documents &&
            tabInfo.documents.map((tab) => {
              let listIdUser = [];
              tab.listDoc.map((list) => {
                if (list.userId && listIdUser.indexOf(list.userId.id) === -1) {
                  listIdUser.push(list.userId.id);
                }
              });
              if (userInfor.roleId && userInfor.roleId.name === "admin") {
                return (
                  <Tab
                    key={tab._id}
                    label={tab.documentTypeId.name}
                    value={tab.documentTypeId.name}
                  />
                );
              } else if (
                listIdUser.indexOf(localStorage.getItem("id")) !== -1
              ) {
                return (
                  <Tab
                    key={tab._id}
                    label={tab.documentTypeId.name}
                    value={tab.documentTypeId.name}
                  />
                );
              } else {
                return null;
              }
            })}
        </Tabs>
        <Divider className={classes.divider} />
        <div className={classes.content}>
          <TabPanel value={selectedTab} index={"Thông tin chung"}>
            <TabInfo detail={tabInfo} />
          </TabPanel>
          {tabInfo.documents &&
            tabInfo.documents.map((tab) => (
              <TabPanel value={selectedTab} index={tab.documentTypeId.name}>
                <OtherTabs detail={tab} setisChanged={setisChanged} />
              </TabPanel>
            ))}
        </div>
        <CardFooter className="text-center">
          <Button
            className="bg-info text-white"
            onClick={() => {
              history.goBack();
            }}
          >
            Đóng
          </Button>
        </CardFooter>
      </Card>
    </>
  );
}

export default EditTenderProfileUserForm;

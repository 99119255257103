import TypeActions from "../TypeActions";

const initialState = {
  listdocument: [],
  documentById: {},
  documentForProfile: [],
  isGetting: false,
  isCreating: false,
  isEditing: false,
  isDeleting: false,
  error: "",
};

export const documentReducer = (state = initialState, action) => {
  switch (action.type) {
    //!Get list document
    case TypeActions.GET_LIST_DOCUMENT_REQUEST:
      return {
        ...state,
        isGetting: true,
      };
    case TypeActions.GET_LIST_DOCUMENT_SUCCESS:
      return {
        ...state,
        listdocument: action.data,
        isGetting: false,
      };
    case TypeActions.GET_LIST_DOCUMENT_FAILED:
      return {
        ...state,
        error: action.error,
        isGetting: false,
      };
    //!Get document
    case TypeActions.GET_DOCUMENT_REQUEST:
      return {
        ...state,
        isGetting: true,
      };
    case TypeActions.GET_DOCUMENT_SUCCESS:
      return {
        ...state,
        documentById: action.data,
        isGetting: false,
      };
    case TypeActions.GET_DOCUMENT_FAILED:
      return {
        ...state,
        error: action.error,
        isGetting: false,
      };
    //!Get document for profile
    case TypeActions.GET_DOCUMENT_FOR_PROFILE_REQUEST:
      return {
        ...state,
        isGetting: true,
      };
    case TypeActions.GET_DOCUMENT_FOR_PROFILE_SUCCESS:
      return {
        ...state,
        documentForProfile: action.data,
        isGetting: false,
      };
    case TypeActions.GET_DOCUMENT_FOR_PROFILE_FAILED:
      return {
        ...state,
        error: action.error,
        isGetting: false,
      };
    //!Create document
    case TypeActions.CREATE_DOCUMENT_REQUEST:
      return {
        ...state,
        isCreating: true,
      };
    case TypeActions.CREATE_DOCUMENT_SUCCESS:
      return {
        ...state,
        isCreating: false,
      };
    case TypeActions.CREATE_DOCUMENT_FAILED:
      return {
        ...state,
        error: action.error,
        isCreating: false,
      };
    //!Edit document
    case TypeActions.EDIT_DOCUMENT_REQUEST:
      return {
        ...state,
        isEditing: true,
      };
    case TypeActions.EDIT_DOCUMENT_SUCCESS:
      return {
        ...state,
        isEditing: false,
      };
    case TypeActions.EDIT_DOCUMENT_FAILED:
      return {
        ...state,
        error: action.error,
        isEditing: false,
      };
    //!Create child document
    case TypeActions.CREATE_CHILD_DOCUMENT_REQUEST:
      return {
        ...state,
        isCreating: true,
      };
    case TypeActions.CREATE_CHILD_DOCUMENT_SUCCESS:
      return {
        ...state,
        isCreating: false,
      };
    case TypeActions.CREATE_CHILD_DOCUMENT_FAILED:
      return {
        ...state,
        error: action.error,
        isCreating: false,
      };
    //!Edit child document
    case TypeActions.EDIT_CHILD_DOCUMENT_REQUEST:
      return {
        ...state,
        isEditing: true,
      };
    case TypeActions.EDIT_CHILD_DOCUMENT_SUCCESS:
      return {
        ...state,
        isEditing: false,
      };
    case TypeActions.EDIT_CHILD_DOCUMENT_FAILED:
      return {
        ...state,
        error: action.error,
        isEditing: false,
      };
    //!Delete document
    case TypeActions.DELETE_DOCUMENT_REQUEST:
      return {
        ...state,
        isDeleting: true,
      };
    case TypeActions.DELETE_DOCUMENT_SUCCESS:
      return {
        ...state,
        isDeleting: false,
      };
    case TypeActions.DELETE_DOCUMENT_FAILED:
      return {
        ...state,
        error: action.error,
        isDeleting: false,
      };
    //!Default
    default:
      return {
        ...state,
      };
  }
};

export default documentReducer;

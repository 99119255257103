import { combineReducers } from "redux";
import { roleReducer } from "./roleReducer";
import { userReducer } from "./userReducer";
import { keyReducer } from "./keyReducer";
import { documentTypeReducer } from "./documentTypeReducer";
import { documentReducer } from "./documentReducer";
import { tenderNoticeReducer } from "./tenderNoticeReducer";
import { tenderProfileReducer } from "./tenderProfileReducer";
import dashboardReducer from './dashboardReducer';

const rootReducers = combineReducers({
  userReducer,
  roleReducer,
  keyReducer,
  documentTypeReducer,
  documentReducer,
  tenderNoticeReducer,
  tenderProfileReducer,
  dashboardReducer
});
export default rootReducers;

import { toUpper } from "lodash-es";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Col, Row } from "reactstrap";
import queryString from "query-string";
import documentActions from "Redux/Actions/documentActions";
import { Checkbox, Divider, TextField } from "@material-ui/core";

function Note({ setopenPopup, detail, onEnd }) {
  //!Const

  const dispatch = useDispatch();

  //!State
  const [detailNote, setdetailNote] = useState({
    index: "",
    note: "",
  });

  //!Function

  //!Use Effect
  useEffect(() => {
    if (detail) {
      setdetailNote({
        index: detail.index,
        note: detail.note,
      });
    }
  }, [detail]);
  //!Render
  return (
    <>
      <Row className="align-items-center">
        <Col lg="12" md="12" xs="12">
          <TextField
            multiline
            label="Nhập ghi chú"
            rows={5}
            fullWidth
            variant="outlined"
            value={detailNote.note}
            onChange={(e) => {
              setdetailNote({
                ...detailNote,
                note: e.target.value,
              });
            }}
          />
        </Col>
      </Row>
      <Row className="align-items-center text-right">
        <Col lg="12" md="12" xs="12" className="p-2 text-right">
          <span>
            <Button
              className="bg-info text-white"
              onClick={() => {
                onEnd(detailNote);
                setopenPopup(false);
              }}
            >
              Hoàn thành
            </Button>
            <Button
              onClick={() => {
                setopenPopup(false);
              }}
            >
              Huỷ bỏ
            </Button>
          </span>
        </Col>
      </Row>
    </>
  );
}

export default Note;

import _ from "lodash";
import { call, put, takeLatest } from "redux-saga/effects";
import { DELETE, GET, PATCH, POST } from "Service/ServiceBase";
import ServiceURL from "Service/ServiceURL";
import TypeActions from "../TypeActions";

export function* getListDocumentType(data) {
  const url = ServiceURL.DocumentType + "?" + data.params;
  // const callback = data.callback;
  try {
    const res = yield call(GET, url);
    if (res.message && !_.isEmpty(res.message)) {
      yield put({
        type: TypeActions.GET_LIST_DOCUMENT_TYPE_FAILED,
        error: res.message,
      });
    } else {
      yield put({
        type: TypeActions.GET_LIST_DOCUMENT_TYPE_SUCCESS,
        data: res.data,
      });
      // callback && callback.success(res.data);
    }
  } catch (error) {
    yield put({
      type: TypeActions.GET_LIST_DOCUMENT_TYPE_FAILED,
      error: error,
    });
  }
}

export function* getDocumentType(data) {
  const url = ServiceURL.DocumentType + "/" + data.params;
  try {
    const res = yield call(GET, url);
    if (res.message && !_.isEmpty(res.message)) {
      yield put({
        type: TypeActions.GET_DOCUMENT_TYPE_FAILED,
        error: res.message,
      });
    } else {
      yield put({
        type: TypeActions.GET_DOCUMENT_TYPE_SUCCESS,
        data: res.data,
      });
    }
  } catch (error) {
    yield put({
      type: TypeActions.GET_DOCUMENT_TYPE_FAILED,
      error: error,
    });
  }
}

export function* createDocumentType(data) {
  const url = ServiceURL.DocumentType;
  const callback = data.callback;
  try {
    const res = yield call(POST, url, data.body);
    if (res.message && !_.isEmpty(res.message)) {
      yield put({
        type: TypeActions.CREATE_DOCUMENT_TYPE_FAILED,
        error: res.message,
      });
      callback && callback.failed(res.message);
    } else {
      yield put({
        type: TypeActions.CREATE_DOCUMENT_TYPE_SUCCESS,
        // data: res.data.results
      });
      callback && callback.success();
    }
  } catch (error) {
    yield put({
      type: TypeActions.CREATE_DOCUMENT_TYPE_FAILED,
      error: error,
    });
    callback && callback.failed(error?.response?.data?.message);
  }
}

export function* editDocumentType(data) {
  const url = ServiceURL.DocumentType + "/" + data.params;
  const callback = data.callback;
  try {
    const res = yield call(PATCH, url, data.body);
    if (res.message && !_.isEmpty(res.message)) {
      yield put({
        type: TypeActions.EDIT_DOCUMENT_TYPE_FAILED,
        error: res.message,
      });
      callback && callback.failed(res.message);
    } else {
      yield put({
        type: TypeActions.EDIT_DOCUMENT_TYPE_SUCCESS,
        // data: res.data.results
      });
      callback && callback.success();
    }
  } catch (error) {
    yield put({
      type: TypeActions.EDIT_DOCUMENT_TYPE_FAILED,
      error: error,
    });
    callback && callback.failed(error?.response?.data?.message);
  }
}
export function* deleteDocumentType(data) {
  const url = ServiceURL.DocumentType + "/" + data.params;
  const callback = data.callback;
  try {
    const res = yield call(DELETE, url);
    if (res.message && !_.isEmpty(res.message)) {
      yield put({
        type: TypeActions.DELETE_DOCUMENT_TYPE_FAILED,
        error: res.message,
      });
      callback && callback.failed(res.message);
    } else {
      yield put({
        type: TypeActions.DELETE_DOCUMENT_TYPE_SUCCESS,
        // data: res.data.results
      });
      callback && callback.success();
    }
  } catch (error) {
    yield put({
      type: TypeActions.DELETE_DOCUMENT_TYPE_FAILED,
      error: error,
    });
    callback && callback.failed(error);
  }
}

export default function* documentTypeSaga() {
  yield takeLatest(
    TypeActions.GET_LIST_DOCUMENT_TYPE_REQUEST,
    getListDocumentType
  );
  yield takeLatest(TypeActions.GET_DOCUMENT_TYPE_REQUEST, getDocumentType);
  yield takeLatest(
    TypeActions.CREATE_DOCUMENT_TYPE_REQUEST,
    createDocumentType
  );
  yield takeLatest(TypeActions.EDIT_DOCUMENT_TYPE_REQUEST, editDocumentType);
  yield takeLatest(
    TypeActions.DELETE_DOCUMENT_TYPE_REQUEST,
    deleteDocumentType
  );
}

import TypeActions from "../TypeActions";

const initialState = {
  listdocumentType: [],
  documentTypeById: {},
  isGetting: false,
  isCreating: false,
  isEditing: false,
  isDeleting: false,
  error: "",
};

export const documentTypeReducer = (state = initialState, action) => {
  switch (action.type) {
    //!Get list documentType
    case TypeActions.GET_LIST_DOCUMENT_TYPE_REQUEST:
      return {
        ...state,
        isGetting: true,
      };
    case TypeActions.GET_LIST_DOCUMENT_TYPE_SUCCESS:
      return {
        ...state,
        listdocumentType: action.data,
        isGetting: false,
      };
    case TypeActions.GET_LIST_DOCUMENT_TYPE_FAILED:
      return {
        ...state,
        error: action.error,
        isGetting: false,
      };
    //!Get documentType
    case TypeActions.GET_DOCUMENT_TYPE_REQUEST:
      return {
        ...state,
        isGetting: true,
      };
    case TypeActions.GET_DOCUMENT_TYPE_SUCCESS:
      return {
        ...state,
        documentTypeById: action.data,
        isGetting: false,
      };
    case TypeActions.GET_DOCUMENT_TYPE_FAILED:
      return {
        ...state,
        error: action.error,
        isGetting: false,
      };
    //!Create documentType
    case TypeActions.CREATE_DOCUMENT_TYPE_REQUEST:
      return {
        ...state,
        isCreating: true,
      };
    case TypeActions.CREATE_DOCUMENT_TYPE_SUCCESS:
      return {
        ...state,
        isCreating: false,
      };
    case TypeActions.CREATE_DOCUMENT_TYPE_FAILED:
      return {
        ...state,
        error: action.error,
        isCreating: false,
      };
    //!Edit documentType
    case TypeActions.EDIT_DOCUMENT_TYPE_REQUEST:
      return {
        ...state,
        isEditing: true,
      };
    case TypeActions.EDIT_DOCUMENT_TYPE_SUCCESS:
      return {
        ...state,
        isEditing: false,
      };
    case TypeActions.EDIT_DOCUMENT_TYPE_FAILED:
      return {
        ...state,
        error: action.error,
        isEditing: false,
      };
    //!Delete documentType
    case TypeActions.DELETE_DOCUMENT_TYPE_REQUEST:
      return {
        ...state,
        isDeleting: true,
      };
    case TypeActions.DELETE_DOCUMENT_TYPE_SUCCESS:
      return {
        ...state,
        isDeleting: false,
      };
    case TypeActions.DELETE_DOCUMENT_TYPE_FAILED:
      return {
        ...state,
        error: action.error,
        isDeleting: false,
      };
    //!Default
    default:
      return {
        ...state,
      };
  }
};

export default documentTypeReducer;

import TypeActions from "../TypeActions";

export const getListKey = (params) => {
  return {
    type: TypeActions.GET_LIST_KEY_REQUEST,
    params,
    // callback
  };
};
export const getKey = (params) => {
  return {
    type: TypeActions.GET_KEY_REQUEST,
    params,
  };
};

export const createKey = (body, callback) => {
  return {
    type: TypeActions.CREATE_KEY_REQUEST,
    body,
    callback,
  };
};
export const editKey = (body, params, callback) => {
  return {
    type: TypeActions.EDIT_KEY_REQUEST,
    body,
    params,
    callback,
  };
};
export const deleteKey = (params, callback) => {
  return {
    type: TypeActions.DELETE_KEY_REQUEST,
    params,
    callback,
  };
};

export default {
  getListKey,
  createKey,
  editKey,
  deleteKey,
  getKey,
};

import React from "react";
import { Button, Card, CardHeader, Col, Container, Row } from "reactstrap";
import "./Filter.css";

function Filter({ Children, openFilter, setopenFilter }) {
  let filter = null;
  let filterClasses = "side-drawer";
  if (openFilter) {
    filterClasses = "side-drawer open";
  } else {
    filterClasses = "side-drawer";
  }
  return (
    <nav className={filterClasses}>
      <Row style={{ marginLeft: "15px" }}>
        <Button
          style={{ margin: "5px" }}
          color="secondary"
          type="button"
          onClick={() => {
            setopenFilter(false);
          }}
        >
          <i className="ni ni-bold-right" style={{ marginRight: "8px" }} />
          Đóng
        </Button>
      </Row>
      <hr style={{ margin: "5px" }} />
      {Children}
    </nav>
  );
}

export default Filter;

import classnames from 'classnames';
import moment from 'moment';
import queryString from 'query-string';
import React, { useEffect, useRef, useState } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import { store } from 'react-notifications-component';
import 'react-notifications-component/dist/theme.css';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import 'App.css';
import {
  Button,
  Card,
  CardHeader,
  Col,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from 'reactstrap';
import tenderProfileActions from 'Redux/Actions/tenderProfileActions';
import Back from './Filter/Back';
import Filter from './Filter/Filter';

function Results(props) {
  //!Const
  let filter;
  let backdrop;
  if (openFilter) {
    filter = (
      <Filter openFilter={openFilter} setopenFilter={setopenFilter}></Filter>
    );
    backdrop = <Back setopenFilter={setopenFilter}></Back>;
  }

  const dispatch = useDispatch();
  const listTenderProfile = useSelector(
    (state) => state.tenderProfileReducer.listTenderProfile
  );
  const userInfor = useSelector((state) => state.userReducer.userInfor);

  const isGetting = useSelector(
    (state) => state.tenderProfileReducer.isGetting
  );
  const typingTimeOutRef = useRef(null);
  const history = useHistory();
  const permission = useSelector((state) =>
    state.userReducer.userInfor.roleId !== undefined
      ? state.userReducer.userInfor.roleId.permission
      : []
  );
  //!State
  const [openFilter, setopenFilter] = useState(false);
  const [detailFilter, setdetailFilter] = useState({});
  const [openModal, setopenModal] = useState(false);
  const [page, setpage] = useState(0);
  const [searchFocus, setsearchFocus] = useState(false);
  const [rowsPerPage, setrowsPerPage] = useState(10);
  const [totalResult, settotalResult] = useState(0);
  const [search, setsearch] = useState('');
  const [idDelete, setidDelete] = useState('');
  const [dataTable, setdataTable] = useState([]);
  const [detailParams, setdetailParams] = useState({
    page: page + 1,
    limit: rowsPerPage,
    populate: 'tBMTInfoId',
    sortBy: 'createdAt:desc',
  });
  if (openFilter) {
    backdrop = <Back setopenFilter={setopenFilter}></Back>;
  }
  //!Function
  const pagination = paginationFactory({
    // page: page + 1,
    page: page + 1,
    onPageChange: (page, rowsPerPage) => {
      setpage(page - 1);
    },
    sizePerPage: rowsPerPage,
    // onSizePerPageChange: (page, rowsPerPage) => {
    //   setrowsPerPage(rowsPerPage);
    // },
    onSizePerPageChange: (value) => {
      setrowsPerPage(value);
      setpage(0);
    },
    totalSize: totalResult,
    showTotal: false,
    withFirstAndLast: true,
    alwaysShowAllBtns: true,
    sizePerPageRenderer: ({
      options,
      currSizePerPage,
      onSizePerPageChange,
    }) => (
      <div className="dataTables_length" id="datatable-basic_length">
        <label>
          Hiển thị{' '}
          {
            <select
              value={rowsPerPage}
              name="datatable-basic_length"
              aria-controls="datatable-basic"
              className="form-control form-control-sm"
              onChange={(e) => onSizePerPageChange(e.target.value)}
            >
              <option value="10">10</option>
              <option value="25">25</option>
              <option value="50">50</option>
              <option value="100">100</option>
            </select>
          }{' '}
          dòng.
        </label>
      </div>
    ),
  });
  //!Use effect
  useEffect(() => {
    setpage(0);
    if (Object.keys(detailFilter).length > 0) {
      setdetailParams({
        ...detailParams,
        ...detailFilter,
      });
    } else {
      if (search !== '') {
        setdetailParams({
          page: page + 1,
          limit: rowsPerPage,
          tBMTTitle: search,
          populate: 'tBMTInfoId',
          sortBy: 'createdAt:desc',
        });
      } else {
        setdetailParams({
          page: page + 1,
          limit: rowsPerPage,
          populate: 'tBMTInfoId',
          sortBy: 'createdAt:desc',
        });
      }
    }
  }, [detailFilter]);

  useEffect(() => {
    setdetailParams({
      ...detailParams,
      page: page + 1,
      limit: rowsPerPage,
    });
  }, [page, rowsPerPage]);
  useEffect(() => {
    dispatch(
      tenderProfileActions.getListTenderProfile(
        queryString.stringify(detailParams)
      )
    );
  }, [detailParams]);
  useEffect(() => {
    if (idDelete === '')
      dispatch(
        tenderProfileActions.getListTenderProfile(
          queryString.stringify(detailParams)
        )
      );
  }, [idDelete]);
  useEffect(() => {
    if (listTenderProfile && listTenderProfile.results !== undefined) {
      setdataTable(listTenderProfile.results);
      settotalResult(listTenderProfile.totalResults);
    }
  }, [listTenderProfile]);

  //!Render
  return (
    <>
      {/* The Modal */}
      <Modal
        isOpen={openModal}
        toggle={() => {
          setopenModal(!openModal);
        }}
        className="text-center"
      >
        <ModalHeader
          toggle={() => {
            setopenModal(!openModal);
          }}
        >
          <h2 className="text-danger">Thông báo!</h2>
        </ModalHeader>
        <ModalBody>Bạn có chắc chắn muốn xoá hồ sơ thầu?</ModalBody>
        <ModalFooter>
          <Button
            color="primary"
            onClick={() => {
              setopenModal(false);

              dispatch(
                tenderProfileActions.deleteTenderProfile(idDelete, {
                  success: () => {
                    store.addNotification({
                      title: 'Thông báo!',
                      message: 'Xoá hồ sơ dự thầu thành công!',
                      type: 'success', // 'default', 'success', 'info', 'warning'
                      container: 'bottom-right', // where to position the notifications
                      animationIn: ['animated', 'fadeIn'], // animate.css classes that's applied
                      animationOut: ['animated', 'fadeOut'], // animate.css classes that's applied
                      dismiss: {
                        pauseOnHover: true,
                        onScreen: true,
                        duration: 3000,
                      },
                    });
                    setidDelete('');
                  },
                  failed: (mess) => {
                    store.addNotification({
                      title: 'Thông báo!',
                      message:
                        'Xoá hồ sơ dự thầu thất bại! Lỗi: ' +
                        mess +
                        '! Vui lòng thử lại!',
                      type: 'danger', // 'default', 'success', 'info', 'warning'
                      container: 'bottom-right', // where to position the notifications
                      animationIn: ['animated', 'fadeIn'], // animate.css classes that's applied
                      animationOut: ['animated', 'fadeOut'], // animate.css classes that's applied
                      dismiss: {
                        onScreen: true,
                        pauseOnHover: true,
                        duration: 5000,
                      },
                    });
                    setidDelete('');
                  },
                })
              );
            }}
          >
            Đồng ý
          </Button>{' '}
          <Button
            color="secondary"
            onClick={() => {
              setopenModal(false);
            }}
          >
            Huỷ bỏ
          </Button>
        </ModalFooter>
      </Modal>
      {/* The Modal */}

      <Card>
        <CardHeader>
          {filter}
          {backdrop}
          <Row className="align-items-center ">
            <Col lg="6" md="7" sm="12">
              <h3 className="mb-0">
                Danh sách thông báo ({totalResult} bản ghi)
              </h3>
            </Col>
            <Col className="mt-3 mt-md-0 text-md-right" lg="6" md="5" sm="12">
              {/* <Button>
                <i className="ni ni-bold-down"></i>
              </Button> */}
              <Button
                type="button"
                color="info"
                onClick={() => {
                  setopenFilter(true);
                }}
              >
                <i
                  className="ni ni-bold-down"
                  style={{
                    marginRight: '10px',
                  }}
                />
                Hiển thị lọc
              </Button>
              <Filter
                openFilter={openFilter}
                setopenFilter={setopenFilter}
                onFilter={(val) => {
                  setdetailFilter(val);
                }}
              />
            </Col>
          </Row>
        </CardHeader>
        <ToolkitProvider
          data={dataTable}
          keyField="id"
          columns={[
            {
              dataField: 'id',
              text: 'ID',
              sort: false,
              hidden: true,
            },
            {
              dataField: 'tbmtNo',
              text: 'Số TBMT',
              sort: false,
              formatter: (val, valRow, key) => {
                return (
                  <a
                    className="font-weight-bold"
                    href={valRow.tBMTInfoId && valRow.tBMTInfoId.link}
                    target="_blank"
                  >
                    {valRow.tBMTInfoId !== null && valRow.tBMTInfoId.tbmtNo}
                  </a>
                );
              },
            },
            {
              dataField: 'title',
              text: 'Tên gói thầu',
              sort: false,
              formatter: (val, valRow, key) => {
                return (
                  <div
                    style={{
                      wordWrap: 'break-word !important',
                      width: '300px',
                      whiteSpace: 'normal',
                    }}
                  >
                    {valRow.tBMTInfoId && valRow.tBMTInfoId.title}
                  </div>
                );
              },
            },
            {
              dataField: 'bmt',
              text: 'Bên mời thầu',
              sort: false,
              formatter: (val, valRow, key) => {
                return (
                  <div
                    style={{
                      wordWrap: 'break-word !important',
                      width: '300px',
                      whiteSpace: 'normal',
                    }}
                  >
                    {valRow.tBMTInfoId && valRow.tBMTInfoId.bmt}
                  </div>
                );
              },
            },
            {
              dataField: 'dueDate',
              text: 'Thời gian đóng thầu',
              sort: false,
              headerAlign: 'center',
              align: 'center',
              formatter: (val, valRow, key) => {
                return moment(
                  valRow.tBMTInfoId && valRow.tBMTInfoId.dueDate
                ).format('hh:mm - DD/MM/YYYY');
              },
            },
            {
              dataField: 'profileStatus',
              text: 'Trạng thái thực hiện',
              sort: false,
              headerAlign: 'center',
              align: 'center',
              formatter: (val, valRow, key) => {
                if (val === 'Đang thực hiện') {
                  return <a className="text-info">{val}</a>;
                } else {
                  return <a className="text-success">{val}</a>;
                }
              },
            },
            {
              dataField: 'action',
              text: 'Hành động',
              sort: false,
              headerAlign: 'center',
              align: 'center',
              formatter: (val, valRow) => {
                return (
                  <div className="d-flex justify-content-center">
                    {/* {val ? null : ( // </Button> //   </span> //     <i className="fas fa-check" /> //   <span className="btn-inner--icon"> // > //   }} //     history.push(`modify-user/${valRow.id}`); //   onClick={() => { //   }} //     backgroundColor: "rgba(186, 188, 190, 1)", //   style={{ //   type="button" //   title="Tạo hồ sơ dự thầu" //   data-toggle="tooltip" //   // color="default" //   className="btn-icon-only rounded-circle text-white" // <Button
                      <Button
                        className="btn-icon-only rounded-circle text-white"
                        disabled
                        data-toggle="tooltip"
                        title="Tạo hồ sơ dự thầu"
                        type="button"
                        onClick={() => {
                          history.push(`modify-user/${valRow.id}`);
                        }}
                        style={{
                          backgroundColor: "rgba(17, 205, 239, 1)",
                        }}
                      >
                        <span className="btn-inner--icon">
                          <i className="fas fa-check" />
                        </span>
                      </Button>
                    )} */}
                    {userInfor.roleId && userInfor.roleId.name === 'admin' ? (
                      <Button
                        className="btn-icon-only rounded-circle bg-success text-white btn-sm m-0"
                        data-toggle="tooltip"
                        title="Chỉnh sửa hồ sơ dự thầu"
                        type="button"
                        onClick={() => {
                          history.push(
                            `/modify-tender-profile/${
                              valRow.tBMTInfoId !== null
                                ? valRow.tBMTInfoId.id
                                : ''
                            }/${valRow.id}`
                          );
                        }}
                      >
                        <span className="btn-inner--icon">
                          <i className="fas fa-edit" />
                        </span>
                      </Button>
                    ) : null}
                    <Button
                      className="btn-icon-only rounded-circle bg-info text-white btn-sm m-0"
                      data-toggle="tooltip"
                      title="Chi tiết hồ sơ dự thầu"
                      type="button"
                      onClick={() => {
                        history.push(`/tender-profile-detail/${valRow.id}`);
                      }}
                    >
                      <span className="btn-inner--icon">
                        <i className="fas fa-external-link-alt" />
                      </span>
                    </Button>
                    {permission !== undefined &&
                    permission.indexOf('manage_tBMTInfo') !== -1 ? (
                      <Button
                        className="btn-icon-only rounded-circle bg-danger text-white btn-sm m-0"
                        data-toggle="tooltip"
                        title="Xoá hồ sơ dự thầu"
                        type="button"
                        onClick={() => {
                          // history.push(`tender-profile-detail/${valRow.id}`);
                          setidDelete(valRow.id);
                          setopenModal(true);
                        }}
                      >
                        <span className="btn-inner--icon">
                          <i className="fas fa-trash-alt" />
                        </span>
                      </Button>
                    ) : null}
                  </div>
                );
              },
            },
          ]}
          search
        >
          {(props) => (
            <div className="py-4 table-responsive table-custom-tender-profile">
              <div id="datatable-basic_filter" className="dataTables px-4 pb-1">
                {/* <label>
                  Search:
                  <SearchBar
                    className="form-control-sm"
                    placeholder=""
                    {...props.searchProps}
                  />
                </label> */}
                <Row>
                  <Col md="6" lg="6" sm="12" className="align-items-center">
                    <FormGroup className="d-flex align-items-center">
                      <InputGroup
                        className={classnames('input-group-merge', {
                          focused: searchFocus,
                        })}
                      >
                        <Input
                          placeholder="Nhập tên gói thầu tìm kiếm"
                          type="text"
                          value={search}
                          onFocus={(e) => setsearchFocus(true)}
                          onBlur={(e) => setsearchFocus(false)}
                          onChange={(e) => {
                            const value = e.target.value;
                            setsearch(e.target.value);
                            if (typingTimeOutRef.current) {
                              clearTimeout(typingTimeOutRef.current);
                            }
                            typingTimeOutRef.current = setTimeout(() => {
                              if (value !== '') {
                                setdetailParams({
                                  ...detailParams,
                                  tBMTTitle: value,
                                });
                              } else {
                                if (Object.keys(detailFilter).length > 0) {
                                  setdetailParams({
                                    page: page + 1,
                                    limit: rowsPerPage,
                                    sortBy: 'createdAt:desc',
                                    ...detailFilter,
                                  });
                                } else {
                                  setdetailParams({
                                    page: page + 1,
                                    limit: rowsPerPage,
                                    populate: 'tBMTInfoId',
                                    sortBy: 'createdAt:desc',
                                  });
                                }
                              }
                              setpage(0);
                            }, 300);
                          }}
                        />
                        <InputGroupAddon addonType="append">
                          <InputGroupText>
                            <i className="fas fa-search" />
                          </InputGroupText>
                        </InputGroupAddon>
                      </InputGroup>
                    </FormGroup>
                  </Col>
                  <Col
                    md="6"
                    lg="6"
                    sm="12"
                    className="align-items-center text-right"
                  >
                    {/* <Button
                      type="button"
                      // color="info"
                      // href="modify-user/add"
                      onClick={() => {
                        history.push(`modify-user/add`);
                      }}
                    >
                      <i
                        className="fas fa-plus"
                        style={{
                          marginRight: "10px",
                        }}
                      />
                      Thêm mới tài khoản
                    </Button> */}
                  </Col>
                </Row>
              </div>
              {isGetting ? (
                <Row className="align-items-center ">
                  <Col
                    lg="12"
                    md="12"
                    xs="12"
                    className="d-flex justify-content-center"
                  >
                    <div class="spinner-border text-info" />
                  </Col>
                </Row>
              ) : (
                <BootstrapTable
                  {...props.baseProps}
                  noDataIndication={() => {
                    return (
                      <span className="font-weight-bold text-danger">
                        Không có dữ liệu!
                      </span>
                    );
                  }}
                  onTableChange={() => {
                    return <div class="spinner-border text-info" />;
                  }}
                  hover
                  remote
                  bootstrap4={true}
                  pagination={pagination}
                  bordered={false}
                />
              )}
            </div>
          )}
        </ToolkitProvider>
      </Card>
    </>
  );
}

export default Results;

/*!

=========================================================
* Argon Dashboard PRO React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import AdminFooter from 'components/Footers/AdminFooter.js';
// core components
import AdminNavbar from 'components/Navbars/AdminNavbar.js';
import Sidebar from 'components/Sidebar/Sidebar.js';
import queryString from 'query-string';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
// react library for routing
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useLocation,
  useParams,
} from 'react-router-dom';
import userActions from 'Redux/Actions/userActions';
import routes from 'routes.js';

function Admin() {
  //!Const
  const history = useHistory();
  const { tab } = useParams();
  const location = useLocation();
  const dispatch = useDispatch();
  const userInfor = useSelector((state) => state.userReducer.userInfor);
  //!State
  const [sidenavOpen, setSidenavOpen] = React.useState(true);
  const mainContentRef = React.useRef(null);
  // React.useEffect(() => {
  //   document.documentElement.scrollTop = 0;
  //   document.scrollingElement.scrollTop = 0;
  //   mainContentRef.current.scrollTop = 0;
  // }, [location]);
  //!Function
  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.collapse) {
        return getRoutes(prop.views);
      }
      if (prop.layout === '/') {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };
  const getBrandText = (path) => {
    for (let i = 0; i < routes.length; i++) {
      if (location.pathname.indexOf(routes[i].layout + routes[i].path) !== -1) {
        return routes[i].name;
      }
    }
    return 'Brand';
  };
  // toggles collapse between mini sidenav and normal
  const toggleSidenav = (e) => {
    if (document.body.classList.contains('g-sidenav-pinned')) {
      document.body.classList.remove('g-sidenav-pinned');
      document.body.classList.remove('g-sidenav-show');
      document.body.classList.add('g-sidenav-hidden');
    } else {
      document.body.classList.add('g-sidenav-pinned');
      document.body.classList.add('g-sidenav-show');
      document.body.classList.remove('g-sidenav-hidden');
    }
    setSidenavOpen(!sidenavOpen);
  };
  const getNavbarTheme = () => {
    return location.pathname.indexOf('admin/alternative-dashboard') === -1
      ? 'dark'
      : 'light';
  };

  //!Use Effect
  React.useEffect(() => {
    dispatch(
      userActions.getUsersInfor(
        localStorage.getItem('id') +
          '?' +
          queryString.stringify({ populate: 'roleId' })
      )
    );
  }, []);
  React.useEffect(() => {
    if (
      !!userInfor &&
      userInfor.isPasswordChange === false &&
      // tab !== undefined &&
      tab !== 'change-password'
    ) {
      history.push(
        `/user-profile/${localStorage.getItem('id')}/change-password`
      );
    }
  }, [window.location.href, userInfor]);
  if (
    !localStorage.getItem('token') ||
    localStorage.getItem('token') === '' ||
    !localStorage.getItem('refreshtoken') ||
    localStorage.getItem('refreshtoken') === '' ||
    !localStorage.getItem('id') ||
    localStorage.getItem('id') === ''
    // ||
    // !localStorage.getItem("roleId") ||
    // localStorage.getItem("roleId") === ""
  ) {
    return <Redirect to="/auth/login" />;
  }
  //!Render
  return (
    <>
      <Sidebar
        routes={routes}
        toggleSidenav={toggleSidenav}
        sidenavOpen={sidenavOpen}
        logo={{
          innerLink: '/dashboard',
          imgSrc: require('assets/img/brand/Fovina.png').default,
          imgAlt: '...',
        }}
      />
      <div className="main-content" ref={mainContentRef}>
        <AdminNavbar
          detail={userInfor}
          theme={getNavbarTheme()}
          toggleSidenav={toggleSidenav}
          sidenavOpen={sidenavOpen}
          brandText={getBrandText(location.pathname)}
        />
        <hr
          style={{
            // border: 0,
            marginBottom: 1,
            marginTop: 0,
          }}
        />
        <Switch>
          {getRoutes(routes)}
          <Redirect from="*" to="/dashboard" />
        </Switch>
        <AdminFooter />
      </div>
      {sidenavOpen ? (
        <div className="backdrop d-xl-none" onClick={toggleSidenav} />
      ) : null}
    </>
  );
}

export default Admin;

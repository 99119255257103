import { TextField } from "@material-ui/core";
import { Formik } from "formik";
import React, { useEffect, useState } from "react";
import { store } from "react-notifications-component";
import "react-notifications-component/dist/theme.css";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router";
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  Row,
} from "reactstrap";
import documentTypeActions from "Redux/Actions/documentTypeActions";
import * as yup from "yup";

function AddDocumentTypeForm(props) {
  //!Const
  const history = useHistory();
  const dispatch = useDispatch();
  const isCreating = useSelector(
    (state) => state.documentTypeReducer.isCreating
  );
  const isEditting = useSelector(
    (state) => state.documentTypeReducer.isEditting
  );
  const documentTypeById = useSelector(
    (state) => state.documentTypeReducer.documentTypeById
  );
  const userSchema = yup.object().shape({
    code: yup.string().required("Vui lòng nhập mã thể loại!"),
    index: yup.number().required("Vui lòng nhập thứ tự đê mục!"),
    name: yup.string().required("Vui lòng nhập tên thể loại!"),
  });
  //!State
  const [detailParams, setdetailParams] = useState({
    code: "",
    index: "",
    name: "",
  });
  const { documentTypeId } = useParams();
  //!Function
  const handleUser = (value) => {
    if (
      !!documentTypeId &&
      documentTypeId !== null &&
      documentTypeId === "add"
    ) {
      dispatch(
        documentTypeActions.createDocumentType(value, {
          success: () => {
            store.addNotification({
              title: "Thông báo!",
              message: "Thêm thể loại thành công!",
              type: "success", // 'default', 'success', 'info', 'warning'
              container: "bottom-right", // where to position the notifications
              animationIn: ["animated", "fadeIn"], // animate.css classes that's applied
              animationOut: ["animated", "fadeOut"], // animate.css classes that's applied
              dismiss: {
                pauseOnHover: true,
                onScreen: true,
                duration: 3000,
              },
            });
            history.goBack();
          },
          failed: (mess) => {
            store.addNotification({
              title: "Thông báo!",
              message: "Thêm mới thể loại thất bại! Lỗi: " + mess + "! Vui lòng thử lại!",
              type: "danger", // 'default', 'success', 'info', 'warning'
              container: "bottom-right", // where to position the notifications
              animationIn: ["animated", "fadeIn"], // animate.css classes that's applied
              animationOut: ["animated", "fadeOut"], // animate.css classes that's applied
              dismiss: {
                onScreen: true,
                pauseOnHover: true,
                duration: 5000,
              },
            });
          },
        })
      );
    } else if (
      !!documentTypeId &&
      documentTypeId !== null &&
      documentTypeId !== "add"
    ) {
      dispatch(
        documentTypeActions.editDocumentType(value, documentTypeId, {
          success: () => {
            store.addNotification({
              title: "Thông báo!",
              message: "Cập nhật thể loại thành công!",
              type: "success", // 'default', 'success', 'info', 'warning'
              container: "bottom-right", // where to position the notifications
              animationIn: ["animated", "fadeIn"], // animate.css classes that's applied
              animationOut: ["animated", "fadeOut"], // animate.css classes that's applied
              dismiss: {
                pauseOnHover: true,
                onScreen: true,
                duration: 3000,
              },
            });
            history.goBack();
          },
          failed: (mess) => {
            store.addNotification({
              title: "Thông báo!",
              message: "Cập nhật thể loại thất bại! Lỗi: " + mess + "! Vui lòng thử lại!",
              type: "danger", // 'default', 'success', 'info', 'warning'
              container: "bottom-right", // where to position the notifications
              animationIn: ["animated", "fadeIn"], // animate.css classes that's applied
              animationOut: ["animated", "fadeOut"], // animate.css classes that's applied
              dismiss: {
                onScreen: true,
                pauseOnHover: true,
                duration: 5000,
              },
            });
          },
        })
      );
    }
  };

  //!Use effect
  useEffect(() => {
    if (documentTypeId && documentTypeId !== "add") {
      dispatch(documentTypeActions.getDocumentType(documentTypeId));
    }
  }, [documentTypeId]);
  useEffect(() => {
    if (documentTypeId !== "add" && documentTypeById !== undefined) {
      setdetailParams({
        code: documentTypeById.code,
        index: documentTypeById.index,
        name: documentTypeById.name,
      });
    } else {
      setdetailParams({
        code: "",
        index: "",
        name: "",
      });
    }
  }, [documentTypeById]);

  //!Render
  return (
    <>
      <Card>
        <CardHeader>
          <Row className="align-items-center ">
            <Col lg="6" md="7" xs="12" className="p-2">
              <h3 className="mb-0">
                {documentTypeId === "add"
                  ? "Thêm mới thể loại"
                  : "Chỉnh sửa thể loại"}
              </h3>
            </Col>
            <Col className="mt-3 mt-md-0 text-md-right" lg="6" md="5" xs="12">
              {/* <Button>
                <i className="ni ni-bold-down"></i>
              </Button> */}
            </Col>
          </Row>
        </CardHeader>
        <Formik
          initialValues={detailParams}
          enableReinitialize
          onSubmit={handleUser}
          validationSchema={userSchema}
        >
          {({ values, setFieldValue, handleSubmit, errors, touched }) => {
            return (
              <>
                <CardBody>
                  <Row className="align-items-flex-start">
                    <Col
                      className="align-items-flex-start"
                      lg="12"
                      md="12"
                      xs="12"
                    >
                      <Row className="align-items-flex-start ">
                        <Col lg="6" md="6" xs="12" className="p-2">
                          <TextField
                            label="Mã thể loại *"
                            name="code"
                            value={values.code}
                            variant="outlined"
                            fullWidth
                            type="text"
                            onChange={(e) => {
                              setFieldValue("code", e.target.value);
                            }}
                          />
                          {errors.code && touched.code && (
                            <a
                              style={{
                                fontSize: 15,
                                
                                color: "red",
                                paddingLeft: "3px",
                              }}
                            >
                              {errors.code}
                            </a>
                          )}
                        </Col>
                        <Col lg="6" md="6" xs="12" className="p-2">
                          <TextField
                            label="Tên thể loại *"
                            name="name"
                            value={values.name}
                            variant="outlined"
                            fullWidth
                            type="text"
                            onChange={(e) => {
                              setFieldValue("name", e.target.value);
                            }}
                          />
                          {errors.name && touched.name && (
                            <a
                              style={{
                                fontSize: 15,
                                
                                color: "red",
                                paddingLeft: "3px",
                              }}
                            >
                              {errors.name}
                            </a>
                          )}
                        </Col>
                      </Row>
                      <Row className="align-items-flex-start ">
                        <Col lg="6" md="6" xs="12" className="p-2">
                          <TextField
                            label="Thứ tự đề mục *"
                            name="index"
                            value={values.index}
                            variant="outlined"
                            fullWidth
                            type="number"
                            onChange={(e) => {
                              setFieldValue("index", e.target.value);
                            }}
                          />
                          {errors.index && touched.index && (
                            <a
                              style={{
                                fontSize: 15,
                                
                                color: "red",
                                paddingLeft: "3px",
                              }}
                            >
                              {errors.index}
                            </a>
                          )}
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </CardBody>
                <CardFooter>
                  <Row className="align-items-center text-right">
                    <Col lg="12" md="12" xs="12">
                      {isEditting || isCreating ? (
                        <div class="spinner-border text-info" />
                      ) : (
                        <span>
                          <Button
                            className="bg-info text-white"
                            onClick={handleSubmit}
                          >
                            <i className="fas fa-check" />{" "}
                            {documentTypeId === "add" ? "Thêm mới" : "Cập nhật"}
                          </Button>
                          {/* {avatar ? <br /> : null} */}
                          <Button
                            // className=""
                            onClick={() => {
                              history.goBack();
                            }}
                          >
                            <i className="fas fa-times" /> Huỷ bỏ
                          </Button>
                        </span>
                      )}
                    </Col>
                  </Row>
                </CardFooter>
              </>
            );
          }}
        </Formik>
      </Card>
    </>
  );
}

export default AddDocumentTypeForm;

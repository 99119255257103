import { TextField } from "@material-ui/core";
import { Formik } from "formik";
import React, { useEffect, useState } from "react";
import { store } from "react-notifications-component";
import "react-notifications-component/dist/theme.css";
import { useDispatch } from "react-redux";
import { useParams } from "react-router";
import { Button, Col, Row } from "reactstrap";
import documentActions from "Redux/Actions/documentActions";
import * as yup from "yup";

function AddDocumentChildForm({ setopenPopup, detail, onEdit, onAdd }) {
  //!Const
  const dispatch = useDispatch();
  const { documentId } = useParams();
  const documentSchema = yup.object().shape({
    name: yup.string().required("Vui lòng nhập tên tài liệu!"),
    code: yup.string().required("Vui lòng nhập mã tài liệu!"),
  });
  //!State
  const [detailParams, setdetailParams] = useState({
    name: "",
    code: "",
    parentId: documentId,
  });
  //!Function
  const handleDocument = (value) => {
    if (detail === null) {
      dispatch(
        documentActions.createChildDocument(value, {
          success: (data) => {
            store.addNotification({
              title: "Thông báo!",
              message: "Thêm tài liệu bổ sung thành công!",
              type: "success", // 'default', 'success', 'info', 'warning'
              container: "bottom-right", // where to position the notifications
              animationIn: ["animated", "fadeIn"], // animate.css classes that's applied
              animationOut: ["animated", "fadeOut"], // animate.css classes that's applied
              dismiss: {
                pauseOnHover: true,
                onScreen: true,
                duration: 3000,
              },
            });
            setopenPopup(false);
            onAdd(data);
          },
          failed: (mess) => {
            store.addNotification({
              title: "Thông báo!",
              message: "Thêm tài liệu bổ sung thất bại! Lỗi: " + mess + "! Vui lòng thử lại!",
              type: "danger", // 'default', 'success', 'info', 'warning'
              container: "bottom-right", // where to position the notifications
              animationIn: ["animated", "fadeIn"], // animate.css classes that's applied
              animationOut: ["animated", "fadeOut"], // animate.css classes that's applied
              dismiss: {
                onScreen: true,
                pauseOnHover: true,
                duration: 5000,
              },
            });
          },
        })
      );
    } else {
      const detailEdit = { name: value.name, code: value.code };
      dispatch(
        documentActions.editChildDocument(detailEdit, detail.id, {
          success: (data) => {
            store.addNotification({
              title: "Thông báo!",
              message: "Cập nhật liệu bổ sung thành công!",
              type: "success", // 'default', 'success', 'info', 'warning'
              container: "bottom-right", // where to position the notifications
              animationIn: ["animated", "fadeIn"], // animate.css classes that's applied
              animationOut: ["animated", "fadeOut"], // animate.css classes that's applied
              dismiss: {
                pauseOnHover: true,
                onScreen: true,
                duration: 3000,
              },
            });
            setopenPopup(false);
            onEdit(data);
          },
          failed: (mess) => {
            store.addNotification({
              title: "Thông báo!",
              message: "Cập nhật liệu bổ sung thất bại! Lỗi: " + mess + "! Vui lòng thử lại!",
              type: "danger", // 'default', 'success', 'info', 'warning'
              container: "bottom-right", // where to position the notifications
              animationIn: ["animated", "fadeIn"], // animate.css classes that's applied
              animationOut: ["animated", "fadeOut"], // animate.css classes that's applied
              dismiss: {
                onScreen: true,
                pauseOnHover: true,
                duration: 5000,
              },
            });
          },
        })
      );
    }
  };
  //!UseEffect
  useEffect(() => {
    if (detail !== null) {
      setdetailParams({
        name: detail.name,
        code: detail.code,
        parentId: documentId,
      });
    } else {
      setdetailParams({
        name: "",
        code: "",
        parentId: documentId,
      });
    }
  }, [detail]);
  //!Render
  return (
    <Formik
      initialValues={detailParams}
      enableReinitialize
      onSubmit={handleDocument}
      validationSchema={documentSchema}
    >
      {({ values, setFieldValue, handleSubmit, errors, touched }) => {
        return (
          <>
            <Row className="align-items-center">
              <Col lg="12" md="12" xs="12" className="p-2">
                <TextField
                  label="Mã tài liệu"
                  fullWidth
                  variant="outlined"
                  name="code"
                  value={values.code}
                  onChange={(e) => {
                    setFieldValue("code", e.target.value);
                  }}
                />
                {errors.code && touched.code && (
                  <a
                    style={{
                      fontSize: 15,
                      
                      color: "red",
                      paddingLeft: "3px",
                    }}
                  >
                    {errors.code}
                  </a>
                )}
              </Col>
              <Col lg="12" md="12" xs="12" className="p-2">
                <TextField
                  label="Tên tài liệu"
                  fullWidth
                  variant="outlined"
                  name="name"
                  value={values.name}
                  onChange={(e) => {
                    setFieldValue("name", e.target.value);
                  }}
                />
                {errors.name && touched.name && (
                  <a
                    style={{
                      fontSize: 15,
                      
                      color: "red",
                      paddingLeft: "3px",
                    }}
                  >
                    {errors.name}
                  </a>
                )}
              </Col>
            </Row>
            <Row className="align-items-center">
              <Col lg="12" md="12" xs="12" className="p-2 text-right">
                <span>
                  <Button className="bg-info text-white" onClick={handleSubmit}>
                    <i className="fas fa-check" />{" "}
                    {detail === null ? "Thêm mới" : "Cập nhật"}
                  </Button>
                  {/* {avatar ? <br /> : null} */}
                  <Button
                    // className=""
                    onClick={() => {
                      // history.goBack();
                      setopenPopup(false);
                    }}
                  >
                    <i className="fas fa-times" /> Huỷ bỏ
                  </Button>
                </span>
              </Col>
            </Row>
          </>
        );
      }}
    </Formik>
  );
}

export default AddDocumentChildForm;

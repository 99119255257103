import React from "react";
import { Search } from "react-bootstrap-table2-toolkit";
import { useParams } from "react-router";
import { Container, Row } from "reactstrap";
import AddDocumentTypeForm from "./AddDocumentTypeForm";
import Header from "./Header";

const { SearchBar } = Search;

function AddDocumentType(props) {
  //!Const
  const { documentTypeId } = useParams();
  //!State
  //!Function

  //!Render
  return (
    <>
      <Header
        name={
          documentTypeId === "add" ? "Thêm mới thể loại" : "Cập nhật thể loại"
        }
        parentName="Quản lý thể loại"
      />
      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <AddDocumentTypeForm />
          </div>
        </Row>
        {/* <Row>
          <div className="col">
            <Card className="border-0"></Card>
          </div>
        </Row> */}
      </Container>
    </>
  );
}

export default AddDocumentType;

import {
  Dialog,
  DialogContent,
  DialogTitle,
  makeStyles,
  Slide,
  Typography,
} from "@material-ui/core";
import { toUpper } from "lodash-es";
import React from "react";

const useStyle = makeStyles((theme) => ({
  dialogWrapper: {
    // padding: theme.spacing(2),
    position: "absolute",
    top: theme.spacing(1),
    borderRadius: "20px",
  },
}));
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});
Transition.displayName = "Transition";

function Popup(props) {
  const classes = useStyle();
  const { title, children, openPopup, setopenPopup, maxWidth, fullWidth } =
    props;
  return (
    <Dialog
      open={openPopup}
      fullWidth={fullWidth}
      maxWidth={!!maxWidth && maxWidth !== "" ? maxWidth : "md"}
      TransitionComponent={Transition}
      classes={{ paper: classes.dialogWrapper }}
    >
      <DialogTitle style={{ backgroundColor: "#11cdef", padding: "20px" }}>
        <h2 className="text-white">{title}</h2>
      </DialogTitle>
      <DialogContent dividers>{children}</DialogContent>
    </Dialog>
  );
}
export default Popup;

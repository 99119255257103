import TypeActions from "../TypeActions";

const initialState = {
  listTenderNotice: [],
  tenderNoticeById: {},
  isGetting: false,
  isCreating: false,
  isEditing: false,
  isDeleting: false,
  isUpdateTenderNote: false,
  error: "",
};

export const tenderNoticeReducer = (state = initialState, action) => {
  switch (action.type) {
    //!Get list tenderNotice
    case TypeActions.GET_LIST_TENDER_NOTICE_REQUEST:
      return {
        ...state,
        isGetting: true,
      };
    case TypeActions.GET_LIST_TENDER_NOTICE_SUCCESS:
      return {
        ...state,
        listTenderNotice: action.data,
        isGetting: false,
      };
    case TypeActions.GET_LIST_TENDER_NOTICE_FAILED:
      return {
        ...state,
        error: action.error,
        isGetting: false,
      };
    //!Get tenderNotice
    case TypeActions.GET_TENDER_NOTICE_REQUEST:
      return {
        ...state,
        isGetting: true,
      };
    case TypeActions.GET_TENDER_NOTICE_SUCCESS:
      return {
        ...state,
        tenderNoticeById: action.data,
        isGetting: false,
      };
    case TypeActions.GET_TENDER_NOTICE_FAILED:
      return {
        ...state,
        error: action.error,
        isGetting: false,
      };
    //!Create tenderNotice
    case TypeActions.CREATE_TENDER_NOTICE_REQUEST:
      return {
        ...state,
        isCreating: true,
      };
    case TypeActions.CREATE_TENDER_NOTICE_SUCCESS:
      return {
        ...state,
        isCreating: false,
      };
    case TypeActions.CREATE_TENDER_NOTICE_FAILED:
      return {
        ...state,
        error: action.error,
        isCreating: false,
      };
    //!Edit tenderNotice
    case TypeActions.EDIT_TENDER_NOTICE_REQUEST:
      return {
        ...state,
        isEditing: true,
      };
    case TypeActions.EDIT_TENDER_NOTICE_SUCCESS:
      return {
        ...state,
        isEditing: false,
      };
    case TypeActions.EDIT_TENDER_NOTICE_FAILED:
      return {
        ...state,
        error: action.error,
        isEditing: false,
      };
    //!Delete tenderNotice
    case TypeActions.DELETE_TENDER_NOTICE_REQUEST:
      return {
        ...state,
        isDeleting: true,
      };
    case TypeActions.DELETE_TENDER_NOTICE_SUCCESS:
      return {
        ...state,
        isDeleting: false,
      };
    case TypeActions.DELETE_TENDER_NOTICE_FAILED:
      return {
        ...state,
        error: action.error,
        isDeleting: false,
      };
    // update tender note
    case TypeActions.UPDATE_TENDER_NOTE_REQUEST:
      return {
        ...state,
        isUpdateTenderNote: true,
      };
    case TypeActions.UPDATE_TENDER_NOTE_SUCCESS:
      return {
        ...state,
        isUpdateTenderNote: false,
      };
    case TypeActions.UPDATE_TENDER_NOTE_FAILED:
      return {
        ...state,
        error: action.error,
        isUpdateTenderNote: false,
      };
    //!Default
    default:
      return {
        ...state,
      };
  }
};

export default tenderNoticeReducer;

import TypeActions from "../TypeActions";

export const getListTenderNotice = (params, callback) => {
  return {
    type: TypeActions.GET_LIST_TENDER_NOTICE_REQUEST,
    params,
    callback,
  };
};
export const getTenderNotice = (params) => {
  return {
    type: TypeActions.GET_TENDER_NOTICE_REQUEST,
    params,
  };
};

export const createTenderNotice = (body, callback) => {
  return {
    type: TypeActions.CREATE_TENDER_NOTICE_REQUEST,
    body,
    callback,
  };
};
export const editTenderNotice = (body, params, callback) => {
  return {
    type: TypeActions.EDIT_TENDER_NOTICE_REQUEST,
    body,
    params,
    callback,
  };
};
export const deleteTenderNotice = (params, callback) => {
  return {
    type: TypeActions.DELETE_TENDER_NOTICE_REQUEST,
    params,
    callback,
  };
};
export const updateTenderNote = (params, body, callback) => {
  return {
    type: TypeActions.UPDATE_TENDER_NOTE_REQUEST,
    params,
    body,
    callback,
  };
};

export default {
  getListTenderNotice,
  createTenderNotice,
  editTenderNotice,
  deleteTenderNotice,
  getTenderNotice,
  updateTenderNote,
};

import TypeActions from "../TypeActions";

export const getListDocument = (params) => {
  return {
    type: TypeActions.GET_LIST_DOCUMENT_REQUEST,
    params,
    // callback
  };
};
export const getDocument = (params) => {
  return {
    type: TypeActions.GET_DOCUMENT_REQUEST,
    params,
  };
};
export const getDocumentForProfile = (params) => {
  return {
    type: TypeActions.GET_DOCUMENT_FOR_PROFILE_REQUEST,
    params,
  };
};

export const createDocument = (body, callback) => {
  return {
    type: TypeActions.CREATE_DOCUMENT_REQUEST,
    body,
    callback,
  };
};
export const editDocument = (body, params, callback) => {
  return {
    type: TypeActions.EDIT_DOCUMENT_REQUEST,
    body,
    params,
    callback,
  };
};
export const deleteDocument = (params, callback) => {
  return {
    type: TypeActions.DELETE_DOCUMENT_REQUEST,
    params,
    callback,
  };
};

export const createChildDocument = (body, callback) => {
  return {
    type: TypeActions.CREATE_CHILD_DOCUMENT_REQUEST,
    body,
    callback,
  };
};
export const editChildDocument = (body, params, callback) => {
  return {
    type: TypeActions.EDIT_CHILD_DOCUMENT_REQUEST,
    body,
    params,
    callback,
  };
};

export default {
  getListDocument,
  createDocument,
  editDocument,
  deleteDocument,
  getDocument,
  createChildDocument,
  editChildDocument,
  getDocumentForProfile,
};

import _ from "lodash";
import { call, put, takeLatest } from "redux-saga/effects";
import { DELETE, GET, PATCH, POST } from "Service/ServiceBase";
import ServiceURL from "Service/ServiceURL";
import TypeActions from "../TypeActions";

export function* getListTenderNotice(data) {
  const url = ServiceURL.TenderNotice + "?" + data.params;
  const callback = data.callback;
  try {
    const res = yield call(GET, url);
    if (res.message && !_.isEmpty(res.message)) {
      yield put({
        type: TypeActions.GET_LIST_TENDER_NOTICE_FAILED,
        error: res.message,
      });
    } else {
      yield put({
        type: TypeActions.GET_LIST_TENDER_NOTICE_SUCCESS,
        data: res.data,
      });
      callback && callback.success();
    }
  } catch (error) {
    yield put({
      type: TypeActions.GET_LIST_TENDER_NOTICE_FAILED,
      error: error,
    });
  }
}

export function* getTenderNotice(data) {
  const url = ServiceURL.TenderNotice + "/" + data.params;
  try {
    const res = yield call(GET, url);
    if (res.message && !_.isEmpty(res.message)) {
      yield put({
        type: TypeActions.GET_TENDER_NOTICE_FAILED,
        error: res.message,
      });
    } else {
      yield put({
        type: TypeActions.GET_TENDER_NOTICE_SUCCESS,
        data: res.data,
      });
    }
  } catch (error) {
    yield put({
      type: TypeActions.GET_TENDER_NOTICE_FAILED,
      error: error,
    });
  }
}

export function* createTenderNotice(data) {
  const url = ServiceURL.TenderNotice;
  const callback = data.callback;
  try {
    const res = yield call(POST, url, data.body);
    if (res.message && !_.isEmpty(res.message)) {
      yield put({
        type: TypeActions.CREATE_TENDER_NOTICE_FAILED,
        error: res.message,
      });
      callback && callback.failed(res.message);
    } else {
      yield put({
        type: TypeActions.CREATE_TENDER_NOTICE_SUCCESS,
        // data: res.data.results
      });
      callback && callback.success();
    }
  } catch (error) {
    yield put({
      type: TypeActions.CREATE_TENDER_NOTICE_FAILED,
      error: error,
    });
    callback && callback.failed(error?.response?.data?.message);
  }
}

export function* editTenderNotice(data) {
  const url = ServiceURL.TenderNotice + "/" + data.params;
  const callback = data.callback;
  try {
    const res = yield call(PATCH, url, data.body);
    if (res.message && !_.isEmpty(res.message)) {
      yield put({
        type: TypeActions.EDIT_TENDER_NOTICE_FAILED,
        error: res.message,
      });
      callback && callback.failed(res.message);
    } else {
      yield put({
        type: TypeActions.EDIT_TENDER_NOTICE_SUCCESS,
        // data: res.data.results
      });
      callback && callback.success();
    }
  } catch (error) {
    yield put({
      type: TypeActions.EDIT_TENDER_NOTICE_FAILED,
      error: error,
    });
    callback && callback.failed(error?.response?.data?.message);
  }
}
export function* deleteTenderNotice(data) {
  const url = ServiceURL.TenderNotice + "/" + data.params;
  const callback = data.callback;
  try {
    const res = yield call(DELETE, url);
    if (res.message && !_.isEmpty(res.message)) {
      yield put({
        type: TypeActions.DELETE_TENDER_NOTICE_FAILED,
        error: res.message,
      });
      callback && callback.failed(res.message);
    } else {
      yield put({
        type: TypeActions.DELETE_TENDER_NOTICE_SUCCESS,
        // data: res.data.results
      });
      callback && callback.success();
    }
  } catch (error) {
    yield put({
      type: TypeActions.DELETE_TENDER_NOTICE_FAILED,
      error: error,
    });
    callback && callback.failed(error);
  }
}
export function* updateTenderNote(data) {
  const url = ServiceURL.TenderNotice + "/" + data.params;
  const callback = data.callback;
  try {
    const res = yield call(PATCH, url, data.body);
    if (res.message && !_.isEmpty(res.message)) {
      yield put({
        type: TypeActions.UPDATE_TENDER_NOTE_FAILED,
        error: res.message,
      });
      callback && callback.failed(res.message);
    } else {
      yield put({
        type: TypeActions.UPDATE_TENDER_NOTE_SUCCESS,
      });
      callback && callback.success();
    }
  } catch (error) {
    yield put({
      type: TypeActions.UPDATE_TENDER_NOTE_FAILED,
      error: error,
    });
    callback && callback.failed(error?.data?.response?.message);
  }
}

export default function* profileTenderSaga() {
  yield takeLatest(
    TypeActions.GET_LIST_TENDER_NOTICE_REQUEST,
    getListTenderNotice
  );
  yield takeLatest(TypeActions.GET_TENDER_NOTICE_REQUEST, getTenderNotice);
  yield takeLatest(
    TypeActions.CREATE_TENDER_NOTICE_REQUEST,
    createTenderNotice
  );
  yield takeLatest(TypeActions.EDIT_TENDER_NOTICE_REQUEST, editTenderNotice);
  yield takeLatest(
    TypeActions.DELETE_TENDER_NOTICE_REQUEST,
    deleteTenderNotice
  );
  yield takeLatest(TypeActions.UPDATE_TENDER_NOTE_REQUEST, updateTenderNote);
}

import React from "react";
import { Search } from "react-bootstrap-table2-toolkit";
import { useParams } from "react-router";
import { Container, Row } from "reactstrap";
import AddDocumentForm from "./AddDocumentForm";
import Header from "./Header";

const { SearchBar } = Search;

function AddDocument(props) {
  //!Const
  const { documentId } = useParams();
  //!State
  //!Function

  //!Render
  return (
    <>
      <Header
        name={documentId === "add" ? "Thêm mới tài liệu" : "Cập nhật tài liệu"}
        parentName="Quản lý tài liệu"
      />
      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <AddDocumentForm />
          </div>
        </Row>
        {/* <Row>
          <div className="col">
            <Card className="border-0"></Card>
          </div>
        </Row> */}
      </Container>
    </>
  );
}

export default AddDocument;

export default {
  //!Users
  LOGIN_REQUEST: "LOGIN_REQUEST",
  LOGIN_REQUEST_SUCCESS: "LOGIN_REQUEST_SUCCESS",
  LOGIN_REQUEST_FAILED: "LOGIN_REQUEST_FAILED",
  LOGOUT_REQUEST: "LOGOUT_REQUEST",
  LOGOUT_REQUEST_SUCCESS: "LOGOUT_REQUEST_SUCCESS",
  LOGOUT_REQUEST_FAILED: "LOGOUT_REQUEST_FAILED",
  GET_USERS_REQUEST: "GET_USERS_REQUEST",
  GET_USERS_SUCCESS: "GET_USERS_SUCCESS",
  GET_USERS_FAILED: "GET_USERS_FAILED",
  GET_USERS_INFOR_REQUEST: "GET_USERS_INFOR_REQUEST",
  GET_USERS_INFOR_SUCCESS: "GET_USERS_INFOR_SUCCESS",
  GET_USERS_INFOR_FAILED: "GET_USERS_INFOR_FAILED",
  CHANGE_PASSWORD_USERS_REQUEST: "CHANGE_PASSWORD_USERS_REQUEST",
  CHANGE_PASSWORD_USERS_SUCCESS: "CHANGE_PASSWORD_USERS_SUCCESS",
  CHANGE_PASSWORD_USERS_FAILED: "CHANGE_PASSWORD_USERS_FAILED",
  FORGOT_PASSWORD_USERS_REQUEST: "FORGOT_PASSWORD_USERS_REQUEST",
  FORGOT_PASSWORD_USERS_SUCCESS: "FORGOT_PASSWORD_USERS_SUCCESS",
  FORGOT_PASSWORD_USERS_FAILED: "FORGOT_PASSWORD_USERS_FAILED",
  RESET_PASSWORD_USERS_REQUEST: "RESET_PASSWORD_USERS_REQUEST",
  RESET_PASSWORD_USERS_SUCCESS: "RESET_PASSWORD_USERS_SUCCESS",
  RESET_PASSWORD_USERS_FAILED: "RESET_PASSWORD_USERS_FAILED",
  GET_LIST_USERS_REQUEST: "GET_LIST_USERS_REQUEST",
  GET_LIST_USERS_SUCCESS: "GET_LIST_USERS_SUCCESS",
  GET_LIST_USERS_FAILED: "GET_LIST_USERS_FAILED",
  CREATE_USERS_REQUEST: "CREATE_USERS_REQUEST",
  CREATE_USERS_SUCCESS: "CREATE_USERS_SUCCESS",
  CREATE_USERS_FAILED: "CREATE_USERS_FAILED",
  EDIT_USERS_REQUEST: "EDIT_USERS_REQUEST",
  EDIT_USERS_SUCCESS: "EDIT_USERS_SUCCESS",
  EDIT_USERS_FAILED: "EDIT_USERS_FAILED",
  DELETE_USERS_REQUEST: "DELETE_USERS_REQUEST",
  DELETE_USERS_SUCCESS: "DELETE_USERS_SUCCESS",
  DELETE_USERS_FAILED: "DELETE_USERS_FAILED",
  UPLOAD_IMAGE_REQUEST: "UPLOAD_IMAGE_REQUEST",
  UPLOAD_IMAGE_SUCCESS: "UPLOAD_IMAGE_SUCCESS",
  UPLOAD_IMAGE_FAILED: "UPLOAD_IMAGE_FAILED",
  SET_PASS_USER_REQUEST: "SET_PASS_USER_REQUEST",
  SET_PASS_USER_SUCCESS: "SET_PASS_USER_SUCCESS",
  SET_PASS_USER_FAILED: "SET_PASS_USER_FAILED",

  //!Role
  GET_LIST_ROLE_REQUEST: "GET_LIST_ROLE_REQUEST",
  GET_LIST_ROLE_SUCCESS: "GET_LIST_ROLE_SUCCESS",
  GET_LIST_ROLE_FAILED: "GET_LIST_ROLE_FAILED",
  GET_ROLE_REQUEST: "GET_ROLE_REQUEST",
  GET_ROLE_SUCCESS: "GET_ROLE_SUCCESS",
  GET_ROLE_FAILED: "GET_ROLE_FAILED",
  CREATE_ROLE_REQUEST: "CREATE_ROLE_REQUEST",
  CREATE_ROLE_SUCCESS: "CREATE_ROLE_SUCCESS",
  CREATE_ROLE_FAILED: "CREATE_ROLE_FAILED",
  EDIT_ROLE_REQUEST: "EDIT_ROLE_REQUEST",
  EDIT_ROLE_SUCCESS: "EDIT_ROLE_SUCCESS",
  EDIT_ROLE_FAILED: "EDIT_ROLE_FAILED",
  DELETE_ROLE_REQUEST: "DELETE_ROLE_REQUEST",
  DELETE_ROLE_SUCCESS: "DELETE_ROLE_SUCCESS",
  DELETE_ROLE_FAILED: "DELETE_ROLE_FAILED",
  GET_ROLE_PERMISSION_REQUEST: "GET_ROLE_PERMISSION_REQUEST",
  GET_ROLE_PERMISSION_SUCCESS: "GET_ROLE_PERMISSION_SUCCESS",
  GET_ROLE_PERMISSION_FAILED: "GET_ROLE_PERMISSION_FAILED",

  //!Key
  GET_LIST_KEY_REQUEST: "GET_LIST_KEY_REQUEST",
  GET_LIST_KEY_SUCCESS: "GET_LIST_KEY_SUCCESS",
  GET_LIST_KEY_FAILED: "GET_LIST_KEY_FAILED",
  GET_KEY_REQUEST: "GET_KEY_REQUEST",
  GET_KEY_SUCCESS: "GET_KEY_SUCCESS",
  GET_KEY_FAILED: "GET_KEY_FAILED",
  CREATE_KEY_REQUEST: "CREATE_KEY_REQUEST",
  CREATE_KEY_SUCCESS: "CREATE_KEY_SUCCESS",
  CREATE_KEY_FAILED: "CREATE_KEY_FAILED",
  EDIT_KEY_REQUEST: "EDIT_KEY_REQUEST",
  EDIT_KEY_SUCCESS: "EDIT_KEY_SUCCESS",
  EDIT_KEY_FAILED: "EDIT_KEY_FAILED",
  DELETE_KEY_REQUEST: "DELETE_KEY_REQUEST",
  DELETE_KEY_SUCCESS: "DELETE_KEY_SUCCESS",
  DELETE_KEY_FAILED: "DELETE_KEY_FAILED",

  //!Document Type
  GET_LIST_DOCUMENT_TYPE_REQUEST: "GET_LIST_DOCUMENT_TYPE_REQUEST",
  GET_LIST_DOCUMENT_TYPE_SUCCESS: "GET_LIST_DOCUMENT_TYPE_SUCCESS",
  GET_LIST_DOCUMENT_TYPE_FAILED: "GET_LIST_DOCUMENT_TYPE_FAILED",
  GET_DOCUMENT_TYPE_REQUEST: "GET_DOCUMENT_TYPE_REQUEST",
  GET_DOCUMENT_TYPE_SUCCESS: "GET_DOCUMENT_TYPE_SUCCESS",
  GET_DOCUMENT_TYPE_FAILED: "GET_DOCUMENT_TYPE_FAILED",
  CREATE_DOCUMENT_TYPE_REQUEST: "CREATE_DOCUMENT_TYPE_REQUEST",
  CREATE_DOCUMENT_TYPE_SUCCESS: "CREATE_DOCUMENT_TYPE_SUCCESS",
  CREATE_DOCUMENT_TYPE_FAILED: "CREATE_DOCUMENT_TYPE_FAILED",
  EDIT_DOCUMENT_TYPE_REQUEST: "EDIT_DOCUMENT_TYPE_REQUEST",
  EDIT_DOCUMENT_TYPE_SUCCESS: "EDIT_DOCUMENT_TYPE_SUCCESS",
  EDIT_DOCUMENT_TYPE_FAILED: "EDIT_DOCUMENT_TYPE_FAILED",
  DELETE_DOCUMENT_TYPE_REQUEST: "DELETE_DOCUMENT_TYPE_REQUEST",
  DELETE_DOCUMENT_TYPE_SUCCESS: "DELETE_DOCUMENT_TYPE_SUCCESS",
  DELETE_DOCUMENT_TYPE_FAILED: "DELETE_DOCUMENT_TYPE_FAILED",

  //!Document
  GET_LIST_DOCUMENT_REQUEST: "GET_LIST_DOCUMENT_REQUEST",
  GET_LIST_DOCUMENT_SUCCESS: "GET_LIST_DOCUMENT_SUCCESS",
  GET_LIST_DOCUMENT_FAILED: "GET_LIST_DOCUMENT_FAILED",
  GET_DOCUMENT_REQUEST: "GET_DOCUMENT_REQUEST",
  GET_DOCUMENT_SUCCESS: "GET_DOCUMENT_SUCCESS",
  GET_DOCUMENT_FAILED: "GET_DOCUMENT_FAILED",
  CREATE_DOCUMENT_REQUEST: "CREATE_DOCUMENT_REQUEST",
  CREATE_DOCUMENT_SUCCESS: "CREATE_DOCUMENT_SUCCESS",
  CREATE_DOCUMENT_FAILED: "CREATE_DOCUMENT_FAILED",
  EDIT_DOCUMENT_REQUEST: "EDIT_DOCUMENT_REQUEST",
  EDIT_DOCUMENT_SUCCESS: "EDIT_DOCUMENT_SUCCESS",
  EDIT_DOCUMENT_FAILED: "EDIT_DOCUMENT_FAILED",
  DELETE_DOCUMENT_REQUEST: "DELETE_DOCUMENT_REQUEST",
  DELETE_DOCUMENT_SUCCESS: "DELETE_DOCUMENT_SUCCESS",
  DELETE_DOCUMENT_FAILED: "DELETE_DOCUMENT_FAILED",

  CREATE_CHILD_DOCUMENT_REQUEST: "CREATE_CHILD_DOCUMENT_REQUEST",
  CREATE_CHILD_DOCUMENT_SUCCESS: "CREATE_CHILD_DOCUMENT_SUCCESS",
  CREATE_CHILD_DOCUMENT_FAILED: "CREATE_CHILD_DOCUMENT_FAILED",
  EDIT_CHILD_DOCUMENT_REQUEST: "EDIT_CHILD_DOCUMENT_REQUEST",
  EDIT_CHILD_DOCUMENT_SUCCESS: "EDIT_CHILD_DOCUMENT_SUCCESS",
  EDIT_CHILD_DOCUMENT_FAILED: "EDIT_CHILD_DOCUMENT_FAILED",

  GET_DOCUMENT_FOR_PROFILE_REQUEST: "GET_DOCUMENT_FOR_PROFILE_REQUEST",
  GET_DOCUMENT_FOR_PROFILE_SUCCESS: "GET_DOCUMENT_FOR_PROFILE_SUCCESS",
  GET_DOCUMENT_FOR_PROFILE_FAILED: "GET_DOCUMENT_FOR_PROFILE_FAILED",

  //!Tender Notice
  GET_LIST_TENDER_NOTICE_REQUEST: "GET_LIST_TENDER_NOTICE_REQUEST",
  GET_LIST_TENDER_NOTICE_SUCCESS: "GET_LIST_TENDER_NOTICE_SUCCESS",
  GET_LIST_TENDER_NOTICE_FAILED: "GET_LIST_TENDER_NOTICE_FAILED",
  GET_TENDER_NOTICE_REQUEST: "GET_TENDER_NOTICE_REQUEST",
  GET_TENDER_NOTICE_SUCCESS: "GET_TENDER_NOTICE_SUCCESS",
  GET_TENDER_NOTICE_FAILED: "GET_TENDER_NOTICE_FAILED",
  CREATE_TENDER_NOTICE_REQUEST: "CREATE_TENDER_NOTICE_REQUEST",
  CREATE_TENDER_NOTICE_SUCCESS: "CREATE_TENDER_NOTICE_SUCCESS",
  CREATE_TENDER_NOTICE_FAILED: "CREATE_TENDER_NOTICE_FAILED",
  EDIT_TENDER_NOTICE_REQUEST: "EDIT_TENDER_NOTICE_REQUEST",
  EDIT_TENDER_NOTICE_SUCCESS: "EDIT_TENDER_NOTICE_SUCCESS",
  EDIT_TENDER_NOTICE_FAILED: "EDIT_TENDER_NOTICE_FAILED",
  DELETE_TENDER_NOTICE_REQUEST: "DELETE_TENDER_NOTICE_REQUEST",
  DELETE_TENDER_NOTICE_SUCCESS: "DELETE_TENDER_NOTICE_SUCCESS",
  DELETE_TENDER_NOTICE_FAILED: "DELETE_TENDER_NOTICE_FAILED",
  UPDATE_TENDER_NOTE_REQUEST: "UPDATE_TENDER_NOTE_REQUEST",
  UPDATE_TENDER_NOTE_SUCCESS: "UPDATE_TENDER_NOTE_SUCCESS",
  UPDATE_TENDER_NOTE_FAILED: "UPDATE_TENDER_NOTE_FAILED",

  //!Tender Profile
  GET_LIST_TENDER_PROFILE_REQUEST: "GET_LIST_TENDER_PROFILE_REQUEST",
  GET_LIST_TENDER_PROFILE_SUCCESS: "GET_LIST_TENDER_PROFILE_SUCCESS",
  GET_LIST_TENDER_PROFILE_FAILED: "GET_LIST_TENDER_PROFILE_FAILED",
  GET_TENDER_PROFILE_REQUEST: "GET_TENDER_PROFILE_REQUEST",
  GET_TENDER_PROFILE_SUCCESS: "GET_TENDER_PROFILE_SUCCESS",
  GET_TENDER_PROFILE_FAILED: "GET_TENDER_PROFILE_FAILED",
  CREATE_TENDER_PROFILE_REQUEST: "CREATE_TENDER_PROFILE_REQUEST",
  CREATE_TENDER_PROFILE_SUCCESS: "CREATE_TENDER_PROFILE_SUCCESS",
  CREATE_TENDER_PROFILE_FAILED: "CREATE_TENDER_PROFILE_FAILED",
  EDIT_TENDER_PROFILE_REQUEST: "EDIT_TENDER_PROFILE_REQUEST",
  EDIT_TENDER_PROFILE_SUCCESS: "EDIT_TENDER_PROFILE_SUCCESS",
  EDIT_TENDER_PROFILE_FAILED: "EDIT_TENDER_PROFILE_FAILED",
  EDIT_TASK_TENDER_PROFILE_REQUEST: "EDIT_TASK_TENDER_PROFILE_REQUEST",
  EDIT_TASK_TENDER_PROFILE_SUCCESS: "EDIT_TASK_TENDER_PROFILE_SUCCESS",
  EDIT_TASK_TENDER_PROFILE_FAILED: "EDIT_TASK_TENDER_PROFILE_FAILED",
  DELETE_TENDER_PROFILE_REQUEST: "DELETE_TENDER_PROFILE_REQUEST",
  DELETE_TENDER_PROFILE_SUCCESS: "DELETE_TENDER_PROFILE_SUCCESS",
  DELETE_TENDER_PROFILE_FAILED: "DELETE_TENDER_PROFILE_FAILED",

  //!Dashboard
  GET_INFO_DASHBOARD_REQUEST: "GET_INFO_DASHBOARD_REQUEST",
  GET_INFO_DASHBOARD_SUCCESS: "GET_INFO_DASHBOARD_SUCCESS",
  GET_INFO_DASHBOARD_FAILED: "GET_INFO_DASHBOARD_FAILED",
};

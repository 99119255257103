import _ from "lodash";
import { call, put, takeLatest } from "redux-saga/effects";
import { DELETE, GET, PATCH, POST } from "Service/ServiceBase";
import ServiceURL from "Service/ServiceURL";
import TypeActions from "../TypeActions";

export function* getListDocument(data) {
  const url = ServiceURL.Document + "?" + data.params;
  // const callback = data.callback;
  try {
    const res = yield call(GET, url);
    if (res.message && !_.isEmpty(res.message)) {
      yield put({
        type: TypeActions.GET_LIST_DOCUMENT_FAILED,
        error: res.message,
      });
    } else {
      yield put({
        type: TypeActions.GET_LIST_DOCUMENT_SUCCESS,
        data: res.data,
      });
      // callback && callback.success(res.data);
    }
  } catch (error) {
    yield put({
      type: TypeActions.GET_LIST_DOCUMENT_FAILED,
      error: error,
    });
  }
}

export function* getDocument(data) {
  const url = ServiceURL.Document + "/" + data.params;
  try {
    const res = yield call(GET, url);
    if (res.message && !_.isEmpty(res.message)) {
      yield put({
        type: TypeActions.GET_DOCUMENT_FAILED,
        error: res.message,
      });
    } else {
      yield put({
        type: TypeActions.GET_DOCUMENT_SUCCESS,
        data: res.data,
      });
    }
  } catch (error) {
    yield put({
      type: TypeActions.GET_DOCUMENT_FAILED,
      error: error,
    });
  }
}

export function* getDocumentForProfile(data) {
  const url = ServiceURL.Document + "/template?" + data.params;
  try {
    const res = yield call(GET, url);
    if (res.message && !_.isEmpty(res.message)) {
      yield put({
        type: TypeActions.GET_DOCUMENT_FOR_PROFILE_FAILED,
        error: res.message,
      });
    } else {
      yield put({
        type: TypeActions.GET_DOCUMENT_FOR_PROFILE_SUCCESS,
        data: res.data,
      });
    }
  } catch (error) {
    yield put({
      type: TypeActions.GET_DOCUMENT_FOR_PROFILE_FAILED,
      error: error,
    });
  }
}

export function* createDocument(data) {
  const url = ServiceURL.Document;
  const callback = data.callback;
  try {
    const res = yield call(POST, url, data.body);
    if (res.message && !_.isEmpty(res.message)) {
      yield put({
        type: TypeActions.CREATE_DOCUMENT_FAILED,
        error: res.message,
      });
      callback && callback.failed(res.message);
    } else {
      yield put({
        type: TypeActions.CREATE_DOCUMENT_SUCCESS,
        // data: res.data.results
      });
      callback && callback.success();
    }
  } catch (error) {
    yield put({
      type: TypeActions.CREATE_DOCUMENT_FAILED,
      error: error,
    });
    callback && callback.failed(error?.response?.data?.message);
  }
}

export function* editDocument(data) {
  const url = ServiceURL.Document + "/" + data.params;
  const callback = data.callback;
  try {
    const res = yield call(PATCH, url, data.body);
    if (res.message && !_.isEmpty(res.message)) {
      yield put({
        type: TypeActions.EDIT_DOCUMENT_FAILED,
        error: res.message,
      });
      callback && callback.failed(res.message);
    } else {
      yield put({
        type: TypeActions.EDIT_DOCUMENT_SUCCESS,
        // data: res.data.results
      });
      callback && callback.success();
    }
  } catch (error) {
    yield put({
      type: TypeActions.EDIT_DOCUMENT_FAILED,
      error: error,
    });
    callback && callback.failed(error?.response?.data?.message);
  }
}

export function* createChildDocument(data) {
  const url = ServiceURL.Document + "/childrent";
  const callback = data.callback;
  try {
    const res = yield call(POST, url, data.body);
    if (res.message && !_.isEmpty(res.message)) {
      yield put({
        type: TypeActions.CREATE_CHILD_DOCUMENT_FAILED,
        error: res.message,
      });
      callback && callback.failed(res.message);
    } else {
      yield put({
        type: TypeActions.CREATE_CHILD_DOCUMENT_SUCCESS,
        // data: res.data.results
      });
      callback && callback.success(res.data);
    }
  } catch (error) {
    yield put({
      type: TypeActions.CREATE_CHILD_DOCUMENT_FAILED,
      error: error,
    });
    callback && callback.failed(error?.response?.data?.message);
  }
}

export function* editChildDocument(data) {
  const url = ServiceURL.Document + "/childrent/" + data.params;
  const callback = data.callback;
  try {
    const res = yield call(PATCH, url, data.body);
    if (res.message && !_.isEmpty(res.message)) {
      yield put({
        type: TypeActions.EDIT_CHILD_DOCUMENT_FAILED,
        error: res.message,
      });
      callback && callback.failed(res.message);
    } else {
      yield put({
        type: TypeActions.EDIT_CHILD_DOCUMENT_SUCCESS,
        // data: res.data.results
      });
      callback && callback.success(res.data);
    }
  } catch (error) {
    yield put({
      type: TypeActions.EDIT_CHILD_DOCUMENT_FAILED,
      error: error,
    });
    callback && callback.failed(error?.response?.data?.message);
  }
}

export function* deleteDocument(data) {
  const url = ServiceURL.Document + "/" + data.params;
  const callback = data.callback;
  try {
    const res = yield call(DELETE, url);
    if (res.message && !_.isEmpty(res.message)) {
      yield put({
        type: TypeActions.DELETE_DOCUMENT_FAILED,
        error: res.message,
      });
      callback && callback.failed(res.message);
    } else {
      yield put({
        type: TypeActions.DELETE_DOCUMENT_SUCCESS,
        // data: res.data.results
      });
      callback && callback.success();
    }
  } catch (error) {
    yield put({
      type: TypeActions.DELETE_DOCUMENT_FAILED,
      error: error,
    });
    callback && callback.failed(error);
  }
}

export default function* documentSaga() {
  yield takeLatest(TypeActions.GET_LIST_DOCUMENT_REQUEST, getListDocument);
  yield takeLatest(TypeActions.GET_DOCUMENT_REQUEST, getDocument);
  yield takeLatest(
    TypeActions.GET_DOCUMENT_FOR_PROFILE_REQUEST,
    getDocumentForProfile
  );
  yield takeLatest(TypeActions.CREATE_DOCUMENT_REQUEST, createDocument);
  yield takeLatest(TypeActions.EDIT_DOCUMENT_REQUEST, editDocument);
  yield takeLatest(
    TypeActions.CREATE_CHILD_DOCUMENT_REQUEST,
    createChildDocument
  );
  yield takeLatest(TypeActions.EDIT_CHILD_DOCUMENT_REQUEST, editChildDocument);
  yield takeLatest(TypeActions.DELETE_DOCUMENT_REQUEST, deleteDocument);
}

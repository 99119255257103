import _ from 'lodash';
import { call, put, takeLatest } from 'redux-saga/effects';
import { DELETE, GET, PATCH, POST } from 'Service/ServiceBase';
import ServiceURL from 'Service/ServiceURL';
import TypeActions from '../TypeActions';

export function* getInfoDashboard() {
  const url = ServiceURL.Dashboard;
  try {
    const res = yield call(GET, url);
    if (res.message && !_.isEmpty(res.message)) {
      yield put({
        type: TypeActions.GET_USERS_INFOR_FAILED,
        error: res.message
      });
    } else {
      yield put({
        type: TypeActions.GET_INFO_DASHBOARD_SUCCESS,
        data: res.data
      });
    }
  } catch (error) {
    yield put({
      type: TypeActions.GET_USERS_INFOR_FAILED,
      error: error
    });
  }
}

export default function* dashboardSaga() {
  yield takeLatest(TypeActions.GET_INFO_DASHBOARD_REQUEST, getInfoDashboard);
}

// core components
// import Button from 'components/CustomButtons/Button.js';
import _ from "lodash";
// used for making the prop types of this component
import PropTypes from "prop-types";
import React from "react";
//Icon
// import CloseIcon from '@material-ui/icons/Close';
// import DoneIcon from '@material-ui/icons/Done';
// import { Button, colors } from "@material-ui/core";
import { Button } from "reactstrap";
import defaultAvatar from "../../assets/img/brand/placeholder.jpg";
// import { makeStyles } from "@material-ui/styles";

// const useStyles = makeStyles((theme) => ({
//   botButton: {
//     display: "flex",
//     padding: theme.spacing(1),
//     justifyContent: "flex-end",
//   },
//   buttonSuccess: {
//     margin: theme.spacing(1),
//     color: "white",
//     backgroundColor: colors.green[600],
//     "&:hover": {
//       backgroundColor: colors.green[900],
//       // Reset on touch devices, it doesn't add specificity
//       "@media (hover: none)": {
//         backgroundColor: colors.green[600],
//       },
//     },
//   },
//   buttonWarning: {
//     margin: theme.spacing(1),
//     color: "white",
//     backgroundColor: colors.grey[600],
//     "&:hover": {
//       backgroundColor: colors.grey[900],
//       // Reset on touch devices, it doesn't add specificity
//       "@media (hover: none)": {
//         backgroundColor: colors.grey[600],
//       },
//     },
//   },
//   //   results: {
//   //     marginTop: theme.spacing(3)
//   //   }
// }));
export default function ImageUpload(props) {
  // const classes = useStyles();
  const [file, setFile] = React.useState(null);
  const [imagePreviewUrl, setImagePreviewUrl] = React.useState(defaultAvatar);
  React.useEffect(() => {
    if (props.avatar !== null && !_.isEmpty(props.avatar))
      setImagePreviewUrl(props.avatar);
  }, [props.avatar]);
  let fileInput = React.createRef();
  const handleImageChange = (e) => {
    e.preventDefault();

    let reader = new FileReader();
    let file = e.target.files[0];
    reader.onloadend = () => {
      setFile(file);
      setImagePreviewUrl(reader.result);
      if (props.onChange) {
        props.onChange(file);
      }
    };
    reader.readAsDataURL(file);
  };

  React.useEffect(() => {
    let reader = new FileReader();
    reader.onloadend = () => {
      setFile(props.defaultAvatarCustom);
      setImagePreviewUrl(reader.result);
      //   if (props.onChange) {
      //     props.onChange(props.defaultAvatarCustom);
      //   }
    };
    // reader.readAsDataURL(props.defaultAvatarCustom);
  });
  // eslint-disable-next-line
  const handleSubmit = (e) => {
    // e.preventDefault();
    // file is the file/image uploaded
    // in this function you can save the image (file) on form submit
    // you have to call it yourself
    props.files(file);
  };
  const handleClick = () => {
    fileInput.current.click();
  };
  const handleRemove = () => {
    setFile(null);
    setImagePreviewUrl(props.avatar ? props.avatar : defaultAvatar);
    fileInput.current.value = null;
  };
  let {
    avatar,
    addButtonProps,
    changeButtonProps,
    removeButtonProps,
    defaultAvatarCustom,
  } = props;
  return (
    <div className="fileinput text-center">
      <input
        style={{ display: "none" }}
        type="file"
        accept=".jpg, .png, .jpeg, .gif, .bmp, .tif, .tiff|image/*"
        onChange={handleImageChange}
        ref={fileInput}
      />
      <div
        className={"thumbnail" + (avatar ? " img-square" : "")}
        style={{ display: "flex", justifyContent: "center" }}
      >
        <img
          src={imagePreviewUrl}
          alt="..."
          style={{ maxWidth: "300px", maxHeight: "300px" }}
        />
      </div>
      <div
        style={{ display: "flex", justifyContent: "center", paddingTop: "5px" }}
      >
        {file === null ? (
          <Button
            {...addButtonProps}
            onClick={() => handleClick()}
            className="bg-info text-white "
          >
            {/* {avatar ? "Add Photo" : "Select image"} */}
            Chọn ảnh
          </Button>
        ) : (
          <span>
            <Button
              {...changeButtonProps}
              className="bg-info text-white"
              onClick={() => {
                handleSubmit(file);
                setFile(null);
              }}
            >
              <i className="fas fa-check" /> Lưu
            </Button>
            {/* {avatar ? <br /> : null} */}
            <Button
              {...removeButtonProps}
              // className=""
              onClick={() => handleRemove()}
            >
              <i className="fas fa-times" /> Huỷ
            </Button>
          </span>
        )}
      </div>
    </div>
  );
}

ImageUpload.propTypes = {
  avatar: PropTypes.string,
  addButtonProps: PropTypes.object,
  changeButtonProps: PropTypes.object,
  removeButtonProps: PropTypes.object,
  // it is a function from which you can get the files and fileNames that were uploaded
  // more can be read here: https://github.com/creativetimofficial/ct-material-kit-pro-react/issues/64
  onChange: PropTypes.func,
};

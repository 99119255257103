// export const BASE_URL = 'https://api.quanlyduan.fovina.bfd.vn/dauthau/v1';
export const BASE_URL = process.env.REACT_APP_BASE_URL;
export const BASE_URL_IMAGE = 'https://door.bfd.vn/api';

export const Login = 'auth/login/';
export const Logout = 'auth/logout';
export const User = 'users';
export const ChangePassword = 'users/change-password';
export const ForgotPassword = 'auth/forgot-password';
export const ResetPassword = 'auth/reset-password';

export const Role = 'roles';
export const RolePermission = 'permissions';

export const setPassUser = 'users/set-password';

export const Key = 'keySearchs';

export const DocumentType = 'documentTypes';

export const Document = 'documents';

export const TenderNotice = 'tBMTInfos';

export const TenderProfile = 'profiles';

export const Dashboard = 'dashboards';

export default {
  BASE_URL,

  //User
  Login,
  User,
  Logout,
  ChangePassword,
  ForgotPassword,
  ResetPassword,

  //Role
  Role,
  RolePermission,

  // Set pass for user
  setPassUser,

  //Key
  Key,

  //DocumentType
  DocumentType,

  //Document
  Document,

  //TenderNotice
  TenderNotice,

  //TenderProfile
  TenderProfile,

  // Dashboard
  Dashboard,
};

import { colors, Divider, makeStyles, Tab, Tabs } from '@material-ui/core';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, useHistory, useParams } from 'react-router';
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardImg,
  Col,
  Container,
  Row,
} from 'reactstrap';
import { userActions } from 'Redux/Actions';
import ChangePass from './ChangePass';
import InforUser from './InforUser';
import queryString from 'query-string';
import Popup from 'components/Popup';
import UpdateUserInfo from './UpdateUserInfo';

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  tabs: {
    marginTop: theme.spacing(3),
  },
  divider: {
    backgroundColor: colors.grey[300],
  },
  content: {
    marginTop: theme.spacing(3),
  },
}));

function UserProfile({ match }) {
  //!Const
  // const { id, tab } = useParams();
  const classes = useStyles();
  const { id, tab: currentTab } = match.params;
  console.log('cur: ', currentTab);
  const dispatch = useDispatch();
  const history = useHistory();
  const userInfor = useSelector((state) => state.userReducer.userInfor);
  const tabs = [
    { value: 'infor', label: 'Thông tin chung' },
    { value: 'change-password', label: 'Đổi mật khẩu' },
  ];
  //!State
  const [userInfo, setuserInfo] = useState({});
  const [openPopup, setopenPopup] = useState(false);
  const [isDone, setisDone] = useState(false);
  //!Useeffect
  React.useEffect(() => {
    dispatch(
      userActions.getUsersInfor(
        id + '?' + queryString.stringify({ populate: 'roleId' })
      )
    );
  }, [id]);
  React.useEffect(() => {
    if (isDone) {
      dispatch(
        userActions.getUsersInfor(
          id + '?' + queryString.stringify({ populate: 'roleId' })
        )
      );
      setisDone(false);
    }
  }, [isDone]);
  React.useEffect(() => {
    if (userInfor !== undefined) {
      setuserInfo(userInfor);
    }
  }, [userInfor]);

  //!Function
  const handleTabsChange = (event, value) => {
    history.push(value);
  };

  if (!currentTab) {
    return <Redirect to={`/user-profile/${id}/infor`} />;
  }

  if (!tabs.find((tab) => tab.value === currentTab)) {
    return <Redirect to="/" />;
  }
  //!Render
  return (
    <>
      <Card className="card-profile">
        {/* <CardImg
          alt="..."
          src={require("assets/img/theme/img-1-1000x600.jpg").default}
          top
          className="h-75"
        /> */}
        <div
          className="header pb-6 d-flex align-items-center"
          style={{
            minHeight: '500px',
            backgroundImage:
              'url("' +
              require('assets/img/theme/img-1-1000x600.jpg').default +
              '")',
            backgroundSize: 'cover',
            backgroundPosition: 'center top',
          }}
        >
          <span className="mask bg-gradient-info opacity-6" />

          <Container className="d-flex align-items-center" fluid>
            <Row>
              <Col lg="12" md="12">
                <h1 className="display-2 text-white">
                  Xin chào, {userInfo.name}!
                </h1>
                {/* <p className="text-white mt-0 mb-5">
                  This is your profile page. You can see the progress you've
                  made with your work and manage your projects or assigned tasks
                </p> */}
                <Button
                  // disabled
                  className="btn-neutral"
                  color="default"
                  // href="#pablo"
                  disabled={!userInfor.isPasswordChange}
                  onClick={() => {
                    setopenPopup(true);
                  }}
                >
                  Cập nhật thông tin cá nhân
                </Button>
              </Col>
            </Row>
          </Container>
        </div>
        <CardHeader>
          <Row className="justify-content-center">
            <Col className="order-lg-2" lg="3">
              <div className="card-profile-image">
                {/* <a href="#pablo" onClick={(e) => e.preventDefault()}> */}
                <img
                  style={{ minWidth: '200px', minHeight: '200px' }}
                  alt="..."
                  className="rounded-circle"
                  src={require('assets/img/theme/team-4.jpg').default}
                />
                {/* </a> */}
              </div>
            </Col>
          </Row>
        </CardHeader>
        {/* <CardHeader className="text-center border-0 pt-8 pt-md-4 pb-0 pb-md-4">
          <div className="d-flex justify-content-between">
            <Button
              className="mr-4"
              color="info"
              href="#pablo"
              onClick={(e) => e.preventDefault()}
              size="sm"
            >
              Connect
            </Button>
            <Button
              className="float-right"
              color="default"
              href="#pablo"
              onClick={(e) => e.preventDefault()}
              size="sm"
            >
              Message
            </Button>
          </div>
        </CardHeader> */}
        <CardBody className="mt-5">
          {/* <Row>
            <div className="col">
              <div className="card-profile-stats d-flex justify-content-center">
                <div>
                  <span className="heading">22</span>
                  <span className="description">Friends</span>
                </div>
                <div>
                  <span className="heading">10</span>
                  <span className="description">Photos</span>
                </div>
                <div>
                  <span className="heading">89</span>
                  <span className="description">Comments</span>
                </div>
              </div>
            </div>
          </Row> */}
          {/* <div className="text-center mt-5">
            <h5 className="h1">
              {userInfo.name}
            </h5>
            <div className="h4 font-weight-300">
              <i className="ni location_pin mr-2" />
              {userInfo.email}
            </div> */}
          {/* <div className="h5 mt-4">
              <i className="ni business_briefcase-24 mr-2" />
              Solution Manager - Creative Tim Officer
            </div> */}
          {/* <div>
              <i className="ni education_hat mr-2" />
              {userInfo.address}
            </div>
          </div> */}
          <Row className="justify-content-center">
            <Col
              // className="d-flex justify-content-center"
              lg="10"
              md="10"
              xs="12"
            >
              <Tabs
                className={classes.tabs}
                onChange={handleTabsChange}
                TabIndicatorProps={{
                  style: { backgroundColor: 'rgba(17, 205, 239, 1)' },
                }}
                scrollButtons="auto"
                value={currentTab}
                variant="scrollable"
              >
                {tabs.map((tab) => (
                  <Tab key={tab.value} label={tab.label} value={tab.value} />
                ))}
              </Tabs>
              <Divider className={classes.divider} />
              <div className={classes.content}>
                {currentTab === 'infor' && <InforUser dataUser={userInfor} />}
                {currentTab === 'change-password' && (
                  <ChangePass dataUser={userInfor} />
                )}
              </div>
            </Col>
          </Row>
        </CardBody>
      </Card>
      <Popup
        openPopup={openPopup}
        title="Cập nhật thông tin cá nhân"
        fullWidth
        maxWidth="md"
      >
        <UpdateUserInfo
          setopenPopup={setopenPopup}
          dataUser={userInfor}
          setisDone={setisDone}
        />
      </Popup>
    </>
  );
}

export default UserProfile;

import React, { useEffect, useRef, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import {
  Button,
  Card,
  CardHeader,
  Col,
  Container,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Modal,
  Row,
  CardBody,
  CardFooter,
  Form,
} from "reactstrap";
import {
  colors,
  FormControl,
  MenuItem,
  TextField,
  Select,
  InputLabel,
  Divider,
  makeStyles,
  Chip,
} from "@material-ui/core";
import { dataTable } from "variables/general";
import classnames from "classnames";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import Select2 from "react-select2-wrapper";
import userActions from "Redux/Actions/userActions";
import queryString from "query-string";
import { useHistory, useParams } from "react-router";
import { store } from "react-notifications-component";
import "react-notifications-component/dist/theme.css";
import ImageUpload from "components/Upload/ImageUpload";
import { Formik } from "formik";
import { Autocomplete } from "@material-ui/lab";
import * as yup from "yup";
import roleActions from "Redux/Actions/roleActions";
import keyActions from "Redux/Actions/keyActions";

const { SearchBar } = Search;

const useStyles = makeStyles((theme) => ({
  chips: {
    // padding: theme.spacing(2),
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap",
  },
  chip: {
    margin: theme.spacing(1),
  },
}));
function AddKeyForm(props) {
  //!Const
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const isCreating = useSelector((state) => state.keyReducer.isCreating);
  const isEditting = useSelector((state) => state.keyReducer.isEditting);
  const keyById = useSelector((state) => state.keyReducer.keyById);
  const userSchema = yup.object().shape({
    code: yup.string().required("Vui lòng nhập mã từ khoá!"),
    // values: yup.array().min(1, "Vui lòng nhập từ khoá!"),
  });
  //!State
  const [detailParams, setdetailParams] = useState({
    code: "",
  });
  const [inputValue, setInputValue] = useState("");
  const [chips, setChips] = useState([]);
  const { keyId } = useParams();
  //!Function
  const handleUser = (value) => {
    const detailUp = { code: value.code, values: chips };
    if (!!keyId && keyId !== null && keyId === "add") {
      dispatch(
        keyActions.createKey(detailUp, {
          success: () => {
            store.addNotification({
              title: "Thông báo!",
              message: "Thêm từ khoá thành công!",
              type: "success", // 'default', 'success', 'info', 'warning'
              container: "bottom-right", // where to position the notifications
              animationIn: ["animated", "fadeIn"], // animate.css classes that's applied
              animationOut: ["animated", "fadeOut"], // animate.css classes that's applied
              dismiss: {
                pauseOnHover: true,
                onScreen: true,
                duration: 3000,
              },
            });
            history.goBack();
          },
          failed: (mess) => {
            store.addNotification({
              title: "Thông báo!",
              message: "Thêm mới từ khoá thất bại! Lỗi: " + mess + "! Vui lòng thử lại!",
              type: "danger", // 'default', 'success', 'info', 'warning'
              container: "bottom-right", // where to position the notifications
              animationIn: ["animated", "fadeIn"], // animate.css classes that's applied
              animationOut: ["animated", "fadeOut"], // animate.css classes that's applied
              dismiss: {
                onScreen: true,
                pauseOnHover: true,
                duration: 5000,
              },
            });
          },
        })
      );
    } else if (!!keyId && keyId !== null && keyId !== "add") {
      dispatch(
        keyActions.editKey(detailUp, keyId, {
          success: () => {
            store.addNotification({
              title: "Thông báo!",
              message: "Cập nhật từ khoá thành công!",
              type: "success", // 'default', 'success', 'info', 'warning'
              container: "bottom-right", // where to position the notifications
              animationIn: ["animated", "fadeIn"], // animate.css classes that's applied
              animationOut: ["animated", "fadeOut"], // animate.css classes that's applied
              dismiss: {
                pauseOnHover: true,
                onScreen: true,
                duration: 3000,
              },
            });
            history.goBack();
          },
          failed: (mess) => {
            store.addNotification({
              title: "Thông báo!",
              message: "Cập nhật từ khoá thất bại! Lỗi: " + mess + "! Vui lòng thử lại!",
              type: "danger", // 'default', 'success', 'info', 'warning'
              container: "bottom-right", // where to position the notifications
              animationIn: ["animated", "fadeIn"], // animate.css classes that's applied
              animationOut: ["animated", "fadeOut"], // animate.css classes that's applied
              dismiss: {
                onScreen: true,
                pauseOnHover: true,
                duration: 5000,
              },
            });
          },
        })
      );
    }
  };

  const handleInputChange = (event) => {
    event.persist();
    setInputValue(event.target.value);
  };

  const handleInputKeyup = (event) => {
    event.persist();

    if (event.keyCode === 13 && inputValue) {
      if (!chips.includes(inputValue)) {
        setChips((prevChips) => [...prevChips, inputValue]);
        setInputValue("");
      }
    }
  };

  const handleChipDelete = (chip) => {
    setChips((prevChips) => prevChips.filter((prevChip) => chip !== prevChip));
  };
  //!Use effect
  useEffect(() => {
    if (keyId && keyId !== "add") {
      dispatch(keyActions.getKey(keyId));
    }
  }, [keyId]);
  useEffect(() => {
    if (keyId !== "add" && keyById !== undefined) {
      setdetailParams({
        code: keyById.code,
      });
      setChips(
        keyById.values !== undefined && keyById.values !== null
          ? keyById.values
          : []
      );
    } else {
      setdetailParams({
        code: "",
      });
      setChips([]);
    }
  }, [keyById]);
  //!Render
  return (
    <>
      <Card>
        <CardHeader>
          <Row className="align-items-center ">
            <Col lg="6" md="7" xs="12" className="p-2">
              <h3 className="mb-0">
                {keyId === "add" ? "Thêm mới từ khoá" : "Chỉnh sửa từ khoá"}
              </h3>
            </Col>
            <Col className="mt-3 mt-md-0 text-md-right" lg="6" md="5" xs="12">
              {/* <Button>
                <i className="ni ni-bold-down"></i>
              </Button> */}
            </Col>
          </Row>
        </CardHeader>
        <Formik
          initialValues={detailParams}
          enableReinitialize
          onSubmit={handleUser}
          validationSchema={userSchema}
        >
          {({ values, setFieldValue, handleSubmit, errors, touched }) => {
            return (
              <>
                <CardBody>
                  <Row className="align-items-flex-start">
                    <Col
                      className="align-items-flex-start"
                      lg="12"
                      md="12"
                      xs="12"
                    >
                      <Row className="align-items-flex-start">
                        <Col lg="6" md="6" xs="12" className="p-2">
                          <TextField
                            label="Mã từ khoá *"
                            name="code"
                            value={values.code}
                            variant="outlined"
                            fullWidth
                            type="text"
                            onChange={(e) => {
                              setFieldValue("code", e.target.value);
                            }}
                          />
                          {errors.code && touched.code && (
                            <a
                              style={{
                                fontSize: 15,
                                
                                color: "red",
                                paddingLeft: "3px",
                              }}
                            >
                              {errors.code}
                            </a>
                          )}
                        </Col>
                        <Col lg="6" md="6" xs="12" className="p-2">
                          <Card>
                            <CardHeader className="p-0">
                              <TextField
                                label="Từ khoá (Nhập và nhấn Enter) *"
                                name="values"
                                value={values.inputValue}
                                variant="outlined"
                                fullWidth
                                type="text"
                                onChange={handleInputChange}
                                onKeyUp={handleInputKeyup}
                                value={inputValue}
                              />
                              {errors.values && touched.values && (
                                <a
                                  style={{
                                    fontSize: 15,
                                    
                                    color: "red",
                                    paddingLeft: "3px",
                                  }}
                                >
                                  {errors.values}
                                </a>
                              )}
                            </CardHeader>
                            <CardBody>
                              <div className={classes.chips}>
                                {chips.length === 0 ? (
                                  <i
                                    style={{
                                      
                                      color: "red",
                                    }}
                                  >
                                    Vui lòng chọn ít nhất 1 từ khoá!
                                  </i>
                                ) : (
                                  chips !== undefined &&
                                  chips !== null &&
                                  chips.map((chip) => (
                                    <Chip
                                      className={classes.chip}
                                      key={chip}
                                      label={chip}
                                      onDelete={() => handleChipDelete(chip)}
                                    />
                                  ))
                                )}
                              </div>
                            </CardBody>
                          </Card>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </CardBody>
                <CardFooter>
                  <Row className="align-items-center text-right">
                    <Col lg="12" md="12" xs="12">
                      {isEditting || isCreating ? (
                        <div class="spinner-border text-info" />
                      ) : (
                        <span>
                          <Button
                            disabled={chips.length === 0}
                            className="bg-info text-white"
                            onClick={handleSubmit}
                          >
                            <i className="fas fa-check" />{" "}
                            {keyId === "add" ? "Thêm mới" : "Cập nhật"}
                          </Button>
                          {/* {avatar ? <br /> : null} */}
                          <Button
                            // className=""
                            onClick={() => {
                              history.goBack();
                            }}
                          >
                            <i className="fas fa-times" /> Huỷ bỏ
                          </Button>
                        </span>
                      )}
                    </Col>
                  </Row>
                </CardFooter>
              </>
            );
          }}
        </Formik>
      </Card>
    </>
  );
}

export default AddKeyForm;

import { Formik } from "formik";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { store } from "react-notifications-component";
import "react-notifications-component/dist/theme.css";
import * as yup from "yup";
import { userActions } from "Redux/Actions";
import { Button, Col, Row } from "reactstrap";
import ImageUpload from "components/Upload/ImageUpload";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@material-ui/core";

function UpdateUserInfo({ setopenPopup, dataUser, setisDone }) {
  //!Const
  const isEditting = useSelector((state) => state.userReducer.isEditting);
  const dispatch = useDispatch();
  const userDetailSchema = yup.object().shape({
    name: yup.string().required("Vui lòng nhập tên người dùng!"),
    // email: yup
    //   .string()
    //   .email('Email không đúng định dạng!')
    //   .required('Vui lòng nhập Email!'),
    gender: yup.string().required("Vui lòng chọn giới tính!"),
    // dateOfBirth: yup.string().required('Vui lòng chọn ngày sinh!'),
    address: yup.string().required("Vui lòng nhập địa chỉ!"),
    // roleId: yup.string().required('Vui lòng chọn phân quyền!')
  });
  //!State
  const [detailParams, setdetailParams] = useState({
    name: "",
    email: "",
    roleId: "",
    address: "",
    gender: "",
  });
  const [gender, setgender] = React.useState(["Nam", "Nữ"]);
  //!Function
  const handleUser = (value) => {
    const dataUserEdit = {
      name: value.name,
      // email: value.email,
      // roleId: value.roleId.id,
      address: value.address,
      gender: value.gender,
      // dateOfBirth: new Date().toISOString()
    };
    dispatch(
      userActions.editUsers(dataUserEdit, dataUser.id, {
        success: () => {
          store.addNotification({
            title: "Thông báo!",
            message: "Cập nhật tài khoản thành công!",
            type: "success", // 'default', 'success', 'info', 'warning'
            container: "bottom-right", // where to position the notifications
            animationIn: ["animated", "fadeIn"], // animate.css classes that's applied
            animationOut: ["animated", "fadeOut"], // animate.css classes that's applied
            dismiss: {
              pauseOnHover: true,
              onScreen: true,
              duration: 3000,
            },
          });
          setopenPopup(false);
          setisDone(true);
        },
        failed: (mess) => {
          store.addNotification({
            title: "Thông báo!",
            message: "Cập nhật tài khoản thất bại! Lỗi: " + mess + "! Vui lòng thử lại!",
            type: "danger", // 'default', 'success', 'info', 'warning'
            container: "bottom-right", // where to position the notifications
            animationIn: ["animated", "fadeIn"], // animate.css classes that's applied
            animationOut: ["animated", "fadeOut"], // animate.css classes that's applied
            dismiss: {
              onScreen: true,
              pauseOnHover: true,
              duration: 5000,
            },
          });
        },
      })
    );
  };
  //!Useeffect
  useEffect(() => {
    setdetailParams({
      ...detailParams,
      name: dataUser.name,
      email: dataUser.email,
      roleId: dataUser.roleId,
      address: dataUser.address,
      gender: dataUser.gender,
      // dateOfBirth: dataUser.dateOfBirth
    });
  }, [dataUser]);
  //!Render
  return (
    <Formik
      initialValues={detailParams}
      enableReinitialize
      onSubmit={handleUser}
      validationSchema={userDetailSchema}
    >
      {({ values, setFieldValue, handleSubmit, errors, touched }) => {
        return (
          <>
            <Row>
              <Col className="p-2" lg="4" md="12" xs="12">
                <ImageUpload />
              </Col>
              <Col className="p-2" lg="8" md="12" xs="12">
                <Row>
                  <Col className="pt-2 pl-2 pb-2" lg="12" md="12" xs="12">
                    <TextField
                      label="Họ tên *"
                      name="userName"
                      variant="outlined"
                      fullWidth
                      type="text"
                      value={values.name}
                      onChange={(e) => {
                        setFieldValue("name", e.target.value);
                      }}
                    />
                    {errors.name && touched.name && (
                      <a
                        style={{
                          fontSize: 13,
                          color: "red",
                          paddingLeft: "3px",
                        }}
                      >
                        {errors.name}
                      </a>
                    )}
                  </Col>
                  <Col className="pt-2 pl-2 pb-2" lg="12" md="12" xs="12">
                    <FormControl fullWidth>
                      <InputLabel
                        //   className={classes.selectLabel}
                        style={{
                          top: "-8px",
                          left: "2px",
                          // color: colors.grey[3] + " !important",
                          fontWeight: "500",
                          fontSize: "16px",
                          lineHeight: "1.42857",
                          letterSpacing: "unset",
                          padding: "0 0 0 15px",
                        }}
                        htmlFor="simple-select"
                      >
                        Giới tính *
                      </InputLabel>
                      <Select
                        className="basic-single"
                        classNamePrefix="select"
                        value={values.gender}
                        onChange={(value) => {
                          setFieldValue("gender", value.target.value);
                        }}
                        variant="outlined"
                        label="Giới tính"
                      >
                        {gender.map((gen, key) => (
                          <MenuItem value={gen} key={key}>
                            {gen}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    {errors.gender && touched.gender && (
                      <a
                        style={{
                          fontSize: 13,
                          color: "red",
                          paddingLeft: "3px",
                        }}
                      >
                        {errors.gender}
                      </a>
                    )}
                  </Col>
                  <Col className="pt-2 pl-2 pb-2" lg="12" md="12" xs="12">
                    <TextField
                      label="Email *"
                      disabled
                      name="email"
                      variant="outlined"
                      fullWidth
                      type="text"
                      value={values.email}
                      onChange={(e) => {
                        setFieldValue("email", e.target.value);
                      }}
                    />
                    {errors.email && touched.email && (
                      <a
                        style={{
                          fontSize: 13,
                          color: "red",
                          paddingLeft: "3px",
                        }}
                      >
                        {errors.email}
                      </a>
                    )}
                  </Col>
                  <Col className="pt-2 pl-2 pb-2" lg="12" md="12" xs="12">
                    <TextField
                      label="Nhóm quyền *"
                      disabled
                      name="roleId"
                      variant="outlined"
                      fullWidth
                      type="text"
                      value={values.roleId && values.roleId.name}
                      // onChange={e => {
                      //   setFieldValue('email', e.target.value);
                      // }}
                    />
                  </Col>
                  <Col className="pt-2 pl-2 pb-2" lg="12" md="12" xs="12">
                    <TextField
                      label="Địa chỉ"
                      name="address"
                      variant="outlined"
                      fullWidth
                      type="text"
                      value={values.address}
                      onChange={(e) => {
                        setFieldValue("address", e.target.value);
                      }}
                    />
                    {errors.address && touched.address && (
                      <a
                        style={{
                          fontSize: 13,
                          color: "red",
                          paddingLeft: "3px",
                        }}
                      >
                        {errors.address}
                      </a>
                    )}
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row>
              <Col className="p-2 text-right" lg="12" md="12" xs="12">
                {isEditting ? (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      marginTop: "10px",
                    }}
                  >
                    <div class="spinner-border text-info" />
                  </div>
                ) : (
                  <>
                    <Button
                      onClick={() => {
                        setopenPopup(false);
                      }}
                      size="large"
                    >
                      Huỷ bỏ
                    </Button>
                    <Button
                      size="large"
                      onClick={handleSubmit}
                      className="bg-info text-white"
                    >
                      Cập nhật
                    </Button>
                  </>
                )}
              </Col>
            </Row>
          </>
        );
      }}
    </Formik>
  );
}

export default UpdateUserInfo;

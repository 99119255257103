/*!

=========================================================
* Argon Dashboard PRO React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import Alternative from 'views/pages/dashboards/Alternative.js';
import Buttons from 'views/pages/components/Buttons.js';
import Calendar from 'views/pages/Calendar.js';
import Cards from 'views/pages/components/Cards.js';
import Charts from 'views/pages/Charts.js';
import Components from 'views/pages/forms/Components.js';
// import Dashboard from "views/pages/dashboards/Dashboard.js";
import Elements from 'views/pages/forms/Elements.js';
import Google from 'views/pages/maps/Google.js';
import Grid from 'views/pages/components/Grid.js';
import Icons from 'views/pages/components/Icons.js';
import Lock from 'views/pages/examples/Lock.js';
// import Login from "views/pages/examples/Login.js";
import Notifications from 'views/pages/components/Notifications.js';
import Pricing from 'views/pages/examples/Pricing.js';
import Profile from 'views/pages/examples/Profile.js';
import ReactBSTables from 'views/pages/tables/ReactBSTables.js';
import Register from 'views/pages/examples/Register.js';
import RTLSupport from 'views/pages/examples/RTLSupport.js';
import Sortable from 'views/pages/tables/Sortable.js';
import Tables from 'views/pages/tables/Tables.js';
import Timeline from 'views/pages/examples/Timeline.js';
import Typography from 'views/pages/components/Typography.js';
import Validation from 'views/pages/forms/Validation.js';
import Vector from 'views/pages/maps/Vector.js';
import Widgets from 'views/pages/Widgets.js';
import Dashboard from 'containers/Dashboard';
import TenderNotice from 'containers/TenderNotice';
import TenderingResult from 'containers/TenderingResult';
import UserManage from 'containers/UserManage';
import TableContent from 'containers/TableContent';
import Login from 'containers/Login';
import KeyManage from 'containers/KeyManage';
import AddUser from 'containers/UserManage/AddUser';
import AddKey from 'containers/KeyManage/AddKey';
import DocumentTypeManage from 'containers/DocumentTypeManage';
import AddDocumentType from 'containers/DocumentTypeManage/AddDocumentType';
import DocumentManage from 'containers/DocumentManage';
import AddDocument from 'containers/DocumentManage/AddDocument';
import TenderProfile from 'containers/TenderProfile';
import AddTenderProfile from 'containers/TenderProfile/AddTenderProfile';
import EditTenderProfileUser from 'containers/TenderProfile/EditTenderProfileUser';
import UserProfile from 'containers/UserProfile';
import ForgotPass from 'containers/ForgotPass';
import ResetPass from 'containers/ResetPass';

const routes = [
  // {
  //   collapse: true,
  //   name: "Tổng quan",
  //   icon: "ni ni-shop text-danger",
  //   state: "dashboardsCollapse",
  //   views: [
  //     {
  //       path: "dashboard",
  //       name: "Dashboard",
  //       miniName: "D",
  //       component: Dashboard,
  //       layout: "/",
  //     },
  //     {
  //       path: "alternative-dashboard",
  //       name: "Alternative",
  //       miniName: "A",
  //       component: Alternative,
  //       layout: "/",
  //     },
  //   ],
  // },
  //!Fovina
  {
    path: 'dashboard',
    name: 'Tổng quan',
    icon: 'ni ni-shop text-info',
    component: Dashboard,
    role: '',
    layout: '/',
  },
  {
    collapse: true,
    name: 'Quản lý TBMT',
    icon: 'ni ni-notification-70 text-primary',
    state: 'tenderNoticeCollapse',
    role: '',
    views: [
      {
        path: 'tender-notice-manage',
        name: 'Thông báo mời thầu',
        miniName: 'TB',
        component: TenderNotice,
        layout: '/',
        role: 'get_tBMTInfo',
      },
      {
        path: 'tender-profile',
        name: 'Hồ sơ dự thầu',
        miniName: 'TP',
        component: TenderProfile,
        layout: '/',
        role: 'get_profile',
      },
      {
        path: 'modify-tender-profile/:tBMTInfoId/:tenderProfileId',
        name: 'Hồ sơ dự thầu',
        miniName: 'TP',
        component: AddTenderProfile,
        layout: '/',
        hide: true,
      },
      {
        path: 'tender-profile-detail/:tenderProfileId',
        name: 'Hồ sơ dự thầu',
        miniName: 'TP',
        component: EditTenderProfileUser,
        layout: '/',
        hide: true,
      },
      {
        path: 'tendering-result-manage',
        name: 'Kết quả dự thầu',
        miniName: 'KQ',
        component: TenderingResult,
        layout: '/',
        role: '',
      },
    ],
  },
  {
    collapse: true,
    name: 'Quản trị cơ sở',
    icon: 'fa fa-cog text-primary',
    state: 'configCollapse',
    role: '',
    views: [
      {
        path: 'config-facility/user-manage',
        name: 'Quản lý tài khoản',
        miniName: 'U',
        component: UserManage,
        layout: '/',
        role: 'get_user',
      },
      {
        path: 'config-facility/modify-user/:userId',
        name: 'Quản lý tài khoản',
        miniName: 'U',
        component: AddUser,
        layout: '/',
        hide: true,
      },
      // {
      //   path: "config-facility/table-content",
      //   name: "Quản lý mục lục",
      //   miniName: "TC",
      //   component: TableContent,
      //   layout: "/",
      // },
      {
        path: 'config-facility/key-manage',
        name: 'Quản lý từ khoá',
        miniName: 'K',
        component: KeyManage,
        layout: '/',
        role: 'get_keySearch',
      },
      {
        path: 'config-facility/modify-key/:keyId',
        name: 'Quản lý từ khoá',
        miniName: 'K',
        component: AddKey,
        layout: '/',
        hide: true,
      },
      {
        path: 'config-facility/document-type',
        name: 'Quản lý thể loại',
        miniName: 'DT',
        component: DocumentTypeManage,
        layout: '/',
        role: 'get_documentType',
      },
      {
        path: 'config-facility/modify-type/:documentTypeId',
        name: 'Quản lý thể loại',
        miniName: 'DT',
        component: AddDocumentType,
        layout: '/',
        hide: true,
      },
      {
        path: 'config-facility/document-manage',
        name: 'Quản lý tài liệu',
        miniName: 'D',
        component: DocumentManage,
        layout: '/',
        role: 'get_document',
      },
      {
        path: 'config-facility/modify-document/:documentId',
        name: 'Quản lý thể loại',
        miniName: 'K',
        component: AddDocument,
        layout: '/',
        hide: true,
      },
    ],
  },
  {
    collapse: true,
    redirect: true,
    name: 'Auth',
    icon: 'ni ni-ungroup text-orange',
    state: 'examplesAuth',
    role: '',
    views: [
      {
        path: '/login',
        name: 'Login',
        miniName: 'L',
        component: Login,
        layout: '/auth',
      },
      {
        path: '/forgot-pass',
        name: 'ForgotPass',
        miniName: 'F',
        component: ForgotPass,
        layout: '/auth',
      },
      {
        path: '/reset-password',
        name: 'ResetPass',
        miniName: 'R',
        component: ResetPass,
        layout: '/auth',
      },
      {
        path: 'user-profile/:id/:tab',
        name: 'Profile',
        miniName: 'P',
        component: UserProfile,
        layout: '/',
      },
    ],
  },
  //!End fovina
  // {
  //   collapse: true,
  //   name: "Examples",
  //   icon: "ni ni-ungroup text-orange",
  //   state: "examplesCollapse",
  //   views: [
  //     {
  //       path: "pricing",
  //       name: "Pricing",
  //       miniName: "P",
  //       component: Pricing,
  //       layout: "/auth",
  //     },
  //     // {
  //     //   path: "/login",
  //     //   name: "Login",
  //     //   miniName: "L",
  //     //   component: Login,
  //     //   layout: "/auth",
  //     // },
  //     {
  //       path: "/register",
  //       name: "Register",
  //       miniName: "R",
  //       component: Register,
  //       layout: "/auth",
  //     },
  //     {
  //       path: "/lock",
  //       name: "Lock",
  //       miniName: "L",
  //       component: Lock,
  //       layout: "/auth",
  //     },
  //     {
  //       path: "/timeline",
  //       name: "Timeline",
  //       miniName: "T",
  //       component: Timeline,
  //       layout: "/",
  //     },
  //     {
  //       path: "profile",
  //       name: "Profile",
  //       miniName: "P",
  //       component: Profile,
  //       layout: "/",
  //     },
  //     {
  //       path: "/rtl-support",
  //       name: "RTL Support",
  //       miniName: "RS",
  //       component: RTLSupport,
  //       layout: "/rtl",
  //     },
  //   ],
  // },
  // {
  //   collapse: true,
  //   name: "Components",
  //   icon: "ni ni-ui-04 text-info",
  //   state: "componentsCollapse",
  //   views: [
  //     {
  //       path: "buttons",
  //       name: "Buttons",
  //       miniName: "B",
  //       component: Buttons,
  //       layout: "/",
  //     },
  //     {
  //       path: "cards",
  //       name: "Cards",
  //       miniName: "C",
  //       component: Cards,
  //       layout: "/",
  //     },
  //     {
  //       path: "grid",
  //       name: "Grid",
  //       miniName: "G",
  //       component: Grid,
  //       layout: "/",
  //     },
  //     {
  //       path: "notifications",
  //       name: "Notifications",
  //       miniName: "N",
  //       component: Notifications,
  //       layout: "/",
  //     },
  //     {
  //       path: "icons",
  //       name: "Icons",
  //       miniName: "I",
  //       component: Icons,
  //       layout: "/",
  //     },
  //     {
  //       path: "typography",
  //       name: "Typography",
  //       miniName: "T",
  //       component: Typography,
  //       layout: "/",
  //     },
  //     {
  //       collapse: true,
  //       name: "Multi Level",
  //       miniName: "M",
  //       state: "multiCollapse",
  //       views: [
  //         {
  //           path: "#pablo",
  //           name: "Third level menu",
  //           component: () => {},
  //           layout: "/",
  //         },
  //         {
  //           path: "#pablo",
  //           name: "Just another link",
  //           component: () => {},
  //           layout: "/",
  //         },
  //         {
  //           path: "#pablo",
  //           name: "One last link",
  //           component: () => {},
  //           layout: "/",
  //         },
  //       ],
  //     },
  //   ],
  // },
  // {
  //   collapse: true,
  //   name: "Forms",
  //   icon: "ni ni-single-copy-04 text-pink",
  //   state: "formsCollapse",
  //   views: [
  //     {
  //       path: "elements",
  //       name: "Elements",
  //       miniName: "E",
  //       component: Elements,
  //       layout: "/",
  //     },
  //     {
  //       path: "components",
  //       name: "Components",
  //       miniName: "C",
  //       component: Components,
  //       layout: "/",
  //     },
  //     {
  //       path: "validation",
  //       name: "Validation",
  //       miniName: "V",
  //       component: Validation,
  //       layout: "/",
  //     },
  //   ],
  // },
  // {
  //   collapse: true,
  //   name: "Tables",
  //   icon: "ni ni-align-left-2 text-default",
  //   state: "tablesCollapse",
  //   views: [
  //     {
  //       path: "tables",
  //       name: "Tables",
  //       miniName: "T",
  //       component: Tables,
  //       layout: "/",
  //     },
  //     {
  //       path: "sortable",
  //       name: "Sortable",
  //       miniName: "S",
  //       component: Sortable,
  //       layout: "/",
  //     },
  //     {
  //       path: "react-bs-table",
  //       name: "React BS Tables",
  //       miniName: "RBT",
  //       component: ReactBSTables,
  //       layout: "/",
  //     },
  //   ],
  // },
  // {
  //   collapse: true,
  //   name: "Maps",
  //   icon: "ni ni-map-big text-primary",
  //   state: "mapsCollapse",
  //   views: [
  //     {
  //       path: "google",
  //       name: "Google",
  //       miniName: "G",
  //       component: Google,
  //       layout: "/",
  //     },
  //     {
  //       path: "vector",
  //       name: "Vector",
  //       miniName: "V",
  //       component: Vector,
  //       layout: "/",
  //     },
  //   ],
  // },
  // {
  //   path: "widgets",
  //   name: "Widgets",
  //   icon: "ni ni-archive-2 text-green",
  //   component: Widgets,
  //   layout: "/",
  // },
  // {
  //   path: "charts",
  //   name: "Charts",
  //   icon: "ni ni-chart-pie-35 text-info",
  //   component: Charts,
  //   layout: "/",
  // },
  // {
  //   path: "calendar",
  //   name: "Calendar",
  //   icon: "ni ni-calendar-grid-58 text-red",
  //   component: Calendar,
  //   layout: "/",
  // },
];

export default routes;

import { Divider, TextField } from "@material-ui/core";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { store } from "react-notifications-component";
import "react-notifications-component/dist/theme.css";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { Button, Col, Row } from "reactstrap";
import { tenderProfileActions } from "Redux/Actions";

function OtherTabs({ detail, setisChanged }) {
  //!Const
  const dispatch = useDispatch();
  const userInfor = useSelector((state) => state.userReducer.userInfor);
  const { tenderProfileId } = useParams();
  //!State
  const [detailEdit, setdetailEdit] = useState({
    updateType: "",
    documentTypeId: "",
    _id: "",
    level: "",
    isDone: "",
    note: "",
  });
  const [note, setnote] = useState(detail.note);
  //!Function
  //!Useeffect
  useEffect(() => {
    if (detailEdit.updateType !== "") {
      dispatch(
        tenderProfileActions.editTaskTenderProfile(
          detailEdit,
          tenderProfileId,
          {
            success: () => {
              setisChanged(true);
              store.addNotification({
                title: "Thông báo!",
                message: "Cập nhật thành công!",
                type: "success", // 'default', 'success', 'info', 'warning'
                container: "bottom-right", // where to position the notifications
                animationIn: ["animated", "fadeIn"], // animate.css classes that's applied
                animationOut: ["animated", "fadeOut"], // animate.css classes that's applied
                dismiss: {
                  pauseOnHover: true,
                  onScreen: true,
                  duration: 3000,
                },
              });
            },
            failed: (mess) => {
              store.addNotification({
                title: "Thông báo!",
                message: "Cập nhật thất bại! Lỗi: " + mess + "! Vui lòng thử lại!",
                type: "danger", // 'default', 'success', 'info', 'warning'
                container: "bottom-right", // where to position the notifications
                animationIn: ["animated", "fadeIn"], // animate.css classes that's applied
                animationOut: ["animated", "fadeOut"], // animate.css classes that's applied
                dismiss: {
                  onScreen: true,
                  pauseOnHover: true,
                  duration: 5000,
                },
              });
            },
          }
        )
      );
      setdetailEdit({
        updateType: "",
        documentTypeId: "",
        _id: "",
        level: "",
        isDone: "",
        note: "",
      });
    }
  }, [detailEdit]);
  return (
    <>
      <Row className="align-items-center">
        <Col lg="5" md="5" xs="5" className="pb-2 pt-2 pr-2">
          <b>Tên hồ sơ</b>
        </Col>
        <Col lg="2" md="2" xs="2" className="pb-2 pt-2 pr-2">
          <b>Người thực hiện</b>
        </Col>
        <Col lg="3" md="3" xs="3" className="p-2 d-flex justify-content-center">
          <b>Cập nhật trạng thái thực hiện</b>
        </Col>
        <Col lg="2" md="2" xs="2" className="p-2 d-flex justify-content-center">
          <b>Thời hạn</b>
        </Col>
      </Row>
      <Row className="align-items-center">
        <Col lg="12" md="12" xs="12">
          <Divider />
        </Col>
      </Row>
      <Row className="align-items-center">
        {detail.listDoc.map((index, key) => {
          const renderChild = () => {
            return (
              <>
                <Col lg="5" md="5" xs="5" className="pb-2 pt-2 pr-2">
                  {index.documentId.name}
                </Col>
                <Col lg="2" md="2" xs="2" className="pb-2 pt-2 pr-2">
                  {index.userId.name}
                </Col>
                <Col
                  lg="3"
                  md="3"
                  xs="3"
                  className="p-2 d-flex justify-content-center"
                >
                  <label className="custom-toggle custom-toggle-info mr-1">
                    <input
                      checked={index.isDone}
                      onChange={(e) => {
                        setdetailEdit({
                          updateType: "task",
                          documentTypeId: detail.documentTypeId.id,
                          _id: index._id,
                          level: 0,
                          isDone: !index.isDone,
                        });
                      }}
                      type="checkbox"
                    />
                    <span
                      className="custom-toggle-slider rounded-circle"
                      data-label-off="No"
                      data-label-on="Yes"
                    />
                  </label>
                </Col>
                <Col
                  lg="2"
                  md="2"
                  xs="2"
                  className="p-2 d-flex justify-content-center"
                >
                  {moment(index.dueDate).format("DD/MM/YYYY hh:mm")}
                </Col>
                {index.selectedIds.length > 0
                  ? index.selectedIds.map((index1, key) => {
                      return (
                        <>
                          <Col lg="5" md="5" xs="5" className="pb-2 pt-2 pr-2">
                            <span className="pl-4">
                              {index1.docSelectId.name}
                            </span>
                          </Col>
                          <Col lg="2" md="2" xs="2" className="pb-2 pt-2 pr-2">
                            -
                          </Col>
                          <Col
                            lg="3"
                            md="3"
                            xs="3"
                            className="p-2 d-flex justify-content-center"
                          >
                            <label className="custom-toggle custom-toggle-info mr-1">
                              <input
                                checked={index1.isDone || index.isDone}
                                onChange={(e) => {
                                  setdetailEdit({
                                    updateType: "task",
                                    documentTypeId: detail.documentTypeId.id,
                                    _id: index1._id,
                                    level: 1,
                                    isDone: !index1.isDone,
                                  });
                                }}
                                type="checkbox"
                              />
                              <span
                                className="custom-toggle-slider rounded-circle"
                                data-label-off="No"
                                data-label-on="Yes"
                              />
                            </label>
                          </Col>
                          <Col
                            lg="2"
                            md="2"
                            xs="2"
                            className="p-2 d-flex justify-content-center"
                          >
                            {null}
                          </Col>
                        </>
                      );
                    })
                  : null}
              </>
            );
          };
          if (
            userInfor.id === index.userId.id ||
            (userInfor.roleId && userInfor.roleId.name === "admin")
          ) {
            return renderChild();
          }
        })}
      </Row>
      <Row className="align-items-center">
        <Col lg="12" md="12" xs="12">
          <Divider />
        </Col>
      </Row>
      <Row className="align-items-center">
        <Col lg="12" md="12" xs="12" className="p-2">
          <b>Ghi chú thêm:</b>
        </Col>
        <Col lg="12" md="12" xs="12">
          <TextField
            variant="outlined"
            disabled
            fullWidth
            value={note}
            onChange={(e) => {
              setnote(e.target.value);
            }}
            multiline
            rows={8}
          />
        </Col>
        <Col lg="12" md="12" xs="12" className="text-right pt-2 ">
          {/* <Button
            className="bg-info text-white"
            onClick={() => {
              setdetailEdit({
                updateType: "note",
                documentTypeId: detail.documentTypeId.id,
                _id: detail._id,
                level: 0,
                note: note,
              });
            }}
          >
            Cập nhật ghi chú
          </Button> */}
        </Col>
      </Row>
    </>
  );
}

export default OtherTabs;

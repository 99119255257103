import { Divider } from "@material-ui/core";
import Popup from "components/Popup";
import React, { useEffect, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import { store } from "react-notifications-component";
import "react-notifications-component/dist/theme.css";
import { useParams } from "react-router";
import {
  Button,
  Col,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";
import AddDocumentChildForm from "./AddDocumentChildForm";

function AddDocumentChild({ dataTable, setFieldValue }) {
  //!Const
  const { documentId } = useParams();
  const pagination = paginationFactory({
    page: 1,
    alwaysShowAllBtns: true,
    // showTotal: true,
    withFirstAndLast: false,
    sizePerPageRenderer: ({
      options,
      currSizePerPage,
      onSizePerPageChange,
    }) => (
      <div className="dataTables_length" id="datatable-basic_length">
        <label>
          Hiển thị{" "}
          {
            <select
              //   value={rowsPerPage}
              name="datatable-basic_length"
              aria-controls="datatable-basic"
              className="form-control form-control-xs"
              onChange={(e) => onSizePerPageChange(e.target.value)}
              // onChange={(e) => setrowsPerPage(e.target.value)}
            >
              <option value="5">5</option>
              <option value="10">10</option>
              <option value="30">30</option>
              <option value="100">100</option>
            </select>
          }{" "}
          dòng.
        </label>
      </div>
    ),
  });

  //!State
  const [openModal, setopenModal] = useState(false);
  const [data, setdata] = useState([]);
  const [page, setpage] = useState(0);
  const [rowsPerPage, setrowsPerPage] = useState(5);
  const [idDelete, setidDelete] = useState("");
  //!Function
  //!State
  const [openPopup, setopenPopup] = useState(false);
  const [detail, setdetail] = useState(null);
  //!UseEffect
  useEffect(() => {
    if (dataTable && dataTable !== undefined) {
      setdata(dataTable);
    }
  }, [dataTable]);
  //!Render
  return (
    <>
      <Row className="align-items-flex-start p-2">
        <Modal
          isOpen={openModal}
          toggle={() => {
            setopenModal(!openModal);
          }}
          className="text-center"
        >
          <ModalHeader
            toggle={() => {
              setopenModal(!openModal);
            }}
          >
            <h2 className="text-danger">Thông báo!</h2>
          </ModalHeader>
          <ModalBody>Bạn có chắc chắn muốn xoá tài liệu bổ sung?</ModalBody>
          <ModalFooter>
            <Button
              color="primary"
              onClick={() => {
                setopenModal(false);
                let index = -1;
                data.map((i, k) => {
                  if (i.id === idDelete) {
                    return (index = k);
                  }
                });
                if (index !== -1) {
                  data.splice(index, 1);
                  setFieldValue("childrentIds", data);
                  store.addNotification({
                    title: "Thông báo!",
                    message: "Xoá tài liệu thành công!",
                    type: "success", // 'default', 'success', 'info', 'warning'
                    container: "bottom-right", // where to position the notifications
                    animationIn: ["animated", "fadeIn"], // animate.css classes that's applied
                    animationOut: ["animated", "fadeOut"], // animate.css classes that's applied
                    dismiss: {
                      pauseOnHover: true,
                      onScreen: true,
                      duration: 3000,
                    },
                  });
                }
              }}
            >
              Đồng ý
            </Button>{" "}
            <Button
              color="secondary"
              onClick={() => {
                setopenModal(false);
              }}
            >
              Huỷ bỏ
            </Button>
          </ModalFooter>
        </Modal>
        {/* The Modal */}
        <Col lg="6" md="6" xs="12">
          <h2>Danh sách tài liệu bổ sung</h2>
        </Col>
        <Col
          lg="6"
          md="6"
          xs="12"
          className="align-items-center text-xl-right text-md-right text-sm-left"
        >
          {documentId !== "add" ? (
            <Button
              type="button"
              onClick={() => {
                setdetail(null);
                setopenPopup(true);
              }}
            >
              <i
                className="fas fa-plus"
                style={{
                  marginRight: "10px",
                }}
              />
              Thêm mới tài liệu bổ sung
            </Button>
          ) : null}
        </Col>
        <Col lg="12" md="12" xs="12" className="pt-3">
          <Divider />
        </Col>
      </Row>
      <Row className="align-items-flex-start p-2">
        {documentId === "add" ? (
          <Col lg="12" md="12" xs="12">
            <i className="text-danger">
              Vui lòng hoàn thành tạo mới tài liệu, sau đó có thể cập nhật danh
              sách tài liệu bổ sung!
            </i>
          </Col>
        ) : (
          <Col lg="12" md="12" xs="12">
            <ToolkitProvider
              data={data}
              keyField="code"
              columns={[
                {
                  dataField: "",
                  text: "STT",
                  align: "center",
                  headerAlign: "center",
                  sort: false,
                  formatter: (value, valueRow, key) => {
                    return page * rowsPerPage + key + 1;
                  },
                },
                {
                  dataField: "code",
                  text: "Mã tài liệu",
                  sort: false,
                },
                {
                  dataField: "name",
                  text: "Tên tài liệu",
                  sort: false,
                },
                {
                  dataField: "status",
                  text: "Trạng thái hoạt động",
                  sort: false,
                  align: "center",
                  headerAlign: "center",
                  formatter: (value, valueRow) => {
                    if (value === "active") {
                      return (
                        <span className="font-weight-bold text-success">
                          Hoạt động
                        </span>
                      );
                    } else if (value === "lock") {
                      return (
                        <span className="font-weight-bold text-warning">
                          Đã khoá
                        </span>
                      );
                    } else if (value === "delete") {
                      return (
                        <span className="font-weight-bold text-danger">
                          Đã xoá
                        </span>
                      );
                    }
                  },
                },
                {
                  dataField: "action",
                  text: "Hành động",
                  sort: false,
                  headerAlign: "center",
                  align: "center",
                  formatter: (key, value) => {
                    return (
                      <div className="d-flex justify-content-center">
                        <Button
                          className="btn-icon-only rounded-circle bg-success text-white"
                          // color="default"
                          data-toggle="tooltip"
                          title="Chỉnh sửa tài liệu"
                          type="button"
                          onClick={() => {
                            setdetail(value);
                            setopenPopup(true);
                          }}
                        >
                          <span className="btn-inner--icon">
                            <i className="fas fa-edit" />
                          </span>
                        </Button>
                        <Button
                          className="btn-icon-only rounded-circle bg-danger text-white"
                          // color="default"
                          data-toggle="tooltip"
                          title="Xoá tài liệu"
                          type="button"
                          onClick={() => {
                            setidDelete(value.id);
                            setopenModal(true);
                          }}
                        >
                          <span className="btn-inner--icon">
                            <i className="far fa-trash-alt" />
                          </span>
                        </Button>
                      </div>
                    );
                  },
                },
              ]}
              //   search
            >
              {(props) => (
                <div className="py-4 table-responsive">
                  <BootstrapTable
                    {...props.baseProps}
                    noDataIndication={() => {
                      return (
                        <span className="font-weight-bold text-danger">
                          Không có dữ liệu!
                        </span>
                      );
                    }}
                    onTableChange={() => {
                      return <div class="spinner-border text-info" />;
                    }}
                    hover
                    // remote
                    bootstrap4={true}
                    pagination={pagination}
                    bordered={false}
                  />
                </div>
              )}
            </ToolkitProvider>
            <Popup
              title={
                detail === null
                  ? "Thêm mới tài liệu bổ sung"
                  : "Cập nhật tài liệu bổ sung"
              }
              openPopup={openPopup}
              maxWidth="sm"
            >
              <AddDocumentChildForm
                setopenPopup={setopenPopup}
                detail={detail}
                onAdd={(val) => {
                  data.push(val);
                  setFieldValue("childrentIds", data);
                }}
                onEdit={(val) => {
                  let index = -1;
                  data.map((i, k) => {
                    if (i.id === val.id) {
                      return (index = k);
                    }
                  });
                  data.splice(index, 1, val);
                  setFieldValue("childrentIds", data);
                }}
              />
            </Popup>
          </Col>
        )}
      </Row>
    </>
  );
}

export default AddDocumentChild;

import React from "react";
import "./Back.css";

function Back({ setopenFilter }) {
  return (
    <div
      className="backdrop"
      onClick={() => {
        setopenFilter(false);
      }}
    />
  );
}

export default Back;

import { TextField } from "@material-ui/core";
import { Formik } from "formik";
import React from "react";
import { store } from "react-notifications-component";
import "react-notifications-component/dist/theme.css";
import { useDispatch, useSelector } from "react-redux";
import { Button, Col, Row } from "reactstrap";
import { userActions } from "Redux/Actions";
import * as yup from "yup";

function SetPass({ setopenPopup, id }) {
  //!Const
  const dispatch = useDispatch();
  const isSetPass = useSelector((state) => state.userReducer.isSetPass);
  const pass = {
    newPass: "",
    reNewPass: "",
  };
  const passSchema = yup.object().shape({
    newPass: yup
      .string()
      .required("Vui lòng nhập mật khẩu mới!")
      .matches(
        "^(?=.*[A-Za-z])(?=.*\\d)[A-Za-z\\d\\W]{8,}$",
        "Mật khẩu phải có ít nhất 8 kí tự, trong đó chứa ít nhất 1 kí tự và một số!"
      ),
    reNewPass: yup
      .string()
      .required("Vui lòng nhập lại mật khẩu mới!")
      .oneOf([yup.ref("newPass"), null], "Mật khẩu không khớp!"),
  });
  //!State
  //!Function
  const handleSubmitFrom = (values) => {
    if (values.newPass === values.reNewPass) {
      setopenPopup(false);
      dispatch(
        userActions.setPassForUser(
          { userId: id, password: values.newPass },
          {
            success: () => {
              store.addNotification({
                title: "Thông báo!",
                message: "Cập nhật mật khẩu thành công!",
                type: "success",
                container: "bottom-right",
                animationIn: ["animated", "fadeIn"],
                animationOut: ["animated", "fadeOut"],
                dismiss: {
                  pauseOnHover: true,
                  onScreen: true,
                  duration: 3000,
                },
              });
            },
            failed: (mess) => {
              store.addNotification({
                title: "Thông báo!",
                message: "Cập nhật mật khẩu thất bại! Lỗi: " + mess + "! Vui lòng thử lại!",
                type: "danger",
                container: "bottom-right",
                animationIn: ["animated", "fadeIn"],
                animationOut: ["animated", "fadeOut"],
                dismiss: {
                  onScreen: true,
                  pauseOnHover: true,
                  duration: 5000,
                },
              });
            },
          }
        )
      );
    }
  };

  //!Useeffect
  //!Render
  return (
    <Formik
      initialValues={pass}
      enableReinitialize
      onSubmit={handleSubmitFrom}
      validationSchema={passSchema}
    >
      {({ values, setFieldValue, handleSubmit, errors, touched }) => {
        return (
          <>
            <Row>
              <Col lg="12" md="12" xs="12" className="p-2">
                <TextField
                  label="Nhập mật khẩu mới *"
                  name="newPass"
                  variant="outlined"
                  type="password"
                  value={values.newPass}
                  inputProps={{
                    autoComplete: "new-password",
                  }}
                  fullWidth
                  onChange={(e) => {
                    setFieldValue("newPass", e.target.value);
                  }}
                />
                {!!errors.newPass && !!touched.newPass && (
                  <a
                    style={{
                      fontSize: 15,
                      fontFamily: "roboto",
                      color: "red",
                      paddingLeft: "3px",
                    }}
                  >
                    {errors.newPass}
                  </a>
                )}
              </Col>
              <Col lg="12" md="12" xs="12" className="p-2">
                <TextField
                  label="Nhập lại mật khẩu mới *"
                  name="reNewPass"
                  variant="outlined"
                  fullWidth
                  value={values.reNewPass}
                  inputProps={{
                    autoComplete: "new-password",
                  }}
                  type="password"
                  onChange={(e) => {
                    setFieldValue("reNewPass", e.target.value);
                  }}
                />
                {!!errors.reNewPass && !!touched.reNewPass && (
                  <a
                    style={{
                      fontSize: 15,
                      fontFamily: "roboto",
                      color: "red",
                      paddingLeft: "3px",
                    }}
                  >
                    {errors.reNewPass}
                  </a>
                )}
              </Col>
            </Row>
            <Row>
              <Col lg="12" md="12" xs="12" className="p-2 text-right">
                {isSetPass ? (
                  <div class="spinner-border text-info" />
                ) : (
                  <>
                    <Button
                      onClick={() => {
                        setopenPopup(false);
                      }}
                    >
                      Hủy bỏ
                    </Button>
                    <Button
                      onClick={handleSubmit}
                      className="bg-info text-white"
                    >
                      Lưu lại
                    </Button>
                  </>
                )}
              </Col>
            </Row>
          </>
        );
      }}
    </Formik>
  );
}

export default SetPass;

import { Divider } from "@material-ui/core";
import { toUpper } from "lodash-es";
import moment from "moment";
import React from "react";
import { Card, CardBody, CardHeader, Col, Container, Row } from "reactstrap";

function TabInfo({ detail }) {
  return (
    <>
      <Row className="align-items-center ml-4">
        <Col lg="9" md="12" xs="12" className="p-2">
          <Row className="align-items-flex-start">
            <Col lg="4" md="4" xs="4" className="p-2">
              <span>Số TBMT:</span>
            </Col>
            <Col lg="8" md="8" xs="8" className="p-2">
              <span>
                <b>{detail.tBMTInfoId && detail.tBMTInfoId.tbmtNo}</b>
              </span>
            </Col>
            <Col lg="4" md="4" xs="4" className="p-2">
              <span>Tên gói thầu:</span>
            </Col>
            <Col lg="8" md="8" xs="8" className="p-2">
              <span>
                <b>{detail.tBMTInfoId && detail.tBMTInfoId.title}</b>
              </span>
            </Col>
            <Col lg="4" md="4" xs="4" className="p-2">
              <span>Thời gian nhận E-HSDT từ ngày:</span>
            </Col>
            <Col lg="8" md="8" xs="8" className="p-2">
              <span>
                <b>
                  {moment(detail.tBMTInfoId && detail.tBMTInfoId.tddt).format(
                    "DD/MM/YYYY hh:mm"
                  )}
                </b>{" "}
                - đến ngày:{" "}
                <b>
                  {moment(
                    detail.tBMTInfoId && detail.tBMTInfoId.dueDate
                  ).format("DD/MM/YYYY hh:mm")}
                </b>
              </span>
            </Col>
            <Col lg="4" md="4" xs="4" className="p-2">
              <span>Bên mời thầu:</span>
            </Col>
            <Col lg="8" md="8" xs="8" className="p-2">
              <span>
                <b>{detail.tBMTInfoId && detail.tBMTInfoId.bmt}</b>
              </span>
            </Col>
          </Row>
        </Col>
        <Col lg="3" md="12" xs="12" className="p-4">
          {detail.profileStatus === "Hoàn thành" ? (
            <Container
              style={{
                backgroundColor: "rgba(196, 196, 196, 0.3)",
                borderRadius: "10px",
              }}
              className="text-center align-items-center p-0"
            >
              <h3 className="p-3 text-success">{toUpper("Trạng thái")}</h3>
              <Divider />
              <div className="d-block">
                <div>
                  <i
                    class="far fa-check-circle text-success font-weight-bold p-2"
                    style={{ fontSize: "5rem" }}
                  />
                </div>
                <div className="pb-2">
                  <a className="text-success">Đã hoàn thành</a>
                </div>
              </div>
            </Container>
          ) : (
            <Container
              style={{
                backgroundColor: "rgba(196, 196, 196, 0.3)",
                borderRadius: "10px",
              }}
              className="text-center align-items-center p-0"
            >
              <h3 className="p-3 text-info">{toUpper("Trạng thái")}</h3>
              <Divider />
              <div className="d-block">
                <div>
                  <i
                    class="fas fa-info-circle text-info font-weight-bold p-2"
                    style={{ fontSize: "5rem" }}
                  />
                </div>
                <div className="pb-2">
                  <a className="text-info">Đang thực hiện</a>
                </div>
              </div>
            </Container>
          )}
        </Col>
      </Row>
    </>
  );
}

export default TabInfo;

/*!

=========================================================
* Argon Dashboard PRO React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from 'react';
// nodejs library that concatenates classes
import classnames from 'classnames';
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
} from 'reactstrap';
// core components
import AuthHeader from 'components/Headers/AuthHeader.js';
import { useDispatch, useSelector } from 'react-redux';
import userActions from 'Redux/Actions/userActions';
import { store } from 'react-notifications-component';
import 'react-notifications-component/dist/theme.css';
import { useHistory } from 'react-router';

function ForgotPass() {
  //!Const
  const dispatch = useDispatch();
  const history = useHistory();
  const isLoading = useSelector((state) => state.userReducer.isLoading);
  //!State
  const [focusedEmail, setfocusedEmail] = React.useState(false);
  const [focusedPassword, setfocusedPassword] = React.useState(false);
  const [dataForgot, setDataForgot] = React.useState({
    email: '',
  });
  const [message, setmessage] = React.useState('');
  //!Function
  const handleSubmitLogin = (e) => {
    e.preventDefault();
    dispatch(
      userActions.forgotPassword(dataForgot, {
        success: (data) => {
          setmessage('');
          store.addNotification({
            title: 'Thông báo!',
            message:
              'Cấp lại mật khẩu thành công! Vui lòng kiểm tra email của bạn!',
            type: 'success', // 'default', 'success', 'info', 'warning'
            container: 'bottom-right', // where to position the notifications
            animationIn: ['animated', 'fadeIn'], // animate.css classes that's applied
            animationOut: ['animated', 'fadeOut'], // animate.css classes that's applied
            dismiss: {
              pauseOnHover: true,
              onScreen: true,
              duration: 3000,
            },
          });
          history.push('login');
        },
        failed: (mess) => {
          setmessage(mess);
          store.addNotification({
            title: 'Thông báo!',
            message:
              'Cấp lại mật khẩu thất bại! Lỗi: ' + mess + '! Vui lòng thử lại!',
            type: 'danger', // 'default', 'success', 'info', 'warning'
            container: 'bottom-right', // where to position the notifications
            animationIn: ['animated', 'fadeIn'], // animate.css classes that's applied
            animationOut: ['animated', 'fadeOut'], // animate.css classes that's applied
            dismiss: {
              onScreen: true,
              pauseOnHover: true,
              duration: 5000,
            },
          });
        },
      })
    );
  };
  //!Use Effect

  //!Render
  return (
    <>
      <AuthHeader
        title="HỆ THỐNG QUẢN LÝ HỒ SƠ THẦU"
        lead="Nhập email để cấp lại mật khẩu."
      />
      <Container className="mt--8 pb-5">
        <Row className="justify-content-center">
          <Col lg="5" md="7">
            <Card className="bg-secondary border-0 mb-0">
              <CardHeader className="bg-transparent pb-4">
                <div className="text-muted text-center mt-3 mb-1 text-black">
                  <h2 className="font-weight-bold">QUÊN MẬT KHẨU</h2>
                </div>
                {/* <div className="btn-wrapper text-center">
                  <Button
                    className="btn-neutral btn-icon"
                    color="default"
                    href="#pablo"
                    onClick={(e) => e.preventDefault()}
                  >
                    <span className="btn-inner--icon mr-1">
                      <img
                        alt="..."
                        src={
                          require("assets/img/icons/common/github.svg").default
                        }
                      />
                    </span>
                    <span className="btn-inner--text">Github</span>
                  </Button>
                  <Button
                    className="btn-neutral btn-icon"
                    color="default"
                    href="#pablo"
                    onClick={(e) => e.preventDefault()}
                  >
                    <span className="btn-inner--icon mr-1">
                      <img
                        alt="..."
                        src={
                          require("assets/img/icons/common/google.svg").default
                        }
                      />
                    </span>
                    <span className="btn-inner--text">Google</span>
                  </Button>
                </div> */}
              </CardHeader>
              <CardBody className="px-lg-5 py-lg-5">
                {/* <div className="text-center text-muted mb-4">
                  <small>Or sign in with credentials</small>
                </div> */}
                <Form role="form" onSubmit={handleSubmitLogin}>
                  <FormGroup
                    className={classnames('mb-3', {
                      focused: focusedEmail,
                    })}
                  >
                    <InputGroup className="input-group-merge input-group-alternative">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="ni ni-email-83" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        placeholder="Email"
                        type="email"
                        onFocus={() => setfocusedEmail(true)}
                        onBlur={() => setfocusedEmail(true)}
                        onChange={(e) => {
                          setDataForgot({
                            ...dataForgot,
                            email: e.target.value,
                          });
                        }}
                      />
                    </InputGroup>
                  </FormGroup>
                  {/* <FormGroup
                    className={classnames({
                      focused: focusedPassword,
                    })}
                  >
                    <InputGroup className="input-group-merge input-group-alternative">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="ni ni-lock-circle-open" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        placeholder="Password"
                        type="password"
                        onFocus={() => setfocusedPassword(true)}
                        onBlur={() => setfocusedPassword(true)}
                        onChange={(e) => {
                          setDataForgot({
                            ...dataForgot,
                            password: e.target.value,
                          });
                        }}
                      />
                    </InputGroup>
                  </FormGroup> */}
                  {/* <div className="custom-control custom-control-alternative custom-checkbox"> */}
                  <div className="d-flex justify-content-center">
                    {/* <input
                      className="custom-control-input"
                      id=" customCheckLogin"
                      type="checkbox"
                    />
                    <label
                      className="custom-control-label"
                      htmlFor=" customCheckLogin"
                    >
                      <span className="text-muted">Remember me</span>
                    </label> */}
                    {message !== '' ? (
                      <>
                        <span className="text-danger">{message}</span>
                      </>
                    ) : null}
                  </div>
                  {/* </div> */}
                  <div className="text-center">
                    {isLoading ? (
                      <div class="spinner-border text-info" />
                    ) : (
                      <Button className="my-4" color="info" type="submit">
                        Cấp lại mật khẩu
                      </Button>
                    )}
                  </div>
                </Form>
              </CardBody>
            </Card>
            <Row className="mt-3">
              <Col xs="6">
                <a
                  className="text-light"
                  href="login"
                  // onClick={(e) => e.preventDefault()}
                >
                  <small>Đăng nhập?</small>
                </a>
              </Col>
              {/* <Col className="text-right" xs="6">
                <a
                  className="text-light"
                  href="#pablo"
                  onClick={(e) => e.preventDefault()}
                >
                  <small>Create new account</small>
                </a>
              </Col> */}
            </Row>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default ForgotPass;

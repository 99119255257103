import TypeActions from "../TypeActions";

const initialState = {
  listKey: [],
  keyById: {},
  isGetting: false,
  isCreating: false,
  isEditing: false,
  isDeleting: false,
  error: "",
};

export const keyReducer = (state = initialState, action) => {
  switch (action.type) {
    //!Get list key
    case TypeActions.GET_LIST_KEY_REQUEST:
      return {
        ...state,
        isGetting: true,
      };
    case TypeActions.GET_LIST_KEY_SUCCESS:
      return {
        ...state,
        listKey: action.data,
        isGetting: false,
      };
    case TypeActions.GET_LIST_KEY_FAILED:
      return {
        ...state,
        error: action.error,
        isGetting: false,
      };
    //!Get key
    case TypeActions.GET_KEY_REQUEST:
      return {
        ...state,
        isGetting: true,
      };
    case TypeActions.GET_KEY_SUCCESS:
      return {
        ...state,
        keyById: action.data,
        isGetting: false,
      };
    case TypeActions.GET_KEY_FAILED:
      return {
        ...state,
        error: action.error,
        isGetting: false,
      };
    //!Create key
    case TypeActions.CREATE_KEY_REQUEST:
      return {
        ...state,
        isCreating: true,
      };
    case TypeActions.CREATE_KEY_SUCCESS:
      return {
        ...state,
        isCreating: false,
      };
    case TypeActions.CREATE_KEY_FAILED:
      return {
        ...state,
        error: action.error,
        isCreating: false,
      };
    //!Edit key
    case TypeActions.EDIT_KEY_REQUEST:
      return {
        ...state,
        isEditing: true,
      };
    case TypeActions.EDIT_KEY_SUCCESS:
      return {
        ...state,
        isEditing: false,
      };
    case TypeActions.EDIT_KEY_FAILED:
      return {
        ...state,
        error: action.error,
        isEditing: false,
      };
    //!Delete key
    case TypeActions.DELETE_KEY_REQUEST:
      return {
        ...state,
        isDeleting: true,
      };
    case TypeActions.DELETE_KEY_SUCCESS:
      return {
        ...state,
        isDeleting: false,
      };
    case TypeActions.DELETE_KEY_FAILED:
      return {
        ...state,
        error: action.error,
        isDeleting: false,
      };
    //!Default
    default:
      return {
        ...state,
      };
  }
};

export default keyReducer;

import { all, fork } from "redux-saga/effects";
import roleSaga from "./roleSaga";
import userSaga from "./userSaga";
import keySaga from "./keySaga";
import documentTypeSaga from "./documentTypeSaga";
import documentSaga from "./documentSaga";
import tenderNoticeSaga from "./tenderNoticeSaga";
import tenderProfileSaga from "./tenderProfileSaga";
import dashboardSaga from './dashboardSaga';

export function* rootSagas() {
  yield all([
    fork(userSaga),
    fork(roleSaga),
    fork(keySaga),
    fork(documentTypeSaga),
    fork(documentSaga),
    fork(tenderNoticeSaga),
    fork(tenderProfileSaga),
    fork(dashboardSaga),
  ]);
}
export default rootSagas;

import { TextField } from "@material-ui/core";
import queryString from "query-string";
import React, { useEffect, useState } from "react";
import { Autocomplete } from "@material-ui/lab";
import { useDispatch, useSelector } from "react-redux";
import { Button, Col, Row } from "reactstrap";
import documentTypeActions from "Redux/Actions/documentTypeActions";
import "./Filter.css";
import { Formik } from "formik";

function Filter({ Children, openFilter, setopenFilter, onFilter }) {
  //!Const
  let filter = null;
  const dispatch = useDispatch();

  let filterClasses = "side-drawer";
  const listdocumentType = useSelector(
    (state) => state.documentTypeReducer.listdocumentType
  );
  //!State
  const [searchType, setsearchType] = useState("");
  const [detailFilter, setdetailFilter] = useState({
    documentTypeId: "",
  });
  //!Function
  const onClose = () => {
    setdetailFilter({
      documentTypeId: "",
    });
    setsearchType("");
    setopenFilter(false);
    if (onFilter) {
      onFilter(null);
    }
  };
  const handleSubmit = () => {
    if (
      detailFilter.documentTypeId &&
      detailFilter.documentTypeId !== null &&
      detailFilter.documentTypeId.id
    ) {
      const objFilter = { documentTypeId: detailFilter.documentTypeId.id };
      if (onFilter) {
        onFilter(objFilter);
      }
    } else {
      if (onFilter) {
        onFilter(null);
      }
    }
    setopenFilter(false);
  };
  //!UseEffect
  useEffect(() => {
    dispatch(
      documentTypeActions.getListDocumentType(queryString.stringify(searchType))
    );
  }, [searchType]);
  if (openFilter) {
    filterClasses = "side-drawer open";
  } else {
    filterClasses = "side-drawer";
  }
  //!Render
  return (
    <nav className={filterClasses}>
      {/* <Formik
        initialValues={detailFilter}
        enableReinitialize
        onSubmit={handleFilterSubmit}
      >
        {({ values, setFieldValue, handleSubmit, errors, touched }) => {
          return ( */}
      <>
        <Row
        // className="ml-1"
        //  style={{ marginLeft: "15px" }}
        >
          <Button
            style={{
              margin: "5px",
              backgroundColor: "transparent",
              color: "red",
              boxShadow: "none",
              padding: "1",
              border: "none",
            }}
            color="secondary"
            type="button"
            onClick={() => {
              setopenFilter(false);
            }}
          >
            <i className="ni ni-bold-right" style={{ marginRight: "8px" }} />
            Đóng
          </Button>
        </Row>
        <hr style={{ margin: "5px" }} />
        {/* {Children} */}
        <Row className="ml-3">
          <Col lg="12" md="12" xs="12" className="p-2 text-left">
            <h3>Thiết lập lựa chọn lọc thông tin</h3>
          </Col>
        </Row>
        <hr style={{ margin: "5px" }} />
        <div className="scrollbar-hidden">
          {/* Content */}
          <div className="container-fluid">
            <Row>
              <Col lg="12" md="12" xs="12" className="p-2 text-left">
                <b>Theo loại tài liệu</b>
              </Col>
              <Col lg="12" md="12" xs="12" className="p-2">
                <Autocomplete
                  options={
                    listdocumentType && listdocumentType.results !== undefined
                      ? listdocumentType.results
                      : []
                  }
                  value={detailFilter.documentTypeId}
                  // value={values.customerId}
                  getOptionLabel={(option) => {
                    if (option !== null && option.name) return option.name;
                  }}
                  onChange={(e, value) => {
                    if (value !== null) {
                      // setdetailFilter({
                      //   ...detailFilter,
                      //   documentTypeId: value,
                      // });
                      setdetailFilter({ documentTypeId: value });
                    } else {
                      setdetailFilter({ documentTypeId: null });
                    }
                    setsearchType("");
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      // style={{
                      //   height: "80%",
                      // }}
                      // placeholder="Phân quyền"
                      label="Loại tài liệu"
                      name="docType"
                      variant="outlined"
                      fullWidth
                      // type="text"
                      onChange={(e) => {
                        if (e.target.value !== "") {
                          setsearchType({ name: e.target.value });
                        } else {
                          setsearchType("");
                        }
                      }}
                    />
                  )}
                />
              </Col>
            </Row>
          </div>
          {/* End Content */}
        </div>
        <div
          className="position-absolute bottom-0 text-center"
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            backgroundColor: "white",
          }}
        >
          <div className="p-2 d-block" style={{ width: "100%" }}>
            <div className="m-2">
              <Button
                style={{ backgroundColor: "rgba(0, 0, 0, .2)" }}
                className="btn btn-secondary btn-block"
                onClick={onClose}
              >
                Xoá bộ lọc
              </Button>
            </div>
            <div className="m-2">
              <Button
                className="btn bg-info text-white btn-block"
                onClick={handleSubmit}
              >
                Áp dụng bộ lọc
              </Button>
            </div>
          </div>
        </div>
      </>
      );
      {/* }}
      </Formik> */}
    </nav>
  );
}

export default Filter;

import {
  Checkbox,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TableRow,
  TextField,
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import Popup from "components/Popup";
import { Formik } from "formik";
import { toUpper } from "lodash-es";
import moment from "moment";
import queryString from "query-string";
import React, { useEffect, useRef, useState } from "react";
import { Search } from "react-bootstrap-table2-toolkit";
import { store } from "react-notifications-component";
import "react-notifications-component/dist/theme.css";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router";
import { Button, Card, CardBody, CardFooter, Col, Row } from "reactstrap";
import documentActions from "Redux/Actions/documentActions";
import tenderNoticeActions from "Redux/Actions/tenderNoticeActions";
import tenderProfileActions from "Redux/Actions/tenderProfileActions";
import userActions from "Redux/Actions/userActions";
import ChooseChilds from "./ChooseChilds";
import Note from "./Note";

const { SearchBar } = Search;

function AddTenderProfileForm(props) {
  //!Const
  const history = useHistory();
  const dispatch = useDispatch();
  const typingTimeOutRef = useRef(null);
  const listData = useSelector((state) => state.userReducer.listData);
  const tenderProfileById = useSelector(
    (state) => state.tenderProfileReducer.tenderProfileById
  );
  let childIds = {};
  const { tBMTInfoId, tenderProfileId } = useParams();

  const tenderNoticeById = useSelector(
    (state) => state.tenderNoticeReducer.tenderNoticeById
  );
  const documentForProfile = useSelector(
    (state) => state.documentReducer.documentForProfile
  );
  const isCreating = useSelector((state) => state.userReducer.isCreating);
  const isEditting = useSelector((state) => state.userReducer.isEditting);
  const userByID = useSelector((state) => state.userReducer.userByID);

  //!State
  const [openPopup, setopenPopup] = useState(false);
  const [openPopupNote, setopenPopupNote] = useState(false);
  const [tenderNotice, settenderNotice] = useState({});
  const [searchUser, setsearchUser] = useState("");
  const [detailChilds, setdetailChilds] = useState({
    parentId: "",
    childs: [],
  });
  const [detailNote, setdetailNote] = useState({
    index: "",
    note: "",
  });
  const [detailParams, setdetailParams] = useState({
    tBMTInfoId: tBMTInfoId,
    documents: [],
  });

  //!Function
  const handleProfile = (value) => {
    let detailUp;
    let detailEdit;

    detailUp = {
      tBMTInfoId: value.tBMTInfoId,
      documents: value.documents
        .map((index, key) => {
          return {
            documentTypeId: index.documentTypeId.id,
            note: index.note,
            listDoc: index.listDoc
              .map((index1, key1) => {
                if (index1.isSelected === true) {
                  return {
                    isDone: index1.isDone,
                    documentId: index1.documentId.id,
                    dueDate: index1.dueDate,
                    userId: index1.userId.id,
                    selectedIds: index1.selectedIds.map((index2) => {
                      return { docSelectId: index2.docSelectId.id };
                    }),
                  };
                }
              })
              .filter((item) => item !== undefined),
          };
        })
        .filter((item) => item.listDoc.length > 0),
    };
    if (tenderProfileId === "add") {
      dispatch(
        tenderProfileActions.createTenderProfile(detailUp, {
          success: () => {
            store.addNotification({
              title: "Thông báo!",
              message: "Tạo hồ sơ dự thầu thành công!",
              type: "success", // 'default', 'success', 'info', 'warning'
              container: "bottom-right", // where to position the notifications
              animationIn: ["animated", "fadeIn"], // animate.css classes that's applied
              animationOut: ["animated", "fadeOut"], // animate.css classes that's applied
              dismiss: {
                pauseOnHover: true,
                onScreen: true,
                duration: 3000,
              },
            });
            history.goBack();
          },
          failed: (mess) => {
            store.addNotification({
              title: "Thông báo!",
              message:
                "Tạo hồ sơ dự thầu thất bại! Lỗi: " +
                mess +
                "! Vui lòng thử lại!",
              type: "danger", // 'default', 'success', 'info', 'warning'
              container: "bottom-right", // where to position the notifications
              animationIn: ["animated", "fadeIn"], // animate.css classes that's applied
              animationOut: ["animated", "fadeOut"], // animate.css classes that's applied
              dismiss: {
                onScreen: true,
                pauseOnHover: true,
                duration: 5000,
              },
            });
          },
        })
      );
    } else {
      detailEdit = { documents: detailUp.documents };
      dispatch(
        tenderProfileActions.editTenderProfile(detailEdit, tenderProfileId, {
          success: () => {
            store.addNotification({
              title: "Thông báo!",
              message: "Cập nhật hồ sơ dự thầu thành công!",
              type: "success", // 'default', 'success', 'info', 'warning'
              container: "bottom-right", // where to position the notifications
              animationIn: ["animated", "fadeIn"], // animate.css classes that's applied
              animationOut: ["animated", "fadeOut"], // animate.css classes that's applied
              dismiss: {
                pauseOnHover: true,
                onScreen: true,
                duration: 3000,
              },
            });
            history.goBack();
          },
          failed: (mess) => {
            store.addNotification({
              title: "Thông báo!",
              message:
                "Cập nhật hồ sơ dự thầu thất bại! Lỗi: " +
                mess +
                "! Vui lòng thử lại!",
              type: "danger", // 'default', 'success', 'info', 'warning'
              container: "bottom-right", // where to position the notifications
              animationIn: ["animated", "fadeIn"], // animate.css classes that's applied
              animationOut: ["animated", "fadeOut"], // animate.css classes that's applied
              dismiss: {
                onScreen: true,
                pauseOnHover: true,
                duration: 5000,
              },
            });
          },
        })
      );
    }
  };
  function romanize(num) {
    var lookup = {
        M: 1000,
        CM: 900,
        D: 500,
        CD: 400,
        C: 100,
        XC: 90,
        L: 50,
        XL: 40,
        X: 10,
        IX: 9,
        V: 5,
        IV: 4,
        I: 1,
      },
      roman = "",
      i;
    for (i in lookup) {
      while (num >= lookup[i]) {
        roman += i;
        num -= lookup[i];
      }
    }
    return roman;
  }
  //!Use effect
  useEffect(() => {
    dispatch(userActions.getListUsers(queryString.stringify(searchUser)));
  }, [searchUser]);

  useEffect(() => {
    if (tBMTInfoId && tBMTInfoId !== null) {
      dispatch(tenderNoticeActions.getTenderNotice(tBMTInfoId));
    }
  }, [tBMTInfoId]);
  useEffect(() => {
    dispatch(documentActions.getDocumentForProfile());
  }, []);
  useEffect(() => {
    if (tenderProfileId !== "add")
      dispatch(
        tenderProfileActions.getTenderProfile(
          tenderProfileId +
            "?" +
            queryString.stringify({
              getToUpdate: true,
              populate:
                "documents.listDoc.selectedIds.docSelectId,documents.listDoc.userId,documents.listDoc.documentId,documents.documentTypeId,tBMTInfoId",
            })
        )
      );
  }, [tenderProfileId]);
  useEffect(() => {
    if (tenderProfileId === "add") {
      settenderNotice(tenderNoticeById);
      if (documentForProfile !== undefined) {
        let tempData = documentForProfile;

        let tempData1 = [];
        tempData.map((doc, index) => {
          if (doc.listDoc.length > 0) {
            doc["listDoc"].map((Doc, index1) => {
              if (Doc.selectedIds !== undefined) {
                tempData[index]["listDoc"][index1]["selectedIds"] =
                  Doc.selectedIds;
              } else {
                tempData[index]["listDoc"][index1]["selectedIds"] = [];
              }
              if (Doc.dueDate !== undefined) {
                tempData[index]["listDoc"][index1]["dueDate"] = Doc.dueDate;
              } else {
                tempData[index]["listDoc"][index1]["dueDate"] = "";
              }
              if (Doc.userId !== undefined) {
                tempData[index]["listDoc"][index1]["userId"] = Doc.userId;
              } else {
                tempData[index]["listDoc"][index1]["userId"] = {};
              }
              if (Doc.isDone !== undefined) {
                tempData[index]["listDoc"][index1]["isDone"] = Doc.isDone;
              } else {
                tempData[index]["listDoc"][index1]["isDone"] = false;
              }
              if (Doc.isSelected !== undefined) {
                tempData[index]["listDoc"][index1]["isSelected"] =
                  Doc.isSelected;
              } else {
                tempData[index]["listDoc"][index1]["isSelected"] = false;
              }
              if (doc.note !== undefined) {
                tempData[index]["note"] = doc.note;
              } else {
                tempData[index]["note"] = "";
              }
            });
            return doc;
          }
        });
        tempData.map((doc, index) => {
          if (doc.listDoc.length > 0) {
            tempData1.push(doc);
          }
        });

        setdetailParams({ ...detailParams, documents: tempData1 });
      }
    } else {
      if (tenderProfileById && tenderProfileById !== undefined) {
        let tempDoc = tenderProfileById.documents;
        tempDoc !== undefined &&
          tempDoc.map((index, key) => {
            index.listDoc.map((index1, key1) => {
              if (tempDoc[key]["listDoc"][key1]["isSelected"] !== undefined) {
                tempDoc[key]["listDoc"][key1]["isSelected"] = index1.isSelected;
              } else {
                tempDoc[key]["listDoc"][key1]["isSelected"] = true;
              }
            });
          });
        setdetailParams({
          ...detailParams,
          documents: tempDoc,
        });

        settenderNotice(tenderProfileById.tBMTInfoId);
      }
    }
  }, [documentForProfile, tenderProfileById]);
  // useEffect(() => {
  //   if (documentForProfile !== undefined) {
  //     settemplate(documentForProfile);
  //   }
  // }, [documentForProfile]);

  //!Render
  return (
    <>
      <Card>
        <Formik
          initialValues={detailParams}
          enableReinitialize
          onSubmit={handleProfile}
        >
          {({ values, setFieldValue, handleSubmit, errors, touched }) => {
            let tempDoc = values.documents;
            return (
              <form>
                <CardBody>
                  <Row className="align-items-flex-start">
                    <Col lg="12" md="12" xs="12" className="p-2">
                      <span className="text-info">
                        <i className="fas fa-info-circle text-danger" />{" "}
                        <b>Thông tin gói thầu được chọn:</b>
                      </span>
                      <Divider />
                    </Col>
                  </Row>
                  <Row className="align-items-flex-start">
                    <Col lg="12" md="12" xs="12" className="p-2">
                      <span>
                        Số TBMT: <b>{tenderNotice && tenderNotice.tbmtNo}</b>
                      </span>
                    </Col>
                    <Col lg="12" md="12" xs="12" className="p-2">
                      <span>
                        Tên gói thầu:{" "}
                        <b>{tenderNotice && tenderNotice.title}</b>
                      </span>
                    </Col>
                    <Col lg="12" md="12" xs="12" className="p-2">
                      <span>
                        Thời gian nhận E-HSDT từ ngày:{" "}
                        <b>
                          {moment(tenderNotice && tenderNotice.tddt).format(
                            "DD/MM/YYYY hh:mm"
                          )}
                        </b>{" "}
                        - đến ngày:{" "}
                        <b>
                          {moment(tenderNotice && tenderNotice.dueDate).format(
                            "DD/MM/YYYY hh:mm"
                          )}
                        </b>
                      </span>
                    </Col>
                    <Col lg="12" md="12" xs="12" className="p-2">
                      <span>
                        Bên mời thầu: <b>{tenderNotice && tenderNotice.bmt}</b>
                      </span>
                    </Col>
                  </Row>
                  <Row className="align-items-flex-start">
                    <Col lg="12" md="12" xs="12" className="p-2">
                      <span className="text-info">
                        <i class="fas fa-layer-group text-danger"></i>{" "}
                        <b>Thiết lập hồ sơ và người phụ trách thực hiện:</b>
                      </span>
                      <Divider />
                    </Col>
                  </Row>
                  {values.documents !== undefined &&
                    values.documents.map((doc, index) => {
                      if (doc.listDoc.length > 0)
                        return (
                          <>
                            <Row className="align-items-flex-start">
                              <Col lg="12" md="12" xs="12" className="p-2">
                                <b>
                                  {toUpper(
                                    `Phần ${romanize(
                                      doc["documentTypeId"].index
                                    )}. ${doc["documentTypeId"].name}`
                                  )}
                                </b>
                              </Col>
                            </Row>
                            <Row className="align-items-center d-flex justify-content-center">
                              <Col lg="11" md="11" xs="12" className="p-2 ">
                                <Table>
                                  <TableHead>
                                    <TableRow>
                                      <TableCell
                                        padding="checkbox"
                                        width="10%"
                                      ></TableCell>
                                      <TableCell width="40%">
                                        Tên hồ sơ
                                      </TableCell>
                                      <TableCell align="center" width="10%">
                                        Chọn danh mục
                                      </TableCell>
                                      <TableCell width="30%">
                                        Người phụ trách
                                      </TableCell>
                                      <TableCell width="10%">
                                        Thời hạn
                                      </TableCell>
                                    </TableRow>
                                  </TableHead>
                                  <TableBody>
                                    {doc["listDoc"].map((Doc, index1) => {
                                      return (
                                        <>
                                          <TableRow>
                                            <TableCell padding="checkbox">
                                              <Checkbox
                                                className="text-info"
                                                // value={
                                                //   tempDoc[index]["listDoc"][
                                                //     index1
                                                //   ]["isDone"]
                                                // }
                                                checked={
                                                  tempDoc[index]["listDoc"][
                                                    index1
                                                  ]["isSelected"]
                                                }
                                                onChange={(e) => {
                                                  tempDoc[index]["listDoc"][
                                                    index1
                                                  ]["isSelected"] =
                                                    !tempDoc[index]["listDoc"][
                                                      index1
                                                    ]["isSelected"];
                                                  setFieldValue(
                                                    "documents",
                                                    tempDoc
                                                  );
                                                }}
                                              />
                                            </TableCell>
                                            <TableCell width="20%">
                                              <span>
                                                {Doc.documentId !== undefined &&
                                                  Doc.documentId.name}{" "}
                                                {Doc.selectedIds.length > 0 ? (
                                                  <i style={{ color: "red" }}>
                                                    (Đã chọn{" "}
                                                    {Doc.selectedIds.length}{" "}
                                                    danh mục)
                                                  </i>
                                                ) : null}
                                              </span>
                                            </TableCell>
                                            <TableCell
                                              align="center"
                                              width="10*"
                                            >
                                              {Doc.documentId !== undefined &&
                                              Doc.documentId.childrentIds
                                                .length > 0 ? (
                                                <Button
                                                  className="ml-2"
                                                  onClick={() => {
                                                    setdetailChilds({
                                                      id: tempDoc[index][
                                                        "listDoc"
                                                      ][index1].documentId.id,
                                                      childIds: tempDoc[index][
                                                        "listDoc"
                                                      ][index1][
                                                        "selectedIds"
                                                      ].map((index3, key3) => {
                                                        return index3
                                                          .docSelectId.id;
                                                      }),
                                                      index: index,
                                                      index1: index1,
                                                    });
                                                    setopenPopup(true);
                                                  }}
                                                >
                                                  <i class="fas fa-plus" />
                                                </Button>
                                              ) : null}
                                            </TableCell>
                                            <TableCell>
                                              <Autocomplete
                                                options={
                                                  listData &&
                                                  listData.results !== undefined
                                                    ? listData.results
                                                    : []
                                                }
                                                value={
                                                  tempDoc[index]["listDoc"][
                                                    index1
                                                  ]["userId"] !== undefined
                                                    ? tempDoc[index]["listDoc"][
                                                        index1
                                                      ]["userId"]
                                                    : {}
                                                }
                                                getOptionLabel={(option) => {
                                                  if (
                                                    option !== null &&
                                                    option.name
                                                  )
                                                    return option.name;
                                                }}
                                                onBlur={() => {
                                                  setsearchUser("");
                                                }}
                                                onChange={(e, value) => {
                                                  // setFieldValue("roleId", value);

                                                  tempDoc[index]["listDoc"][
                                                    index1
                                                  ]["userId"] = value;
                                                  setFieldValue(
                                                    "documents",
                                                    tempDoc
                                                  );
                                                }}
                                                renderInput={(params) => (
                                                  <TextField
                                                    {...params}
                                                    style={{
                                                      height: "80%",
                                                    }}
                                                    value={searchUser}
                                                    label="Chọn nhập"
                                                    name="userId"
                                                    variant="outlined"
                                                    fullWidth
                                                    onChange={(e) => {
                                                      const value =
                                                        e.target.value;
                                                      if (value !== "") {
                                                        setsearchUser({
                                                          name: value,
                                                        });
                                                      } else {
                                                        setsearchUser("");
                                                      }
                                                    }}
                                                  />
                                                )}
                                              />
                                            </TableCell>
                                            <TableCell width="10%">
                                              <TextField
                                                id="datetime-local"
                                                label="Chọn"
                                                type="datetime-local"
                                                // defaultValue={moment(
                                                //   new Date()
                                                // ).format("YYYY-MM-DDTHH:mm")}

                                                variant="outlined"
                                                fullWidth
                                                value={
                                                  tempDoc[index]["listDoc"][
                                                    index1
                                                  ]["userId"] !== undefined
                                                    ? moment(
                                                        tempDoc[index][
                                                          "listDoc"
                                                        ][index1]["dueDate"]
                                                      ).format(
                                                        "YYYY-MM-DDTHH:mm"
                                                      )
                                                    : null
                                                }
                                                onChange={(time) => {
                                                  if (
                                                    new Date(
                                                      time.target.value
                                                    ).getTime() >
                                                    new Date().getTime()
                                                  ) {
                                                    tempDoc[index]["listDoc"][
                                                      index1
                                                    ]["dueDate"] = new Date(
                                                      time.target.value
                                                    ).toISOString();
                                                    setFieldValue(
                                                      "documents",
                                                      tempDoc
                                                    );
                                                  } else {
                                                    store.addNotification({
                                                      title: "Thông báo!",
                                                      message:
                                                        "Thời hạn không được nhỏ hơn thời điểm hiện tại! Vui lòng thử lại!",
                                                      type: "danger", // 'default', 'success', 'info', 'warning'
                                                      container: "bottom-right", // where to position the notifications
                                                      animationIn: [
                                                        "animated",
                                                        "fadeIn",
                                                      ], // animate.css classes that's applied
                                                      animationOut: [
                                                        "animated",
                                                        "fadeOut",
                                                      ], // animate.css classes that's applied
                                                      dismiss: {
                                                        onScreen: true,
                                                        pauseOnHover: true,
                                                        duration: 5000,
                                                      },
                                                    });
                                                  }
                                                }}
                                                InputLabelProps={{
                                                  shrink: true,
                                                }}
                                              />
                                            </TableCell>
                                          </TableRow>
                                        </>
                                      );
                                    })}
                                  </TableBody>
                                </Table>
                                <Row className="align-items-center">
                                  <Col lg="12" md="12" xs="12" className="p-2 ">
                                    <b>Ghi chú thêm</b>
                                  </Col>
                                  <Col lg="12" md="12" xs="12" className="p-2 ">
                                    {/* <TextField
                                      variant="outlined"
                                      label="Nhập ghi chú"
                                      multiline
                                      rows={4}
                                      fullWidth
                                      value={tempDoc[index]["note"]}
                                      onChange={(e) => {
                                        tempDoc[index]["note"] = e.target.value;
                                        if (typingTimeOutRef.current) {
                                          clearTimeout(
                                            typingTimeOutRef.current
                                          );
                                        }
                                        typingTimeOutRef.current = setTimeout(
                                          () => {
                                            setFieldValue("documents", tempDoc);
                                          },
                                          300
                                        );
                                      }}
                                    /> */}
                                    <Card>
                                      <CardBody>
                                        {tempDoc[index]["note"] !== "" ? (
                                          tempDoc[index]["note"]
                                        ) : (
                                          <i className="text-danger">
                                            (Chưa có ghi chú!)
                                          </i>
                                        )}
                                      </CardBody>
                                      <CardFooter className="text-right">
                                        <Button
                                          className="bg-info text-white"
                                          onClick={() => {
                                            setdetailNote({
                                              note: tempDoc[index]["note"],
                                              index: index,
                                            });
                                            setopenPopupNote(true);
                                          }}
                                        >
                                          Cập nhật ghi chú
                                        </Button>
                                      </CardFooter>
                                    </Card>
                                  </Col>
                                </Row>
                              </Col>
                            </Row>
                          </>
                        );
                    })}
                </CardBody>
                <CardFooter>
                  <Row className="align-items-center text-right">
                    <Col lg="12" md="12" xs="12">
                      {isEditting || isCreating ? (
                        <div class="spinner-border text-info" />
                      ) : (
                        <span>
                          <Button
                            className="bg-info text-white"
                            onClick={handleSubmit}
                            type="submit"
                          >
                            <i className="fas fa-check" />{" "}
                            {tenderProfileId === "add"
                              ? "Thêm mới"
                              : "Cập nhật"}
                          </Button>
                          {/* {avatar ? <br /> : null} */}
                          <Button
                            // className=""
                            onClick={() => {
                              history.goBack();
                            }}
                          >
                            <i className="fas fa-times" /> Huỷ bỏ
                          </Button>
                        </span>
                      )}
                    </Col>
                  </Row>
                </CardFooter>
                <Popup
                  openPopup={openPopupNote}
                  title="Cập nhật ghi chú"
                  fullWidth
                >
                  <Note
                    setopenPopup={setopenPopupNote}
                    detail={detailNote}
                    onEnd={(val) => {
                      tempDoc[val.index]["note"] = val.note;
                      setFieldValue("documents", tempDoc);
                    }}
                  />
                </Popup>
                <Popup
                  openPopup={openPopup}
                  title="Lựa chọn danh mục"
                  fullWidth
                >
                  <ChooseChilds
                    setopenPopup={setopenPopup}
                    detail={detailChilds}
                    onEnd={(val) => {
                      if (val !== null) {
                        tempDoc[val.index]["listDoc"][val.index1][
                          "selectedIds"
                        ] = val.childIds.map((index) => {
                          return { docSelectId: { id: index } };
                        });
                        setFieldValue("documents", tempDoc);
                      }
                    }}
                  />
                </Popup>
              </form>
            );
          }}
        </Formik>
      </Card>
    </>
  );
}

export default AddTenderProfileForm;

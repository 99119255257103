/*!

=========================================================
* Argon Dashboard PRO React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useEffect, useState } from 'react';
// nodejs library that concatenates classes
import classnames from 'classnames';
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
  Label,
} from 'reactstrap';
// core components
import AuthHeader from 'components/Headers/AuthHeader.js';
import { useDispatch, useSelector } from 'react-redux';
import userActions from 'Redux/Actions/userActions';
import { useHistory, useLocation } from 'react-router';
import Select from 'react-select';
import queryString from 'query-string';

function Login() {
  //!Const
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const isLogging = useSelector((state) => state.userReducer.isLogging);
  //!State
  const [focusedEmail, setfocusedEmail] = React.useState(false);
  const [focusedPassword, setfocusedPassword] = React.useState(false);
  const [dataLogin, setDataLogin] = React.useState({
    email: '',
    password: '',
  });
  const [websiteValue, setWebsiteValue] = useState({
    label: 'Phần mềm quản lý đấu thầu',
    value: 0,
    url: '/',
  });

  const qlqtURL = process.env.REACT_APP_QLQT_LOGIN_URL;

  const websites = [
    {
      label: 'Phần mềm quản lý đấu thầu',
      value: 0,
      url: '/',
    },
    {
      label: 'Phần mềm quản lý đơn hàng',
      value: 1,
      url: qlqtURL,
    },
  ];
  const [message, setmessage] = React.useState('');
  //!Function
  const handleSubmitLogin = (e) => {
    e.preventDefault();
    if (
      dataLogin.email === 'ngamtq@fovina.vn' ||
      dataLogin.email === 'yenvh@fovina.vn' ||
      websiteValue.value === 1
    ) {
      dispatch(
        userActions.login(dataLogin, {
          success: (data) => {
            setmessage('');
            const token = data.tokens.access.token;
            const roleId = data.user.roleId;
            const refreshtoken = data.tokens.refresh.token;
            const id = data.user.id;
            localStorage.setItem('token', token);
            localStorage.setItem('roleId', roleId);
            localStorage.setItem('refreshtoken', refreshtoken);
            localStorage.setItem('id', id);
            if (websiteValue.value === 1) {
              window.location.href = `${websiteValue.url}?token=${token}&roleId=${roleId}&id=${id}&refreshtoken=${refreshtoken}`;
            } else {
              history.push(websiteValue.url);
            }
          },
          failed: (mess) => {
            setmessage(mess);
          },
        })
      );
    }
  };
  //!Use Effect
  useEffect(() => {
    if (!!location.search) {
      const query = queryString.parse(location.search);
      const { token, refreshtoken, roleId, id } = query;
      if (!!token && !!refreshtoken && !!roleId && !!id) {
        localStorage.setItem('token', token);
        localStorage.setItem('roleId', roleId);
        localStorage.setItem('refreshtoken', refreshtoken);
        localStorage.setItem('id', id);
        history.push('/');
      }
    } else {
      localStorage.clear();
    }
  }, [location]);
  // localStorage.setItem('token', '');
  // localStorage.setItem('id', '');
  // localStorage.setItem('roleId', '');
  // localStorage.setItem('refreshtoken', '');
  //!Render
  return (
    <>
      <AuthHeader
        title="Hệ thống quản lý sản xuất"
        lead="Đăng nhập để sử dụng các chức năng của hệ thống."
      />
      <Container className="mt--8 pb-5">
        <Row className="justify-content-center">
          <Col lg="5" md="7">
            <Card className="bg-secondary border-0 mb-0">
              <CardHeader className="bg-transparent pb-4">
                <div className="text-muted text-center mt-3 mb-1 text-black">
                  <h2 className="font-weight-bold">ĐĂNG NHẬP</h2>
                </div>
                {/* <div className="btn-wrapper text-center">
                  <Button
                    className="btn-neutral btn-icon"
                    color="default"
                    href="#pablo"
                    onClick={(e) => e.preventDefault()}
                  >
                    <span className="btn-inner--icon mr-1">
                      <img
                        alt="..."
                        src={
                          require("assets/img/icons/common/github.svg").default
                        }
                      />
                    </span>
                    <span className="btn-inner--text">Github</span>
                  </Button>
                  <Button
                    className="btn-neutral btn-icon"
                    color="default"
                    href="#pablo"
                    onClick={(e) => e.preventDefault()}
                  >
                    <span className="btn-inner--icon mr-1">
                      <img
                        alt="..."
                        src={
                          require("assets/img/icons/common/google.svg").default
                        }
                      />
                    </span>
                    <span className="btn-inner--text">Google</span>
                  </Button>
                </div> */}
              </CardHeader>
              <CardBody className="px-lg-5 py-lg-5">
                {/* <div className="text-center text-muted mb-4">
                  <small>Or sign in with credentials</small>
                </div> */}
                <Form role="form" onSubmit={handleSubmitLogin}>
                  <FormGroup
                    className={classnames('mb-3', {
                      focused: focusedEmail,
                    })}
                  >
                    <InputGroup className="input-group-merge input-group-alternative">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="ni ni-email-83" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        placeholder="Email"
                        type="email"
                        onFocus={() => setfocusedEmail(true)}
                        onBlur={() => setfocusedEmail(true)}
                        onChange={(e) => {
                          setDataLogin({ ...dataLogin, email: e.target.value });
                        }}
                      />
                    </InputGroup>
                  </FormGroup>
                  <FormGroup
                    className={classnames({
                      focused: focusedPassword,
                    })}
                  >
                    <InputGroup className="input-group-merge input-group-alternative">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="ni ni-lock-circle-open" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        placeholder="Password"
                        type="password"
                        onFocus={() => setfocusedPassword(true)}
                        onBlur={() => setfocusedPassword(true)}
                        onChange={(e) => {
                          setDataLogin({
                            ...dataLogin,
                            password: e.target.value,
                          });
                        }}
                      />
                    </InputGroup>
                  </FormGroup>
                  {/* <div className="custom-control custom-control-alternative custom-checkbox"> */}
                  <div className="d-flex justify-content-center">
                    {/* <input
                      className="custom-control-input"
                      id=" customCheckLogin"
                      type="checkbox"
                    />
                    <label
                      className="custom-control-label"
                      htmlFor=" customCheckLogin"
                    >
                      <span className="text-muted">Remember me</span>
                    </label> */}
                    {message !== '' ? (
                      <>
                        <span className="text-danger">{message}</span>
                      </>
                    ) : null}
                  </div>
                  <div style={{ width: '100%' }}>
                    <FormGroup>
                      <Label className="form-control-label text-sm">
                        Lựa chọn
                      </Label>
                      <Select
                        placeholder="Nhập để tìm kiếm"
                        isClearable={false}
                        value={websiteValue}
                        onChange={(e) => {
                          setWebsiteValue(e);
                        }}
                        options={websites.map((item) => ({
                          label: item.label,
                          value: item.value,
                          url: item.url,
                        }))}
                      />
                    </FormGroup>
                  </div>
                  {/* </div> */}
                  <div className="text-center">
                    {isLogging ? (
                      <div class="spinner-border text-info" />
                    ) : (
                      <Button className="my-4" color="info" type="submit">
                        Đăng nhập
                      </Button>
                    )}
                  </div>
                </Form>
              </CardBody>
            </Card>
            <Row className="mt-3">
              <Col xs="6">
                <a className="text-light" href="forgot-pass">
                  <small>Quên mật khẩu?</small>
                </a>
              </Col>
              {/* <Col className="text-right" xs="6">
                <a
                  className="text-light"
                  href="#pablo"
                  onClick={(e) => e.preventDefault()}
                >
                  <small>Create new account</small>
                </a>
              </Col> */}
            </Row>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default Login;

import React from "react";
import { Col, Row } from "reactstrap";

function InforUser({ dataUser }) {
  return (
    <>
      <Row>
        <Col lg="6" md="12" xs="12" className="p-2">
          <b>Họ tên: </b>
          {dataUser.name}
        </Col>
        <Col lg="6" md="12" xs="12" className="p-2">
          <b>Giới tính: </b>
          {dataUser.gender}
        </Col>
        <Col lg="6" md="12" xs="12" className="p-2">
          <b>Phân quyền: </b>
          {dataUser.roleId && dataUser.roleId.name}
        </Col>
        <Col lg="6" md="12" xs="12" className="p-2">
          <b>Email: </b>
          {dataUser.email}
        </Col>
        <Col lg="12" md="12" xs="12" className="p-2">
          <b>Địa chỉ: </b>
          {dataUser.address}
        </Col>
      </Row>
    </>
  );
}

export default InforUser;

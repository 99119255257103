import TypeActions from '../TypeActions';

const initialState = {
  isGetting: false,
  infoDashboard: {},
  error: ''
};

const dashboardReducer = (state = initialState, action) => {
  switch (action.type) {
    case TypeActions.GET_INFO_DASHBOARD_REQUEST:
      return {
        ...state,
        isGetting: true
      };
    case TypeActions.GET_INFO_DASHBOARD_SUCCESS:
    
      return {
        ...state,
        infoDashboard: {...action.data},
        isGetting: false
      };
    case TypeActions.GET_INFO_DASHBOARD_FAILED:
      return {
        ...state,
        error: action.error,
        isGetting: false
      };
    //!Default
    default:
      return {
        ...state
      };
  }
};

export default dashboardReducer;

import React, { useEffect, useRef, useState } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import {
  Button,
  Card,
  CardHeader,
  Col,
  Container,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Modal,
  Row,
} from 'reactstrap';
import { dataTable } from 'variables/general';
import Back from './Filter/Back';
import classnames from 'classnames';
import Filter from './Filter/Filter';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import userActions from 'Redux/Actions/userActions';
import { store } from 'react-notifications-component';
import 'react-notifications-component/dist/theme.css';
import queryString from 'query-string';
import { useHistory } from 'react-router';
import Popup from 'components/Popup';
import SetPass from './Popup/SetPass';

const { SearchBar } = Search;

function Results(props) {
  //!Const
  let filter;
  let backdrop;
  if (openFilter) {
    filter = (
      <Filter openFilter={openFilter} setopenFilter={setopenFilter}></Filter>
    );
    backdrop = <Back setopenFilter={setopenFilter}></Back>;
  }

  const dispatch = useDispatch();
  const listData = useSelector((state) => state.userReducer.listData);
  const isGetting = useSelector((state) => state.userReducer.isGetting);
  const typingTimeOutRef = useRef(null);
  const history = useHistory();
  //!State
  const [openFilter, setopenFilter] = useState(false);
  const [openPopup, setopenPopup] = useState(false);
  const [action, setaction] = useState('');
  const [detailRestore, setdetailRestore] = useState({});
  const [openModal, setopenModal] = useState(false);
  const [page, setpage] = useState(0);
  const [searchFocus, setsearchFocus] = useState(false);
  const [rowsPerPage, setrowsPerPage] = useState(5);
  const [totalResult, settotalResult] = useState(0);
  const [userSearch, setuserSearch] = useState('');
  const [idDelete, setidDelete] = useState('');
  const [dataTable, setdataTable] = useState([]);
  const [detailParams, setdetailParams] = useState({
    page: page + 1,
    limit: rowsPerPage,
    populate: 'roleId',
  });
  if (openFilter) {
    backdrop = <Back setopenFilter={setopenFilter}></Back>;
  }
  //!Function
  const pagination = paginationFactory({
    // page: page + 1,
    page: page + 1,
    onPageChange: (page, rowsPerPage) => {
      setpage(page - 1);
    },
    sizePerPage: rowsPerPage,
    // onSizePerPageChange: (page, rowsPerPage) => {
    //   setrowsPerPage(rowsPerPage);
    // },
    onSizePerPageChange: (value) => {
      setrowsPerPage(value);
      setpage(0);
    },
    totalSize: totalResult,
    showTotal: false,
    withFirstAndLast: false,
    alwaysShowAllBtns: true,
    sizePerPageRenderer: ({
      options,
      currSizePerPage,
      onSizePerPageChange,
    }) => (
      <div className="dataTables_length" id="datatable-basic_length">
        <label>
          Hiển thị{' '}
          {
            <select
              value={rowsPerPage}
              name="datatable-basic_length"
              aria-controls="datatable-basic"
              className="form-control form-control-sm"
              onChange={(e) => onSizePerPageChange(e.target.value)}
            >
              <option value="5">5</option>
              <option value="10">10</option>
              <option value="30">30</option>
              <option value="100">100</option>
            </select>
          }{' '}
          dòng.
        </label>
      </div>
    ),
  });
  //!Use effect
  useEffect(() => {
    setdetailParams({
      ...detailParams,
      page: page + 1,
      limit: rowsPerPage,
    });
  }, [page, rowsPerPage]);
  useEffect(() => {
    dispatch(userActions.getListUsers(queryString.stringify(detailParams)));
  }, [detailParams]);
  useEffect(() => {
    if (idDelete === '')
      dispatch(userActions.getListUsers(queryString.stringify(detailParams)));
  }, [idDelete]);
  useEffect(() => {
    if (listData && listData.results !== undefined) {
      setdataTable(listData.results);
      settotalResult(listData.totalResults);
    }
  }, [listData]);

  //!Render
  return (
    <>
      {/* The Modal */}
      <Modal
        isOpen={openModal}
        toggle={() => {
          setopenModal(!openModal);
        }}
        className="text-center"
      >
        <ModalHeader
          toggle={() => {
            setopenModal(!openModal);
          }}
        >
          <h2 className="text-danger">Thông báo!</h2>
        </ModalHeader>
        <ModalBody>
          {action === 'delete'
            ? 'Bạn có chắc chắn muốn xoá tài khoản?'
            : 'Bạn có chắc chắn muốn khôi phục tài khoản này?'}
        </ModalBody>
        <ModalFooter>
          <Button
            color="primary"
            onClick={() => {
              setopenModal(false);
              if (action === 'delete') {
                dispatch(
                  userActions.deleteUsers(idDelete, {
                    success: () => {
                      setidDelete('');
                      store.addNotification({
                        title: 'Thông báo!',
                        message: 'Xoá tài khoản thành công!',
                        type: 'success', // 'default', 'success', 'info', 'warning'
                        container: 'bottom-right', // where to position the notifications
                        animationIn: ['animated', 'fadeIn'], // animate.css classes that's applied
                        animationOut: ['animated', 'fadeOut'], // animate.css classes that's applied
                        dismiss: {
                          pauseOnHover: true,
                          onScreen: true,
                          duration: 3000,
                        },
                      });
                    },
                    failed: (mess) => {
                      setidDelete('');
                      store.addNotification({
                        title: 'Thông báo!',
                        message:
                          'Xoá tài khoản thất bại! Lỗi: ' +
                          mess +
                          '! Vui lòng thử lại!',
                        type: 'danger', // 'default', 'success', 'info', 'warning'
                        container: 'bottom-right', // where to position the notifications
                        animationIn: ['animated', 'fadeIn'], // animate.css classes that's applied
                        animationOut: ['animated', 'fadeOut'], // animate.css classes that's applied
                        dismiss: {
                          onScreen: true,
                          pauseOnHover: true,
                          duration: 5000,
                        },
                      });
                    },
                  })
                );
              } else {
                const detailEdit = {
                  name: detailRestore.name,
                  roleId: detailRestore.roleId && detailRestore.roleId.id,
                  gender: detailRestore.gender,
                  address: detailRestore.address,
                  status: 'active',
                };
                dispatch(
                  userActions.editUsers(detailEdit, idDelete, {
                    success: () => {
                      setidDelete('');
                      store.addNotification({
                        title: 'Thông báo!',
                        message: 'Khôi phục tài khoản thành công!',
                        type: 'success', // 'default', 'success', 'info', 'warning'
                        container: 'bottom-right', // where to position the notifications
                        animationIn: ['animated', 'fadeIn'], // animate.css classes that's applied
                        animationOut: ['animated', 'fadeOut'], // animate.css classes that's applied
                        dismiss: {
                          pauseOnHover: true,
                          onScreen: true,
                          duration: 3000,
                        },
                      });
                    },
                    failed: (mess) => {
                      setidDelete('');
                      store.addNotification({
                        title: 'Thông báo!',
                        message:
                          'Khôi phục tài khoản thất bại! Lỗi: ' +
                          mess +
                          '! Vui lòng thử lại!',
                        type: 'danger', // 'default', 'success', 'info', 'warning'
                        container: 'bottom-right', // where to position the notifications
                        animationIn: ['animated', 'fadeIn'], // animate.css classes that's applied
                        animationOut: ['animated', 'fadeOut'], // animate.css classes that's applied
                        dismiss: {
                          onScreen: true,
                          pauseOnHover: true,
                          duration: 5000,
                        },
                      });
                    },
                  })
                );
              }
            }}
          >
            Đồng ý
          </Button>{' '}
          <Button
            color="secondary"
            onClick={() => {
              setopenModal(false);
            }}
          >
            Huỷ bỏ
          </Button>
        </ModalFooter>
      </Modal>
      {/* The Modal */}

      <Card>
        <CardHeader>
          {filter}
          {backdrop}
          <Row className="align-items-center ">
            <Col lg="6" md="7" sm="12">
              <h3 className="mb-0">
                Danh sách tài khoản ({totalResult} bản ghi)
              </h3>
            </Col>
            <Col className="mt-3 mt-md-0 text-md-right" lg="6" md="5" sm="12">
              {/* <Button>
                <i className="ni ni-bold-down"></i>
              </Button> */}
              {/* <Button
                type="button"
                color="info"
                onClick={() => {
                  setopenFilter(true);
                }}
              >
                <i
                  className="ni ni-bold-down"
                  style={{
                    marginRight: "10px",
                  }}
                />
                Hiển thị lọc
              </Button> */}
              <Filter openFilter={openFilter} setopenFilter={setopenFilter} />
            </Col>
          </Row>
        </CardHeader>
        <ToolkitProvider
          data={dataTable}
          keyField="email"
          columns={[
            {
              dataField: 'name',
              text: 'Tên người dùng',
              sort: false,
            },

            {
              dataField: 'email',
              text: 'Email',
              sort: false,
            },
            {
              dataField: 'roleId',
              text: 'Phân quyền',
              sort: false,
              formatter: (key, value) => {
                return value.roleId && value.roleId.name;
              },
            },
            // {
            //   dataField: "gender",
            //   text: "Giới tính",
            //   sort: false,
            // },
            {
              dataField: 'address',
              text: 'Địa chỉ',
              sort: false,
              formatter: (val, valRow, key) => {
                return (
                  <div
                    style={{
                      wordWrap: 'break-word !important',
                      width: '300px',
                      whiteSpace: 'normal',
                    }}
                  >
                    {val}
                  </div>
                );
              },
            },
            {
              dataField: 'status',
              text: 'Trạng thái hoạt động',
              sort: false,
              align: 'center',
              headerAlign: 'center',
              formatter: (key, value) => {
                if (value.status === 'active') {
                  return (
                    <span className="font-weight-bold text-success">
                      Hoạt động
                    </span>
                  );
                } else if (value.status === 'lock') {
                  return (
                    <span className="font-weight-bold text-warning">
                      Đã khoá
                    </span>
                  );
                } else if (value.status === 'delete') {
                  return (
                    <span className="font-weight-bold text-danger">
                      Đã đóng
                    </span>
                  );
                }
              },
            },
            {
              dataField: 'action',
              text: 'Hành động',
              sort: false,
              headerAlign: 'center',
              align: 'center',
              formatter: (key, value) => {
                return (
                  <div className="d-flex justify-content-center">
                    <Button
                      className="btn-icon-only rounded-circle bg-success text-white btn-sm m-0"
                      // color="default"
                      data-toggle="tooltip"
                      title="Chỉnh sửa tài khoản"
                      type="button"
                      onClick={() => {
                        history.push(
                          `/config-facility/modify-user/${value.id}`
                        );
                      }}
                    >
                      <span className="btn-inner--icon">
                        <i className="fas fa-user-edit" />
                      </span>
                    </Button>
                    <Button
                      className="btn-icon-only rounded-circle bg-warning text-white btn-sm m-0"
                      data-toggle="tooltip"
                      title="Đặt lại mật khẩu"
                      type="button"
                      onClick={() => {
                        setopenPopup(true);
                        setidDelete(value.id);
                        setaction('');
                      }}
                    >
                      <span className="btn-inner--icon">
                        <i className="fa fa-key" />
                      </span>
                    </Button>
                    {value.status === 'delete' ? (
                      <Button
                        className="btn-icon-only rounded-circle bg-info text-white btn-sm m-0"
                        // color="default"
                        data-toggle="tooltip"
                        title="Khôi phục tài khoản"
                        type="button"
                        onClick={() => {
                          setidDelete(value.id);
                          setaction('restore');
                          setdetailRestore(value);
                          setopenModal(true);
                        }}
                      >
                        <span className="btn-inner--icon">
                          <i className="fa fa-undo" />
                        </span>
                      </Button>
                    ) : (
                      <Button
                        className="btn-icon-only rounded-circle bg-danger text-white btn-sm m-0"
                        // color="default"
                        data-toggle="tooltip"
                        title="Đóng tài khoản"
                        type="button"
                        onClick={() => {
                          setidDelete(value.id);
                          setaction('delete');
                          setopenModal(true);
                        }}
                      >
                        <span className="btn-inner--icon">
                          <i className="fas fa-user-times" />
                        </span>
                      </Button>
                    )}
                  </div>
                );
              },
            },
          ]}
          search
        >
          {(props) => (
            <div className="py-4 table-responsive table-custom-user-manage">
              <div id="datatable-basic_filter" className="dataTables px-4 pb-1">
                {/* <label>
                  Search:
                  <SearchBar
                    className="form-control-sm"
                    placeholder=""
                    {...props.searchProps}
                  />
                </label> */}
                <Row>
                  <Col md="6" lg="6" sm="12" className="align-items-center">
                    <FormGroup className="d-flex align-items-center">
                      <InputGroup
                        className={classnames('input-group-merge', {
                          focused: searchFocus,
                        })}
                      >
                        <Input
                          placeholder="Nhập tên người dùng tìm kiếm"
                          type="text"
                          autoComplete="off"
                          onFocus={(e) => setsearchFocus(true)}
                          onBlur={(e) => setsearchFocus(false)}
                          onChange={(e) => {
                            const value = e.target.value;
                            setuserSearch(e.target.value);
                            if (typingTimeOutRef.current) {
                              clearTimeout(typingTimeOutRef.current);
                            }
                            typingTimeOutRef.current = setTimeout(() => {
                              if (value !== '') {
                                setdetailParams({
                                  ...detailParams,
                                  name: value,
                                });
                              } else {
                                setdetailParams({
                                  page: page + 1,
                                  limit: rowsPerPage,
                                  populate: 'roleId',
                                });
                              }
                              setpage(0);
                            }, 300);
                          }}
                        />
                        <InputGroupAddon addonType="append">
                          <InputGroupText>
                            <i className="fas fa-search" />
                          </InputGroupText>
                        </InputGroupAddon>
                      </InputGroup>
                    </FormGroup>
                  </Col>
                  <Col
                    md="6"
                    lg="6"
                    sm="12"
                    className="align-items-center text-right"
                  >
                    <Button
                      type="button"
                      // color="info"
                      // href="modify-user/add"
                      onClick={() => {
                        history.push(`/config-facility/modify-user/add`);
                      }}
                    >
                      <i
                        className="fas fa-plus"
                        style={{
                          marginRight: '10px',
                        }}
                      />
                      Thêm mới tài khoản
                    </Button>
                  </Col>
                </Row>
              </div>
              {isGetting ? (
                <Row className="align-items-center ">
                  <Col
                    lg="12"
                    md="12"
                    xs="12"
                    className="d-flex justify-content-center"
                  >
                    <div class="spinner-border text-info" />
                  </Col>
                </Row>
              ) : (
                <BootstrapTable
                  {...props.baseProps}
                  noDataIndication={() => {
                    return (
                      <span className="font-weight-bold text-danger">
                        Không có dữ liệu!
                      </span>
                    );
                  }}
                  onTableChange={() => {
                    return <div class="spinner-border text-info" />;
                  }}
                  hover
                  remote
                  bootstrap4={true}
                  pagination={pagination}
                  bordered={false}
                />
              )}
            </div>
          )}
        </ToolkitProvider>
      </Card>
      <Popup
        openPopup={openPopup}
        title="Đặt lại mật khẩu"
        maxWidth="sm"
        fullWidth
      >
        <SetPass setopenPopup={setopenPopup} id={idDelete} />
      </Popup>
    </>
  );
}

export default Results;

/*!

=========================================================
* Argon Dashboard PRO React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
// nodejs library that concatenates classes
import classnames from "classnames";
// core components
import AuthHeader from "components/Headers/AuthHeader.js";
import { Formik } from "formik";
import queryString from "query-string";
import React from "react";
import { store } from "react-notifications-component";
import "react-notifications-component/dist/theme.css";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
// reactstrap components
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Row,
} from "reactstrap";
import userActions from "Redux/Actions/userActions";
import * as yup from "yup";

function ResetPass() {
  //!Const
  const dispatch = useDispatch();
  const history = useHistory();
  const isLoading = useSelector((state) => state.userReducer.isLoading);
  const passSchema = yup.object().shape({
    password: yup
      .string()
      .required("Vui lòng nhập mật khẩu mới!")
      .matches(
        "^(?=.*[A-Za-z])(?=.*\\d)[A-Za-z\\d]{8,}$",
        "Mật khẩu phải có ít nhất 8 kí tự, trong đó chứa ít nhất 1 kí tự và một số!"
      ),
    passwordRetype: yup
      .string()
      .oneOf([yup.ref("password")], "Mật khẩu không trùng khớp!")
      .required("Vui lòng xác nhận mật khẩu!"),
  });
  //!State
  const [focusedEmail, setfocusedEmail] = React.useState(false);
  const [focusedPassword, setfocusedPassword] = React.useState(false);
  const [changePass, setchangePass] = React.useState({
    password: "",
    passwordRetype: "",
  });
  const [message, setmessage] = React.useState("");
  //!Function
  const handleChange = (value) => {
    const body = { password: value.password };
    const params = queryString.stringify({
      token: window.location.href.split("token=")[1],
    });
    dispatch(
      userActions.resetPassword(body, params, {
        success: () => {
          store.addNotification({
            title: "Thông báo!",
            message: "Cấp lại mật khẩu thành công!",
            type: "success", // 'default', 'success', 'info', 'warning'
            container: "bottom-right", // where to position the notifications
            animationIn: ["animated", "fadeIn"], // animate.css classes that's applied
            animationOut: ["animated", "fadeOut"], // animate.css classes that's applied
            dismiss: {
              pauseOnHover: true,
              onScreen: true,
              duration: 5000,
            },
          });
          history.push("login");
        },
        failed: (mess) => {
          store.addNotification({
            title: "Thông báo!",
            message: "Cấp lại mật khẩu thất bại! Lỗi: " + mess + "! Vui lòng thử lại!",
            type: "danger", // 'default', 'success', 'info', 'warning'
            container: "bottom-right", // where to position the notifications
            animationIn: ["animated", "fadeIn"], // animate.css classes that's applied
            animationOut: ["animated", "fadeOut"], // animate.css classes that's applied
            dismiss: {
              onScreen: true,
              pauseOnHover: true,
              duration: 5000,
            },
          });
        },
      })
    );
  };
  //!Use Effect

  //!Render
  return (
    <>
      <AuthHeader
        title="HỆ THỐNG QUẢN LÝ HỒ SƠ THẦU"
        lead="Nhập mật khẩu để cập nhật."
      />
      <Container className="mt--8 pb-5">
        <Row className="justify-content-center">
          <Col lg="5" md="7">
            <Card className="bg-secondary border-0 mb-0">
              <CardHeader className="bg-transparent pb-4">
                <div className="text-muted text-center mt-3 mb-1 text-black">
                  <h2 className="font-weight-bold">THAY ĐỔI MẬT KHẨU</h2>
                </div>
                {/* <div className="btn-wrapper text-center">
                  <Button
                    className="btn-neutral btn-icon"
                    color="default"
                    href="#pablo"
                    onClick={(e) => e.preventDefault()}
                  >
                    <span className="btn-inner--icon mr-1">
                      <img
                        alt="..."
                        src={
                          require("assets/img/icons/common/github.svg").default
                        }
                      />
                    </span>
                    <span className="btn-inner--text">Github</span>
                  </Button>
                  <Button
                    className="btn-neutral btn-icon"
                    color="default"
                    href="#pablo"
                    onClick={(e) => e.preventDefault()}
                  >
                    <span className="btn-inner--icon mr-1">
                      <img
                        alt="..."
                        src={
                          require("assets/img/icons/common/google.svg").default
                        }
                      />
                    </span>
                    <span className="btn-inner--text">Google</span>
                  </Button>
                </div> */}
              </CardHeader>
              <CardBody className="px-lg-5 py-lg-5">
                {/* <div className="text-center text-muted mb-4">
                  <small>Or sign in with credentials</small>
                </div> */}
                <Formik
                  initialValues={changePass}
                  enableReinitialize
                  onSubmit={handleChange}
                  validationSchema={passSchema}
                >
                  {({
                    values,
                    setFieldValue,
                    handleSubmit,
                    errors,
                    touched,
                  }) => {
                    return (
                      <>
                        {/* <Form role="form"> */}
                        <FormGroup
                          className={classnames("mb-3", {
                            focused: focusedEmail,
                          })}
                        >
                          <InputGroup className="input-group-merge input-group-alternative">
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText>
                                {/* <i className="ni ni-email-83" /> */}
                                <i className="ni ni-lock-circle-open" />
                              </InputGroupText>
                            </InputGroupAddon>
                            <Input
                              placeholder="Nhập mật khẩu"
                              type="password"
                              onFocus={() => setfocusedEmail(true)}
                              onBlur={() => setfocusedEmail(true)}
                              onChange={(e) => {
                                setFieldValue("password", e.target.value);
                              }}
                            />
                          </InputGroup>
                          {errors.password && touched.password && (
                            <a
                              style={{
                                fontSize: 15,
                                color: "red",
                                
                              }}
                            >
                              {errors.password}
                            </a>
                          )}
                        </FormGroup>
                        <FormGroup
                          className={classnames({
                            focused: focusedPassword,
                          })}
                        >
                          <InputGroup className="input-group-merge input-group-alternative">
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText>
                                <i className="ni ni-lock-circle-open" />
                              </InputGroupText>
                            </InputGroupAddon>
                            <Input
                              placeholder="Nhập lại mật khẩu"
                              type="password"
                              onFocus={() => setfocusedPassword(true)}
                              onBlur={() => setfocusedPassword(true)}
                              onChange={(e) => {
                                setFieldValue("passwordRetype", e.target.value);
                              }}
                            />
                          </InputGroup>
                          {errors.passwordRetype && touched.passwordRetype && (
                            <a
                              style={{
                                fontSize: 15,
                                color: "red",
                                
                              }}
                            >
                              {errors.passwordRetype}
                            </a>
                          )}
                        </FormGroup>
                        {/* <div className="custom-control custom-control-alternative custom-checkbox"> */}
                        <div className="d-flex justify-content-center">
                          {/* <input
                      className="custom-control-input"
                      id=" customCheckLogin"
                      type="checkbox"
                    />
                    <label
                      className="custom-control-label"
                      htmlFor=" customCheckLogin"
                    >
                      <span className="text-muted">Remember me</span>
                    </label> */}
                          {message !== "" ? (
                            <>
                              <span className="text-danger">{message}</span>
                            </>
                          ) : null}
                        </div>
                        {/* </div> */}
                        <div className="text-center">
                          {isLoading ? (
                            <div class="spinner-border text-info" />
                          ) : (
                            <Button
                              className="my-4"
                              color="info"
                              // type="submit"
                              onClick={handleSubmit}
                            >
                              Đặt lại mật khẩu
                            </Button>
                          )}
                        </div>
                        {/* </Form> */}
                      </>
                    );
                  }}
                </Formik>
              </CardBody>
            </Card>
            <Row className="mt-3">
              {/* <Col xs="6">
                <a className="text-light" href="forgot-pass">
                  <small>Quên mật khẩu?</small>
                </a>
              </Col> */}
              {/* <Col className="text-right" xs="6">
                <a
                  className="text-light"
                  href="#pablo"
                  onClick={(e) => e.preventDefault()}
                >
                  <small>Create new account</small>
                </a>
              </Col> */}
            </Row>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default ResetPass;

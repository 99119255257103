import {
  colors,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@material-ui/core";
import moment from "moment";
import React, { useState } from "react";
import { Button, Col, Row } from "reactstrap";
import "./Filter.css";

function Filter({ Children, openFilter, setopenFilter, onFilter }) {
  //!Const
  let filter = null;
  //!State
  const [status, setstatus] = useState([
    "Tất cả",
    "Đang mở thầu",
    "Đã đóng thầu",
  ]);
  const [detailFilter, setdetailFilter] = useState({
    dateStatus: "Tất cả",
    fromDate: "",
    toDate: "",
  });
  let filterClasses = "side-drawer";
  if (openFilter) {
    filterClasses = "side-drawer open";
  } else {
    filterClasses = "side-drawer";
  }
  return (
    <nav className={filterClasses}>
      <Row
      // className="ml-1"
      //  style={{ marginLeft: "15px" }}
      >
        <Button
          style={{
            margin: "5px",
            backgroundColor: "transparent",
            color: "red",
            boxShadow: "none",
            padding: "1",
            border: "none",
          }}
          color="secondary"
          type="button"
          onClick={() => {
            setopenFilter(false);
          }}
        >
          <i className="ni ni-bold-right" style={{ marginRight: "8px" }} />
          Đóng
        </Button>
      </Row>
      <hr style={{ margin: "5px" }} />
      {/* {Children} */}
      <Row className="ml-3">
        <Col lg="12" md="12" xs="12" className="p-2 text-left">
          <h3>Thiết lập lựa chọn lọc thông tin</h3>
        </Col>
      </Row>
      <hr style={{ margin: "5px" }} />
      <div className="scrollbar-hidden">
        {/* Content */}
        <div className="container-fluid">
          <Row>
            <Col lg="12" md="12" xs="12" className="p-2 text-left">
              <b>Theo Trạng thái</b>
            </Col>
            <Col lg="12" md="12" xs="12" className="p-2 text-left">
              <FormControl fullWidth>
                <InputLabel
                  style={{
                    top: "-9px",
                    color: colors.grey[3] + " !important",
                    fontWeight: "400",
                    fontSize: "15px",
                    lineHeight: "1.42857",
                    letterSpacing: "unset",
                    padding: "0 0 0 15px",
                  }}
                  htmlFor="simple-select"
                >
                  Lựa chọn
                </InputLabel>
                <Select
                  // option={status}
                  className="basic-single"
                  classNamePrefix="select"
                  value={detailFilter.dateStatus}
                  onChange={(value) => {
                    // setFieldValue("status", value.target.value);
                    setdetailFilter({
                      ...detailFilter,
                      dateStatus: value.target.value,
                    });
                  }}
                  variant="outlined"
                  label="Lựa chọn"
                >
                  {status.map((gen, key) => (
                    <MenuItem value={gen} key={key}>
                      {gen}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Col>
            <Col lg="12" md="12" xs="12" className="p-2 text-left">
              <b>Theo ngày</b>
            </Col>
            <Col lg="12" md="12" xs="12" className="p-2 text-left">
              <TextField
                id="datetime-local"
                label="Từ ngày"
                type="datetime-local"
                // defaultValue={moment(
                //   new Date()
                // ).format("YYYY-MM-DDTHH:mm")}
                variant="outlined"
                fullWidth
                value={moment(detailFilter.fromDate).format("YYYY-MM-DDTHH:mm")}
                onChange={(time) => {
                  setdetailFilter({
                    ...detailFilter,
                    fromDate: time.target.value,
                  });
                }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Col>
            <Col lg="12" md="12" xs="12" className="p-2 text-left">
              <TextField
                id="datetime-local"
                label="Đến ngày"
                type="datetime-local"
                // defaultValue={moment(
                //   new Date()
                // ).format("YYYY-MM-DDTHH:mm")}

                variant="outlined"
                fullWidth
                value={moment(detailFilter.toDate).format("YYYY-MM-DDTHH:mm")}
                onChange={(time) => {
                  setdetailFilter({
                    ...detailFilter,
                    toDate: time.target.value,
                  });
                }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Col>
          </Row>
        </div>
        {/* End Content */}
      </div>
      <div
        className="position-absolute bottom-0 text-center"
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          backgroundColor: "white",
        }}
      >
        <div className="p-2 d-block" style={{ width: "100%" }}>
          <div className="m-2">
            <Button
              style={{ backgroundColor: "rgba(0, 0, 0, .2)" }}
              className="btn btn-secondary btn-block"
              onClick={() => {
                setdetailFilter({
                  dateStatus: "Tất cả",
                  fromDate: "",
                  toDate: "",
                });
                setopenFilter(false);
                if (onFilter) {
                  onFilter({});
                }
              }}
            >
              Xoá bộ lọc
            </Button>
          </div>
          <div className="m-2">
            <Button
              className="btn bg-info text-white btn-block"
              onClick={() => {
                let Filter = {};
                Object.keys(detailFilter).map((index, key) => {
                  if (index === "dateStatus") {
                    if (detailFilter[index] === "Đang mở thầu") {
                      Filter["isExp"] = false;
                    } else if (detailFilter[index] === "Đã đóng thầu") {
                      Filter["isExp"] = true;
                    }
                  } else if (
                    detailFilter[index] !== "" &&
                    detailFilter[index] !== null &&
                    detailFilter[index] !== undefined
                  ) {
                    Filter[index] = new Date(detailFilter[index]).getTime();
                  }
                });
                setopenFilter(false);
                if (onFilter) {
                  onFilter(Filter);
                }
              }}
            >
              Áp dụng bộ lọc
            </Button>
          </div>
        </div>
      </div>
    </nav>
  );
}

export default Filter;

import _ from "lodash";
import { call, put, takeLatest } from "redux-saga/effects";
import { DELETE, GET, PATCH, POST } from "Service/ServiceBase";
import ServiceURL from "Service/ServiceURL";
import TypeActions from "../TypeActions";

export function* getListKey(data) {
  const url = ServiceURL.Key + "?" + data.params;
  // const callback = data.callback;
  try {
    const res = yield call(GET, url);
    if (res.message && !_.isEmpty(res.message)) {
      yield put({
        type: TypeActions.GET_LIST_KEY_FAILED,
        error: res.message,
      });
    } else {
      yield put({
        type: TypeActions.GET_LIST_KEY_SUCCESS,
        data: res.data,
      });
      // callback && callback.success(res.data);
    }
  } catch (error) {
    yield put({
      type: TypeActions.GET_LIST_KEY_FAILED,
      error: error,
    });
  }
}

export function* getKey(data) {
  const url = ServiceURL.Key + "/" + data.params;
  try {
    const res = yield call(GET, url);
    if (res.message && !_.isEmpty(res.message)) {
      yield put({
        type: TypeActions.GET_KEY_FAILED,
        error: res.message,
      });
    } else {
      yield put({
        type: TypeActions.GET_KEY_SUCCESS,
        data: res.data,
      });
    }
  } catch (error) {
    yield put({
      type: TypeActions.GET_KEY_FAILED,
      error: error,
    });
  }
}

export function* createKey(data) {
  const url = ServiceURL.Key;
  const callback = data.callback;
  try {
    const res = yield call(POST, url, data.body);
    if (res.message && !_.isEmpty(res.message)) {
      yield put({
        type: TypeActions.CREATE_KEY_FAILED,
        error: res.message,
      });
      callback && callback.failed(res.message);
    } else {
      yield put({
        type: TypeActions.CREATE_KEY_SUCCESS,
        // data: res.data.results
      });
      callback && callback.success();
    }
  } catch (error) {
    yield put({
      type: TypeActions.CREATE_KEY_FAILED,
      error: error,
    });
    callback && callback.failed(error?.response?.data?.message);
  }
}

export function* editKey(data) {
  const url = ServiceURL.Key + "/" + data.params;
  const callback = data.callback;
  try {
    const res = yield call(PATCH, url, data.body);
    if (res.message && !_.isEmpty(res.message)) {
      yield put({
        type: TypeActions.EDIT_KEY_FAILED,
        error: res.message,
      });
      callback && callback.failed(res.message);
    } else {
      yield put({
        type: TypeActions.EDIT_KEY_SUCCESS,
        // data: res.data.results
      });
      callback && callback.success();
    }
  } catch (error) {
    yield put({
      type: TypeActions.EDIT_KEY_FAILED,
      error: error,
    });
    callback && callback.failed(error?.response?.data?.message);
  }
}
export function* deleteKey(data) {
  const url = ServiceURL.Key + "/" + data.params;
  const callback = data.callback;
  try {
    const res = yield call(DELETE, url);
    if (res.message && !_.isEmpty(res.message)) {
      yield put({
        type: TypeActions.DELETE_KEY_FAILED,
        error: res.message,
      });
      callback && callback.failed(res.message);
    } else {
      yield put({
        type: TypeActions.DELETE_KEY_SUCCESS,
        // data: res.data.results
      });
      callback && callback.success();
    }
  } catch (error) {
    yield put({
      type: TypeActions.DELETE_KEY_FAILED,
      error: error,
    });
    callback && callback.failed(error);
  }
}

export default function* keySaga() {
  yield takeLatest(TypeActions.GET_LIST_KEY_REQUEST, getListKey);
  yield takeLatest(TypeActions.GET_KEY_REQUEST, getKey);
  yield takeLatest(TypeActions.CREATE_KEY_REQUEST, createKey);
  yield takeLatest(TypeActions.EDIT_KEY_REQUEST, editKey);
  yield takeLatest(TypeActions.DELETE_KEY_REQUEST, deleteKey);
}

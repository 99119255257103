import React, { useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import {
  Button,
  Card,
  CardHeader,
  Col,
  Container,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Row,
} from "reactstrap";
import { dataTable } from "variables/general";
import Back from "./Filter/Back";
import classnames from "classnames";
import Filter from "./Filter/Filter";
import moment from "moment";

const { SearchBar } = Search;

function Results(props) {
  //!Const
  let filter;
  let backdrop;
  if (openFilter) {
    filter = (
      <Filter openFilter={openFilter} setopenFilter={setopenFilter}></Filter>
    );
    backdrop = <Back setopenFilter={setopenFilter}></Back>;
  }
  //!State
  const [openFilter, setopenFilter] = useState(false);
  const [page, setpage] = useState(0);
  const [searchFocus, setsearchFocus] = useState(false);
  const [rowPerPage, setrowPerPage] = useState(10);
  const [totalResult, settotalResult] = useState(dataTable.length);
  if (openFilter) {
    backdrop = <Back setopenFilter={setopenFilter}></Back>;
  }
  //!Function
  const pagination = paginationFactory({
    // page: page + 1,
    page: page + 1,
    onPageChange: (page, rowPerPage) => {},
    totalSize: totalResult,
    alwaysShowAllBtns: true,
    showTotal: false,
    withFirstAndLast: false,
    onSizePerPageChange: (page, rowPerPage) => {},
    sizePerPageRenderer: ({
      options,
      currSizePerPage,
      onSizePerPageChange,
    }) => (
      <div className="dataTables_length" id="datatable-basic_length">
        <label>
          Hiển thị{" "}
          {
            <select
              name="datatable-basic_length"
              aria-controls="datatable-basic"
              className="form-control form-control-sm"
              onChange={(e) => onSizePerPageChange(e.target.value)}
            >
              <option value="10">10</option>
              <option value="25">25</option>
              <option value="50">50</option>
              <option value="100">100</option>
            </select>
          }{" "}
          dòng.
        </label>
      </div>
    ),
  });
  //!Render
  return (
    <>
      <Card>
        <CardHeader>
          {filter}
          {backdrop}
          <Row className="align-items-center ">
            <Col lg="6" md="7" sm="12">
              <h3 className="mb-0">Danh sách mục lục</h3>
            </Col>
            <Col className="mt-3 mt-md-0 text-md-right" lg="6" md="5" sm="12">
              {/* <Button>
                <i className="ni ni-bold-down"></i>
              </Button> */}
              <Button
                type="button"
                color="info"
                onClick={() => {
                  setopenFilter(true);
                }}
              >
                <i
                  className="ni ni-bold-down"
                  style={{
                    marginRight: "10px",
                  }}
                />
                Hiển thị lọc
              </Button>
              <Filter openFilter={openFilter} setopenFilter={setopenFilter} />
            </Col>
          </Row>
        </CardHeader>
        <ToolkitProvider
          data={dataTable}
          keyField="name"
          columns={[
            {
              dataField: "name",
              text: "Name",
              sort: false,
            },
            {
              dataField: "position",
              text: "Position",
              sort: false,
            },
            {
              dataField: "office",
              text: "Office",
              sort: false,
            },
            {
              dataField: "age",
              text: "Age",
              sort: false,
            },
            {
              dataField: "start_date",
              text: "Start date",
              sort: false,
              formatter: (key, value) => {
                return moment(new Date(key)).format("YYYY/MM/DD");
              },
            },
            {
              dataField: "salary",
              text: "Salary",
              sort: false,
            },
          ]}
          search
        >
          {(props) => (
            <div className="py-4 table-responsive">
              <div id="datatable-basic_filter" className="dataTables px-4 pb-1">
                {/* <label>
                  Search:
                  <SearchBar
                    className="form-control-sm"
                    placeholder=""
                    {...props.searchProps}
                  />
                </label> */}
                <Row>
                  <Col md="6" lg="6" sm="12" className="align-items-center">
                    <FormGroup className="d-flex align-items-center">
                      <InputGroup
                        className={classnames("input-group-merge", {
                          focused: searchFocus,
                        })}
                      >
                        <Input
                          placeholder="Nhập từ tên tài khoản tìm kiếm"
                          type="text"
                          onFocus={(e) => setsearchFocus(true)}
                          onBlur={(e) => setsearchFocus(false)}
                        />
                        <InputGroupAddon addonType="append">
                          <InputGroupText>
                            <i className="fas fa-search" />
                          </InputGroupText>
                        </InputGroupAddon>
                      </InputGroup>
                    </FormGroup>
                  </Col>
                </Row>
              </div>
              <BootstrapTable
                {...props.baseProps}
                bootstrap4={true}
                pagination={pagination}
                bordered={false}
              />
            </div>
          )}
        </ToolkitProvider>
      </Card>
    </>
  );
}

export default Results;

import TypeActions from "../TypeActions";

export const getListTenderProfile = (params) => {
  return {
    type: TypeActions.GET_LIST_TENDER_PROFILE_REQUEST,
    params,
    // callback
  };
};
export const getTenderProfile = (params) => {
  return {
    type: TypeActions.GET_TENDER_PROFILE_REQUEST,
    params,
  };
};

export const createTenderProfile = (body, callback) => {
  return {
    type: TypeActions.CREATE_TENDER_PROFILE_REQUEST,
    body,
    callback,
  };
};
export const editTenderProfile = (body, params, callback) => {
  return {
    type: TypeActions.EDIT_TENDER_PROFILE_REQUEST,
    body,
    params,
    callback,
  };
};
export const editTaskTenderProfile = (body, params, callback) => {
  return {
    type: TypeActions.EDIT_TASK_TENDER_PROFILE_REQUEST,
    body,
    params,
    callback,
  };
};
export const deleteTenderProfile = (params, callback) => {
  return {
    type: TypeActions.DELETE_TENDER_PROFILE_REQUEST,
    params,
    callback,
  };
};

export default {
  getListTenderProfile,
  createTenderProfile,
  editTenderProfile,
  deleteTenderProfile,
  getTenderProfile,
  editTaskTenderProfile,
};

import React, { useEffect, useRef, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import {
  Button,
  Card,
  CardHeader,
  Col,
  Container,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Modal,
  Row,
  CardBody,
  CardFooter,
  Form,
} from "reactstrap";
import {
  colors,
  FormControl,
  MenuItem,
  TextField,
  Select,
  InputLabel,
  Divider,
} from "@material-ui/core";
import { dataTable } from "variables/general";
import classnames from "classnames";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import Select2 from "react-select2-wrapper";
import userActions from "Redux/Actions/userActions";
import queryString from "query-string";
import { useHistory, useParams } from "react-router";
import { store } from "react-notifications-component";
import "react-notifications-component/dist/theme.css";
import ImageUpload from "components/Upload/ImageUpload";
import { Formik } from "formik";
import { Autocomplete } from "@material-ui/lab";
import * as yup from "yup";
import roleActions from "Redux/Actions/roleActions";

const { SearchBar } = Search;

function AddUserForm(props) {
  //!Const
  const history = useHistory();
  const dispatch = useDispatch();
  const listRole = useSelector((state) => state.roleReducer.listRole);
  const isCreating = useSelector((state) => state.userReducer.isCreating);
  const isEditting = useSelector((state) => state.userReducer.isEditting);
  const userByID = useSelector((state) => state.userReducer.userByID);
  const userSchema = yup.object().shape({
    name: yup.string().required("Vui lòng nhập tên người dùng!"),
    email: yup
      .string()
      .email("Email không đúng định dạng!")
      .required("Vui lòng nhập Email!"),
    gender: yup.string().required("Vui lòng chọn giới tính!"),
    address: yup.string().required("Vui lòng nhập địa chỉ cụ thể!"),
    roleId: yup.object().required("Vui lòng chọn phân quyền!"),
  });
  //!State
  const [openFilter, setopenFilter] = useState(false);
  const [openModal, setopenModal] = useState(false);
  const [page, setpage] = useState(0);
  const [searchFocus, setsearchFocus] = useState(false);
  const [rowPerPage, setrowPerPage] = useState(5);
  const [totalResult, settotalResult] = useState(0);
  const [userSearch, setuserSearch] = useState("");
  const [gender, setgender] = React.useState(["Nam", "Nữ"]);
  const [searchRole, setsearchRole] = useState("");

  const [detailParams, setdetailParams] = useState({
    name: "",
    email: "",
    roleId: {},
    gender: "",
    address: "",
  });

  const { userId } = useParams();
  //!Function
  const handleUser = (value) => {
    if (!!userId && userId !== null && userId === "add") {
      const dataUserAdd = {
        name: value.name,
        email: value.email,
        roleId: value.roleId && value.roleId.id,
        gender: value.gender,
        address: value.address,
      };
      dispatch(
        userActions.createUsers(dataUserAdd, {
          success: () => {
            store.addNotification({
              title: "Thông báo!",
              message: "Thêm tài khoản thành công!",
              type: "success", // 'default', 'success', 'info', 'warning'
              container: "bottom-right", // where to position the notifications
              animationIn: ["animated", "fadeIn"], // animate.css classes that's applied
              animationOut: ["animated", "fadeOut"], // animate.css classes that's applied
              dismiss: {
                pauseOnHover: true,
                onScreen: true,
                duration: 3000,
              },
            });
            history.goBack();
          },
          failed: (mess) => {
            store.addNotification({
              title: "Thông báo!",
              message:
                "Thêm mới tài khoản thất bại! Lỗi: " +
                mess +
                "! Vui lòng thử lại!",
              type: "danger", // 'default', 'success', 'info', 'warning'
              container: "bottom-right", // where to position the notifications
              animationIn: ["animated", "fadeIn"], // animate.css classes that's applied
              animationOut: ["animated", "fadeOut"], // animate.css classes that's applied
              dismiss: {
                onScreen: true,
                pauseOnHover: true,
                duration: 5000,
              },
            });
          },
        })
      );
    } else if (!!userId && userId !== null && userId !== "add") {
      const dataUserEdit = {
        name: value.name,
        roleId: value.roleId && value.roleId.id,
        gender: value.gender,
        address: value.address,
      };
      dispatch(
        userActions.editUsers(dataUserEdit, userId, {
          success: () => {
            store.addNotification({
              title: "Thông báo!",
              message: "Cập nhật tài khoản thành công!",
              type: "success", // 'default', 'success', 'info', 'warning'
              container: "bottom-right", // where to position the notifications
              animationIn: ["animated", "fadeIn"], // animate.css classes that's applied
              animationOut: ["animated", "fadeOut"], // animate.css classes that's applied
              dismiss: {
                pauseOnHover: true,
                onScreen: true,
                duration: 3000,
              },
            });
            history.goBack();
          },
          failed: (mess) => {
            store.addNotification({
              title: "Thông báo!",
              message:
                "Cập nhật tài khoản thất bại! Lỗi: " +
                mess +
                "! Vui lòng thử lại!",
              type: "danger", // 'default', 'success', 'info', 'warning'
              container: "bottom-right", // where to position the notifications
              animationIn: ["animated", "fadeIn"], // animate.css classes that's applied
              animationOut: ["animated", "fadeOut"], // animate.css classes that's applied
              dismiss: {
                onScreen: true,
                pauseOnHover: true,
                duration: 5000,
              },
            });
          },
        })
      );
    }
  };
  //!Use effect
  useEffect(() => {
    dispatch(roleActions.getListRole(queryString.stringify(searchRole)));
  }, [searchRole]);
  useEffect(() => {
    if (userId && userId !== "add")
      dispatch(
        userActions.getUsers(
          userId + "?" + queryString.stringify({ populate: "roleId" })
        )
      );
  }, [userId]);
  useEffect(() => {
    if (userId !== "add" && userByID !== null) {
      setdetailParams({
        ...detailParams,
        name: userByID.name,
        email: userByID.email,
        roleId: userByID.roleId,
        gender: userByID.gender,
        address: userByID.address,
      });
    } else {
      setdetailParams({
        ...detailParams,
        name: "",
        email: "",
        roleId: {},
        address: "",
        gender: "",
        address: "",
      });
    }
  }, [userByID]);

  //!Render
  return (
    <>
      <Card>
        <CardHeader>
          <Row className="align-items-center ">
            <Col lg="6" md="7" xs="12" className="p-2">
              <h3 className="mb-0">
                {userId === "add"
                  ? "Thêm mới tài khoản"
                  : "Chỉnh sửa tài khoản"}
              </h3>
            </Col>
            <Col className="mt-3 mt-md-0 text-md-right" lg="6" md="5" xs="12">
              {/* <Button>
                <i className="ni ni-bold-down"></i>
              </Button> */}
            </Col>
          </Row>
        </CardHeader>
        <Formik
          initialValues={detailParams}
          enableReinitialize
          onSubmit={handleUser}
          validationSchema={userSchema}
        >
          {({ values, setFieldValue, handleSubmit, errors, touched }) => {
            return (
              <>
                <CardBody>
                  <Row className="align-items-flex-start">
                    <Col lg="4" md="4" xs="12" className="p-2">
                      <ImageUpload />
                    </Col>
                    <Col
                      className="align-items-flex-start"
                      lg="8"
                      md="8"
                      xs="12"
                    >
                      <Row className="align-items-flex-start ">
                        <Col lg="6" md="6" xs="12" className="p-2">
                          <TextField
                            label="Họ tên *"
                            name="name"
                            value={values.name}
                            variant="outlined"
                            fullWidth
                            type="text"
                            onChange={(e) => {
                              setFieldValue("name", e.target.value);
                            }}
                          />
                          {errors.name && touched.name && (
                            <a
                              style={{
                                fontSize: 15,

                                color: "red",
                                paddingLeft: "3px",
                              }}
                            >
                              {errors.name}
                            </a>
                          )}
                        </Col>
                        <Col lg="6" md="6" xs="12" className="p-2">
                          <FormControl fullWidth>
                            <InputLabel
                              // className={classes.selectLabel}
                              style={{
                                // fontSize: "12px",
                                // textTransform: "uppercase",
                                // color: grayColor[1] + " !important",
                                top: "-7px",
                                // bottom: '15px',
                                // ...defaultFont,
                                color: colors.grey[3] + " !important",
                                fontWeight: "400",
                                fontSize: "14px",
                                lineHeight: "1.42857",
                                letterSpacing: "unset",
                                padding: "0 0 0 15px",
                              }}
                              htmlFor="simple-select"
                            >
                              Giới tính *
                            </InputLabel>
                            <Select
                              // option={gender}
                              className="basic-single"
                              classNamePrefix="select"
                              value={values.gender}
                              onChange={(value) => {
                                setFieldValue("gender", value.target.value);
                              }}
                              variant="outlined"
                              label="Giới tính *"
                            >
                              {gender.map((gen, key) => (
                                <MenuItem value={gen} key={key}>
                                  {gen}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                          {errors.gender && touched.gender && (
                            <a
                              style={{
                                fontSize: 15,

                                color: "red",
                                paddingLeft: "3px",
                              }}
                            >
                              {errors.gender}
                            </a>
                          )}
                        </Col>
                      </Row>
                      <Row className="align-items-flex-start ">
                        <Col lg="6" md="6" xs="12" className="p-2">
                          <TextField
                            label="Email *"
                            name="email"
                            value={values.email}
                            variant="outlined"
                            fullWidth
                            type="text"
                            onChange={(e) => {
                              setFieldValue("email", e.target.value);
                            }}
                          />
                          {errors.email && touched.email && (
                            <a
                              style={{
                                fontSize: 15,

                                color: "red",
                                paddingLeft: "3px",
                              }}
                            >
                              {errors.email}
                            </a>
                          )}
                        </Col>
                        <Col lg="6" md="6" xs="12" className="p-2">
                          {/* <FormGroup> */}
                          <Autocomplete
                            options={
                              listRole && listRole.results !== undefined
                                ? listRole.results
                                : []
                            }
                            value={values.roleId}
                            // value={values.customerId}
                            getOptionLabel={(option) => {
                              if (option !== null && option.name)
                                return option.name;
                            }}
                            onChange={(e, value) => {
                              setFieldValue("roleId", value);
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                style={{
                                  height: "80%",
                                }}
                                // placeholder="Phân quyền"
                                label="Phân quyền *"
                                name="role"
                                variant="outlined"
                                fullWidth
                                // type="text"
                                onChange={(e) => {
                                  setFieldValue("roleId", e.target.value);
                                }}
                              />
                            )}
                          />
                          {errors.roleId && touched.roleId && (
                            <a
                              style={{
                                fontSize: 15,

                                color: "red",
                                paddingLeft: "3px",
                              }}
                            >
                              {errors.roleId}
                            </a>
                          )}
                        </Col>
                      </Row>
                      <Row className="align-items-flex-start ">
                        <Col lg="12" md="12" xs="12" className="p-2">
                          {/* <FormGroup> */}
                          <TextField
                            label="Địa chỉ cụ thể (Nhập) *"
                            value={
                              values.address !== undefined ? values.address : ""
                            }
                            name="address"
                            multiline
                            rows={4}
                            variant="outlined"
                            fullWidth
                            type="text"
                            onChange={(e) => {
                              setFieldValue("address", e.target.value);
                            }}
                          />
                          {errors.address && touched.address && (
                            <a
                              style={{
                                fontSize: 15,

                                color: "red",
                                paddingLeft: "3px",
                              }}
                            >
                              {errors.address}
                            </a>
                          )}
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </CardBody>
                <CardFooter>
                  <Row className="align-items-center text-right">
                    <Col lg="12" md="12" xs="12">
                      {isEditting || isCreating ? (
                        <div class="spinner-border text-info" />
                      ) : (
                        <span>
                          <Button
                            className="bg-info text-white"
                            onClick={handleSubmit}
                          >
                            <i className="fas fa-check" />{" "}
                            {userId === "add" ? "Thêm mới" : "Cập nhật"}
                          </Button>
                          {/* {avatar ? <br /> : null} */}
                          <Button
                            // className=""
                            onClick={() => {
                              history.goBack();
                            }}
                          >
                            <i className="fas fa-times" /> Huỷ bỏ
                          </Button>
                        </span>
                      )}
                    </Col>
                  </Row>
                </CardFooter>
              </>
            );
          }}
        </Formik>
      </Card>
    </>
  );
}

export default AddUserForm;

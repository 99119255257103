import React, { useEffect, useRef, useState } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import {
  Button,
  Card,
  CardHeader,
  Col,
  Container,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Modal,
  Row,
} from 'reactstrap';
import { dataTable } from 'variables/general';
import Back from './Filter/Back';
import classnames from 'classnames';
import Filter from './Filter/Filter';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import userActions from 'Redux/Actions/userActions';
import { store } from 'react-notifications-component';
import 'react-notifications-component/dist/theme.css';
import queryString from 'query-string';
import { useHistory } from 'react-router';
import keyActions from 'Redux/Actions/keyActions';

const { SearchBar } = Search;

function Results(props) {
  //!Const
  let filter;
  let backdrop;
  if (openFilter) {
    filter = (
      <Filter openFilter={openFilter} setopenFilter={setopenFilter}></Filter>
    );
    backdrop = <Back setopenFilter={setopenFilter}></Back>;
  }

  const dispatch = useDispatch();
  const listKey = useSelector((state) => state.keyReducer.listKey);
  const isGetting = useSelector((state) => state.keyReducer.isGetting);
  const typingTimeOutRef = useRef(null);
  const history = useHistory();
  //!State
  const [openFilter, setopenFilter] = useState(false);
  const [openModal, setopenModal] = useState(false);
  const [page, setpage] = useState(0);
  const [searchFocus, setsearchFocus] = useState(false);
  const [rowsPerPage, setrowsPerPage] = useState(5);
  const [totalResult, settotalResult] = useState(0);
  const [search, setsearch] = useState('');
  const [idDelete, setidDelete] = useState('');
  const [dataTable, setdataTable] = useState([]);
  const [detailParams, setdetailParams] = useState({
    page: page + 1,
    limit: rowsPerPage,
  });
  if (openFilter) {
    backdrop = <Back setopenFilter={setopenFilter}></Back>;
  }
  //!Function
  const pagination = paginationFactory({
    // page: page + 1,
    page: page + 1,
    onPageChange: (page, rowsPerPage) => {
      setpage(page - 1);
    },
    sizePerPage: rowsPerPage,
    // onSizePerPageChange: (page, rowsPerPage) => {
    //   setrowsPerPage(rowsPerPage);
    // },
    onSizePerPageChange: (value) => {
      setrowsPerPage(value);
      setpage(0);
    },
    totalSize: totalResult,
    showTotal: false,
    withFirstAndLast: false,
    alwaysShowAllBtns: true,
    sizePerPageRenderer: ({
      options,
      currSizePerPage,
      onSizePerPageChange,
    }) => (
      <div className="dataTables_length" id="datatable-basic_length">
        <label>
          Hiển thị{' '}
          {
            <select
              value={rowsPerPage}
              name="datatable-basic_length"
              aria-controls="datatable-basic"
              className="form-control form-control-xs"
              onChange={(e) => onSizePerPageChange(e.target.value)}
              // onChange={(e) => setrowsPerPage(e.target.value)}
            >
              <option value="5">5</option>
              <option value="10">10</option>
              <option value="30">30</option>
              <option value="100">100</option>
            </select>
          }{' '}
          dòng.
        </label>
      </div>
    ),
  });
  //!Use effect
  useEffect(() => {
    setdetailParams({
      ...detailParams,
      page: page + 1,
      limit: rowsPerPage,
    });
  }, [page, rowsPerPage]);
  useEffect(() => {
    dispatch(keyActions.getListKey(queryString.stringify(detailParams)));
  }, [detailParams]);
  useEffect(() => {
    if (idDelete === '')
      dispatch(keyActions.getListKey(queryString.stringify(detailParams)));
  }, [idDelete]);
  useEffect(() => {
    if (listKey && listKey.results !== undefined) {
      setdataTable(listKey.results);
      settotalResult(listKey.totalResults);
    }
  }, [listKey]);

  //!Render
  return (
    <>
      {/* The Modal */}
      <Modal
        isOpen={openModal}
        toggle={() => {
          setopenModal(!openModal);
        }}
        className="text-center"
      >
        <ModalHeader
          toggle={() => {
            setopenModal(!openModal);
          }}
        >
          <h2 className="text-danger">Thông báo!</h2>
        </ModalHeader>
        <ModalBody>Bạn có chắc chắn muốn xoá từ khoá?</ModalBody>
        <ModalFooter>
          <Button
            color="primary"
            onClick={() => {
              setopenModal(false);

              dispatch(
                keyActions.deleteKey(idDelete, {
                  success: () => {
                    store.addNotification({
                      title: 'Thông báo!',
                      message: 'Xoá từ khoá thành công!',
                      type: 'success', // 'default', 'success', 'info', 'warning'
                      container: 'bottom-right', // where to position the notifications
                      animationIn: ['animated', 'fadeIn'], // animate.css classes that's applied
                      animationOut: ['animated', 'fadeOut'], // animate.css classes that's applied
                      dismiss: {
                        pauseOnHover: true,
                        onScreen: true,
                        duration: 3000,
                      },
                    });
                    setidDelete('');
                  },
                  failed: (mess) => {
                    store.addNotification({
                      title: 'Thông báo!',
                      message:
                        'Xoá từ khoá thất bại! Lỗi: ' +
                        mess +
                        '! Vui lòng thử lại!',
                      type: 'danger', // 'default', 'success', 'info', 'warning'
                      container: 'bottom-right', // where to position the notifications
                      animationIn: ['animated', 'fadeIn'], // animate.css classes that's applied
                      animationOut: ['animated', 'fadeOut'], // animate.css classes that's applied
                      dismiss: {
                        onScreen: true,
                        pauseOnHover: true,
                        duration: 5000,
                      },
                    });
                    setidDelete('');
                  },
                })
              );
            }}
          >
            Đồng ý
          </Button>{' '}
          <Button
            color="secondary"
            onClick={() => {
              setopenModal(false);
            }}
          >
            Huỷ bỏ
          </Button>
        </ModalFooter>
      </Modal>
      {/* The Modal */}

      <Card>
        <CardHeader>
          {filter}
          {backdrop}
          <Row className="align-items-center ">
            <Col lg="6" md="7" xs="12">
              <h3 className="mb-0">
                Danh sách từ khoá ({totalResult} bản ghi)
              </h3>
            </Col>
            <Col className="mt-3 mt-md-0 text-md-right" lg="6" md="5" xs="12">
              {/* <Button>
                <i className="ni ni-bold-down"></i>
              </Button> */}
              {/* <Button
                type="button"
                color="info"
                onClick={() => {
                  setopenFilter(true);
                }}
              >
                <i
                  className="ni ni-bold-down"
                  style={{
                    marginRight: "10px",
                  }}
                />
                Hiển thị lọc
              </Button> */}
              <Filter openFilter={openFilter} setopenFilter={setopenFilter} />
            </Col>
          </Row>
        </CardHeader>
        <ToolkitProvider
          data={dataTable}
          keyField="email"
          columns={[
            // {
            //   dataField: "",
            //   text: "STT",
            //   align: "center",
            //   headerAlign: "center",
            //   sort: false,
            //   formatter: (value, valueRow, key) => {
            //     return page * rowsPerPage + key + 1;
            //   },
            // },
            {
              dataField: 'code',
              text: 'Mã từ khoá',
              sort: false,
              align: 'center',
              headerAlign: 'center',
            },
            {
              dataField: 'values',
              text: 'Từ khoá',
              sort: false,
              width: '30%',
              formatter: (value, valueRow, key) => {
                return (
                  <div
                    style={{
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap',
                      width: '350px',
                    }}
                  >
                    {value.map((index, key) => {
                      if (key !== value.length - 1) {
                        return index + ', ';
                      } else return index;
                    })}
                  </div>
                );
              },
            },
            {
              dataField: 'action',
              text: 'Hành động',
              sort: false,
              headerAlign: 'center',
              align: 'center',
              formatter: (key, value) => {
                return (
                  <div className="d-flex justify-content-center">
                    <Button
                      className="btn-icon-only rounded-circle bg-success text-white btn-sm m-0"
                      // color="default"
                      data-toggle="tooltip"
                      title="Chỉnh sửa từ khoá"
                      type="button"
                      onClick={() => {
                        history.push(`/config-facility/modify-key/${value.id}`);
                      }}
                    >
                      <span className="btn-inner--icon">
                        <i className="fas fa-edit" />
                      </span>
                    </Button>
                    <Button
                      className="btn-icon-only rounded-circle bg-danger text-white btn-sm m-0"
                      // color="default"
                      data-toggle="tooltip"
                      title="Xoá từ khoá"
                      type="button"
                      onClick={() => {
                        setidDelete(value.id);
                        setopenModal(true);
                      }}
                    >
                      <span className="btn-inner--icon">
                        <i className="far fa-trash-alt" />
                      </span>
                    </Button>
                  </div>
                );
              },
            },
          ]}
          search
        >
          {(props) => (
            <div className="py-4 table-responsive table-custom-key-manage">
              <div id="datatable-basic_filter" className="dataTables px-4 pb-1">
                {/* <label>
                  Search:
                  <SearchBar
                    className="form-control-xs"
                    placeholder=""
                    {...props.searchProps}
                  />
                </label> */}
                <Row>
                  <Col md="6" lg="6" xs="12" className="align-items-center">
                    {/* <FormGroup className="d-flex align-items-center">
                      <InputGroup
                        className={classnames("input-group-merge", {
                          focused: searchFocus,
                        })}
                      >
                        <Input
                          placeholder="Nhập mã từ khoá tìm kiếm"
                          type="text"
                          value={search}
                          onFocus={(e) => setsearchFocus(true)}
                          onBlur={(e) => setsearchFocus(false)}
                          onChange={(e) => {
                            const value = e.target.value;
                            setsearch(e.target.value);
                            if (typingTimeOutRef.current) {
                              clearTimeout(typingTimeOutRef.current);
                            }
                            typingTimeOutRef.current = setTimeout(() => {
                              if (value !== "") {
                                setdetailParams({
                                  ...detailParams,
                                  code: value,
                                });
                              } else {
                                setdetailParams({
                                  page: page + 1,
                                  limit: rowsPerPage,
                                });
                              }
                              setpage(0);
                            }, 300);
                          }}
                        />
                        <InputGroupAddon addonType="append">
                          <InputGroupText>
                            <i className="fas fa-search" />
                          </InputGroupText>
                        </InputGroupAddon>
                      </InputGroup>
                    </FormGroup> */}
                  </Col>
                  <Col
                    md="6"
                    lg="6"
                    xs="12"
                    className="align-items-center text-right"
                  >
                    <Button
                      type="button"
                      // color="info"
                      // href="modify-user/add"
                      className="mb-2 mt-0"
                      onClick={() => {
                        history.push(`/config-facility/modify-key/add`);
                      }}
                    >
                      <i
                        className="fas fa-plus"
                        style={{
                          marginRight: '10px',
                        }}
                      />
                      Thêm mới từ khoá
                    </Button>
                  </Col>
                </Row>
              </div>
              {isGetting ? (
                <Row className="align-items-center ">
                  <Col
                    lg="12"
                    md="12"
                    xs="12"
                    className="d-flex justify-content-center"
                  >
                    <div class="spinner-border text-info" />
                  </Col>
                </Row>
              ) : (
                <BootstrapTable
                  {...props.baseProps}
                  noDataIndication={() => {
                    return (
                      <span className="font-weight-bold text-danger">
                        Không có dữ liệu!
                      </span>
                    );
                  }}
                  onTableChange={() => {
                    return <div class="spinner-border text-info" />;
                  }}
                  hover
                  remote
                  bootstrap4={true}
                  pagination={pagination}
                  bordered={false}
                />
              )}
            </div>
          )}
        </ToolkitProvider>
      </Card>
    </>
  );
}

export default Results;

import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import { useParams } from "react-router";
import { Card, CardHeader, Container, Row } from "reactstrap";
import { dataTable } from "variables/general";
import Header from "./Header";
import AddUserForm from "./AddUserForm";

const { SearchBar } = Search;

function AddUser(props) {
  //!Const
  const { userId } = useParams();
  //!State
  //!Function

  //!Render
  return (
    <>
      <Header
        name={userId === "add" ? "Thêm mới tài khoản" : "Cập nhật tài khoản"}
        parentName="Quản lý tài khoản"
      />
      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <AddUserForm />
          </div>
        </Row>
        {/* <Row>
          <div className="col">
            <Card className="border-0"></Card>
          </div>
        </Row> */}
      </Container>
    </>
  );
}

export default AddUser;

import { toUpper } from "lodash-es";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Col, Row } from "reactstrap";
import queryString from "query-string";
import documentActions from "Redux/Actions/documentActions";
import { Checkbox, Divider } from "@material-ui/core";

function ChooseChilds({ setopenPopup, detail, onEnd }) {
  //!Const
  const listdocument = useSelector(
    (state) => state.documentReducer.listdocument
  );
  const dispatch = useDispatch();
  let listIds = [];
  //!State
  const [detailChilds, setdetailChilds] = useState({
    parentId: "",
    childs: [],
  });
  const [listChild, setlistChild] = useState([]);
  const [detailParams, setdetailParams] = useState({
    limit: 1000,
    parentId: detail.id,
  });
  //!Function

  //!Use Effect
  useEffect(() => {
    dispatch(
      documentActions.getListDocument(queryString.stringify(detailParams))
    );
  }, [detailParams]);
  useEffect(() => {
    if (detail && detail !== null) {
      setdetailChilds(detail);
    }
  }, [detail]);
  useEffect(() => {
    if (listdocument && listdocument.results !== undefined) {
      setlistChild(listdocument.results);
    }
  }, [listdocument]);
  listdocument.results !== undefined &&
    listdocument.results.map((index) => {
      listIds.push(index.id);
    });
  //!Render
  return (
    <>
      <Row className="align-items-center">
        <Col lg="2" md="2" xs="2" className="p-2 text-center">
          <Checkbox
            className="text-info"
            checked={
              listIds !== undefined &&
              detailChilds.childIds !== undefined &&
              listIds.length === detailChilds.childIds.length
            }
            onChange={() => {
              if (listIds.length === detailChilds.childIds.length) {
                setdetailChilds({ ...detailChilds, childIds: [] });
              } else {
                setdetailChilds({ ...detailChilds, childIds: listIds });
              }
            }}
          />
        </Col>
        <Col lg="10" md="10" xs="10" className="p-2">
          <b>Tên tài liệu</b>
        </Col>
        <Col lg="12" md="12" xs="12">
          <Divider />
        </Col>
        {listChild !== undefined &&
          listChild.map((index, key) => {
            return (
              <>
                <Col lg="2" md="2" xs="2" className="p-2 text-center">
                  <Checkbox
                    className="text-info"
                    value={index.id}
                    checked={
                      detailChilds.childIds !== undefined &&
                      detailChilds.childIds.indexOf(index.id) !== -1
                    }
                    onChange={(e) => {
                      let arr = detailChilds.childIds;
                      if (arr.indexOf(e.target.value) === -1) {
                        arr.push(index.id);
                      } else {
                        arr.splice(arr.indexOf(e.target.value), 1);
                      }
                      setdetailChilds({ ...detailChilds, childIds: arr });
                    }}
                  />
                </Col>
                <Col lg="10" md="10" xs="10" className="p-2">
                  {index.name}
                </Col>
              </>
            );
          })}
      </Row>
      <Row className="align-items-center text-right">
        <Col lg="12" md="12" xs="12" className="p-2 text-center">
          <span>
            <Button
              className="bg-info text-white"
              onClick={() => {
                onEnd(detailChilds);
                setopenPopup(false);
              }}
            >
              Hoàn thành
            </Button>
            {/* <Button
              onClick={() => {
                setopenPopup(false);
                onEnd(null);
              }}
            >
              Huỷ bỏ
            </Button> */}
          </span>
        </Col>
      </Row>
    </>
  );
}

export default ChooseChilds;
